import React from "react";
import Chip from "../../BlogContent/Chip";
import "./BlogItem.css";

const BlogItem = ({
  blog: {
    description,
    title,
    created_at,
    author_name,
    author_avatar,
    cover,
    category,
    subcategories,
    views,
    id,
  },
}) => {
  return (
    <div className="blogItem-link" onClick={() => goBlogId(id)}>
      <div className="blogItem-wrap">
        <img className="blogItem-cover" src={cover} alt="" />
        <Chip label={category} />
        <h3>{title}</h3>
        <div className="blogItem-desc">{description}</div>
        <div className="blogItem-desc">
          <i className="fa fa-eye" />
          {views} views
        </div>
        <div className="blogItem-author">
          <img src={author_avatar} alt="" />
          <div>
            <h6>{author_name}</h6>
            <p>{created_at}</p>
          </div>
          <i className="fa fa-arrow-right" />
        </div>
      </div>
    </div>
  );

  function goBlogId(id) {
    window.location.href = `/blog/${id}`;
  }
};

export default BlogItem;
