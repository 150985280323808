import React, { useState, useEffect } from "react";
import BlogEmptyList from "./BlogContent/BlogEmptyList";
import BlogList from "./BlogList/BlogList";
import BlogHeader from "./BlogHeader/BlogHeader";
import { getBlogArticlesAPI } from "../../utils/apis/public";

//CSS
import "./BlogHome.css";
import isEmpty from "../../components/isEmpty";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//BlogHome
const BlogHome = () => {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [filtered_blogs, setFilteredBlogs] = useState([]);

  const [new_blog_article, setNewBlogArticle] = useState(null);

  // Parse query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const initialSearchText = urlParams.get("search") || "";
  const initialFilterTags = urlParams.getAll("tag") || [];

  const [searchText, setSearchText] = useState(initialSearchText);
  const [filterTags, setFilterTags] = useState(initialFilterTags);

  const [showAllTags, setShowAllTags] = useState(false);
  const tagsToShowInitially = 14; // Adjust this value to change the initial number of tags shown

  const toggleShowAllTags = () => {
    setShowAllTags(!showAllTags);
  };

  useEffect(() => {
    //API -> get blogs
    getBlogs();
    //
  }, []);

  //API
  async function getBlogs() {
    setApiWorking(true);
    try {
      var queryParams = [];
      queryParams.push(`page=${0}`);
      queryParams.push(`limit=${200}`);
      let qParameters = queryParams.join("&");
      //✅SUCCESS
      let result = await getBlogArticlesAPI(qParameters);
      let blogs = result["blogs"];
      //
      setBlogs(blogs);
      setFilteredBlogs(blogs);
      console.log("RESULT", blogs);
      //
      setApiWorking(false);
      //
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
    }
  }

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleTagClick = (tag) => {
    if (!filterTags.includes(tag)) {
      setFilterTags([...filterTags, tag]);
    }
    setSearchText(tag);
  };

  const getUniqueTags = () => {
    const allTags = blogs.reduce((tags, blog) => {
      tags.push(blog.category.toLowerCase());
      blog.subcategories.forEach((subcategory) => {
        tags.push(subcategory.toLowerCase());
      });
      return tags;
    }, []);

    return Array.from(new Set(allTags));
  };

  const filterBlogs = (blog, searchText, filterTags) => {
    // Check if searchText is a string before applying toLowerCase
    const searchTextString =
      typeof searchText === "string" ? searchText.toLowerCase() : "";

    // Check if the blog's title contains the searchText (case-insensitive)
    const titleMatch = blog.title.toLowerCase().includes(searchTextString);

    // Check if the blog's category or subcategories match the filterTags (case-insensitive)
    const categoryMatch = blog.category
      .toLowerCase()
      .includes(searchTextString);
    const subcategoryMatch = blog.subcategories.some(
      (subcategory) => subcategory.toLowerCase() === searchTextString
    );

    // If the search bar is empty and no filterTags are applied, return true to show all blogs
    if (!searchTextString.trim() && filterTags.length === 0) {
      return true;
    }

    // Check if the blog matches the searchText or filterTags
    return titleMatch || categoryMatch || subcategoryMatch;
  };

  return (
    <div className="bloghome">
      {/* Page Header */}
      <BlogHeader new_blog_article={new_blog_article} />
      <div className="search-bar">
        <form>
          <div className="input-container">
            <input
              type="text"
              name="focus"
              required
              className="search-box"
              placeholder="Enter search term"
              value={searchText}
              onChange={handleSearch}
            />
            {searchText && (
              <button
                className="close-icon"
                type="reset"
                onClick={() => setSearchText("")}
              >
                <i className="fa fa-times-circle" />
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="filter-tags">
        {showAllTags
          ? getUniqueTags().map((tag, tagIndex) => (
              <span
                key={tagIndex}
                className={`tag ${searchText.includes(tag) ? "active" : ""}`}
                onClick={() => handleTagClick(tag)}
              >
                {tag}
              </span>
            ))
          : getUniqueTags()
              .slice(0, tagsToShowInitially)
              .map((tag, tagIndex) => (
                <span
                  key={tagIndex}
                  className={`tag ${searchText.includes(tag) ? "active" : ""}`}
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </span>
              ))}
        {!showAllTags && getUniqueTags().length > tagsToShowInitially && (
          <button onClick={toggleShowAllTags} className="show-button">
            Show More
          </button>
        )}
        {showAllTags && (
          <button onClick={toggleShowAllTags} className="show-button">
            Show Less
          </button>
        )}
      </div>
      {/* API_LOADING */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {isApiWorking && (
        <div style={{ textAlign: "center", marginBottom: "60px" }}>
          Loading...
          <br />
        </div>
      )}
      {/* Blog List & Empty View */}
      {!isApiWorking && (
        <div>
          {blogs.filter((blog) => filterBlogs(blog, searchText, filterTags))
            .length === 0 && <BlogEmptyList />}
          {blogs.filter((blog) => filterBlogs(blog, searchText, filterTags))
            .length !== 0 && (
            <BlogList
              blogs={blogs.filter((blog) =>
                filterBlogs(blog, searchText, filterTags)
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BlogHome;
