import React, { useState, useEffect } from "react";
import VideosHeader from "./VideosHeader";
import "./Videos.css";
import Footer from "../../components/footer/Footer";
import isEmpty from "../../components/isEmpty";
import VideoInfoModal from "./VideoInfoModal";
import { searchOMVApi } from "../../utils/apis/public";
import Moment from "moment";
import VideosLoader from "./VideosLoader";

// Font Awesome icon class
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//Videos
function Videos(props) {
  // Parse query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const initialSearchText = (urlParams.get("search") || "").replace(/%/g, " ");
  const initialFilterTags = urlParams.getAll("tag") || [];

  const [videos, setVideos] = useState([]);
  const [searchText, setSearchText] = useState(initialSearchText);
  const [filterTags, setFilterTags] = useState([]);

  const [videoInfo, setVideoInfo] = useState(null);
  const [isApiWorking, setApiWorking] = useState(false);

  useEffect(() => {
    // Any initialization code you want to run when the component mounts
    // console.log(initialFilterTags);
    searchOMV(initialSearchText);
  }, []);

  function searchBtnPressed(e) {
    e.preventDefault();
    searchOMV(searchText);
  }

  async function searchOMV(search_text) {
    setApiWorking(true);
    try {
      var queryParams = [];
      queryParams.push(
        `search=${search_text.toLowerCase().replace(/" "/g, "+")}`
      );

      let qParameters = queryParams.join("&");
      //✅SUCCESS
      const result = await searchOMVApi(qParameters);
      const videos = result["omvs"];
      console.log(result);
      setVideos(videos);
      console.log(result);
      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      console.log(error.message);
      setApiWorking(false);
    }
  }

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleTagClick = (tag) => {
    if (!filterTags.includes(tag)) {
      setFilterTags([...filterTags, tag]);
    }
    setSearchText(tag);
    //
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const filterVideos = (video) => {
    // Check if the video's title contains the searchText (case-insensitive)
    const titleMatch = video.a_title
      .toLowerCase()
      .includes(searchText.toLowerCase());

    // Check if any of the filterTags are included in the video's tags (case-insensitive)
    const tagsMatch =
      filterTags.length === 0 ||
      filterTags.some((tag) =>
        video.tags.some((videoTag) =>
          videoTag.toLowerCase().includes(searchText.toLowerCase())
        )
      );

    // If the search bar is empty, return true to show all videos
    if (!searchText.trim()) {
      return true;
    }

    //return if titleMatch or tagsMatch
    return titleMatch || tagsMatch;
  };

  const getUniqueTags = () => {
    const allTags = videos.reduce((tags, video) => {
      return tags.concat(video.tags);
    }, []);

    return Array.from(new Set(allTags));
  };

  const infoBtnClicked = (video) => {
    setVideoInfo(video);
  };

  const closeVideoInfo = () => {
    setVideoInfo(null);
  };

  return (
    <div className="videos">
      {videoInfo && (
        <VideoInfoModal video={videoInfo} onClose={closeVideoInfo} />
      )}
      <VideosHeader />

      <div className="search-bar">
        <form>
          <div className="input-container">
            <input
              type="text"
              name="focus"
              required
              placeholder="Enter search term"
              value={searchText}
              onChange={handleSearch}
            />
            <button
              className="close-icon"
              type="reset"
              onClick={(e) => setSearchText("")}
            >
              <i className="fa fa-times-circle" />
            </button>
            <div className="sbtn-wrap">
              <button
                type="submit"
                // disabled={!searchText || isApiWorking}
                onClick={(e) => searchBtnPressed(e)}
              >
                {!isApiWorking && <i className="fa fa-search" />}
                {isApiWorking && (
                  <i className="fa fa-circle-o-notch spin-icon" />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {isApiWorking && <VideosLoader />}
      <div className="filter-tags">
        {getUniqueTags().map((tag, tagIndex) => (
          <span
            key={tagIndex}
            className={`tag ${searchText.includes(tag) ? "active" : ""}`}
            onClick={() => handleTagClick(tag)}
          >
            {tag}
          </span>
        ))}
      </div>
      <div className="video-container">
        {videos.filter(filterVideos).map((video, index) => (
          <div key={index} className="video-card block-display">
            {isNewVideo(video.created_at) && (
              <span className="new-tag">New</span>
            )}
            <iframe
              title={video.a_title}
              src={video.video_url}
              frameBorder="0"
              allowFullScreen
              height={220}
            ></iframe>
            <div className="title_wrapper">
              {!isEmpty(video.t_link) && (
                <a
                  className="title_url"
                  href={`${video.t_link}`}
                  alt=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <i className="fa fa-play" aria-hidden="true"></i> */}
                  {/* <i className="fa fa-music" aria-hidden="true"></i> */}
                  <i className="fa fa-external-link-square" />
                  {video.a_title}
                </a>
              )}
              <div className="info">
                Buy the album, support the artist, drop songs
              </div>
              {isEmpty(video.t_link) && (
                <p className="title_nolink">{video.a_title}</p>
              )}
              <button onClick={() => infoBtnClicked(video)}>
                <i className="fa fa-info-circle" />
              </button>
            </div>
            <div className="video-tags">
              {video.tags.map((tag, tagIndex) => (
                <span
                  key={tagIndex}
                  className={`tag ${searchText.includes(tag) ? "active" : ""}`}
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
      {videos.filter(filterVideos).length === 0 && !isApiWorking && (
        <div className="empty-data-view">
          <p>No videos found. Please try a different search.</p>
        </div>
      )}

      <Footer />
    </div>
  );

  function isNewVideo(created_at) {
    const createdAt = Moment(created_at); // Assuming video.created_at is a valid date string
    const now = Moment();
    const differenceInDays = now.diff(createdAt, "days");

    return differenceInDays <= 7; // Adjust the comparison as needed
  }
}

export default Videos;
