import React, { useState, useEffect, useRef } from "react";
import isEmpty from "../../components/isEmpty";

///
const additionalVariations = [
  "celebration! 🎉",
  "morning! 🌅",
  "rain! ☔️",
  "mood! 😊",
  "rhythm! 🎶",
  "harmony! 🎵",
  "melody! 🎼",
  "beats! 🔊",
  "groove! 🕺",
  "jam! 🎸",
  "concert! 🎤",
  "performance! 🎵",
  "party! 🎊",
  "dance! 💃",
  "playlist! 🎧",
  "album! 📀",
  "song! 🎶",
  "musician! 🎻",
  "band! 🎸",
  "studio! 🎙️",
  "tour! 🚌",
  "beatbox! 🎤",
  "chord! 🎸",
  "verse! 🎶",
  "tune! 🎵",
  "symphony! 🎶",
  "gig! 🎵",
  "melodic! 🎶",
  "vibes! 🎶",
  "chorus! 🎶",
];

const AnimationStates = {
  WRITING: 1,
  WAITING: 2,
  REVERSING: 3,
  CHANGING: 4,
};

function QRTitle({ hashtag }) {
  const animationDuration = 100;
  const waitDuration = 2000;
  const delayBeforeRepeat = 500;
  const [currentText, setCurrentText] = useState("party!🎉");
  const [textToPrint, setTextToPrint] = useState("");
  const textToPrintRef = useRef("");
  const currentTextRef = useRef("");
  const animationStateRef = useRef(AnimationStates.WRITING);

  useEffect(() => {
    textToPrintRef.current = textToPrint;
    currentTextRef.current = currentText;
  }, [textToPrint, currentText]);

  useEffect(() => {
    const updateText = () => {
      const currentAnimationState = animationStateRef.current;
      switch (currentAnimationState) {
        case AnimationStates.WRITING:
          //   console.log("Animation state: writing");
          const newText = currentTextRef.current.substring(
            0,
            textToPrintRef.current.length + 1
          );
          setTextToPrint(newText);
          if (newText.length === currentTextRef.current.length) {
            // console.log(
            //   "Writing animation completed. Transitioning to WAITING state"
            // );
            animationStateRef.current = AnimationStates.WAITING;
            setTimeout(updateText, waitDuration);
          } else {
            setTimeout(updateText, animationDuration);
          }
          break;
        case AnimationStates.WAITING:
          //   console.log("Animation state: waiting");
          //   console.log("Transitioning to REVERSING state");
          animationStateRef.current = AnimationStates.REVERSING;
          setTimeout(updateText, waitDuration);
          break;
        case AnimationStates.REVERSING:
          //   console.log("Animation state: reversing");
          const reversedText = textToPrintRef.current.substring(
            0,
            textToPrintRef.current.length - 1
          );
          setTextToPrint(reversedText);
          if (reversedText === "") {
            // console.log(
            //   "Reversing animation completed. Transitioning to CHANGING state"
            // );
            animationStateRef.current = AnimationStates.CHANGING;
            setTimeout(updateText, waitDuration);
          } else {
            setTimeout(updateText, animationDuration);
          }
          break;
        case AnimationStates.CHANGING:
          //   console.log("Animation state: changing");
          setCurrentText(getRandomVariation());
          //   console.log("Transitioning to WRITING state");
          animationStateRef.current = AnimationStates.WRITING;
          setTimeout(updateText, delayBeforeRepeat);
          break;
        default:
          break;
      }
    };

    setCurrentText(getRandomVariation());
    updateText();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getRandomVariation() {
    const randomIndex = Math.floor(Math.random() * additionalVariations.length);
    return additionalVariations[randomIndex];
  }
  const [showCursor, setShowCursor] = useState(true);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500); // Change cursor visibility every 500ms

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="qrtitle">
      Print <strong>{hashtag}</strong> QR for your{" "}
      {!isEmpty(textToPrint) && (
        <strong
          style={{
            backgroundColor: "orange",
            color: "white",
            padding: "0px 4px 0px 4px",
            borderRadius: "2px",
          }}
        >
          {textToPrint}
        </strong>
      )}
      {showCursor && "|"} {/* Render cursor if showCursor is true */}
    </div>
  );
}

export default QRTitle;
