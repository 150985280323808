import React, { useState, useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import isEmpty from "../../../components/isEmpty";
import EmailSubscription from "../../dashboard/emailsubscription/EmailSubscription";

import Footer from "../../../components/footer/Footer";
//api
import { getNewsletterUserAPI } from "../../../utils/apis/public";

import _placeholder from "../../../assets/img/ic_placeholder_profile.png";
import audience from "./audience.png";
//CSS
import "./Subscribe.css";
import MusicGenres from "./MusicGenres";

function Subscribe(props) {
  const snewbodyStyles = {
    backgroundImage: `url(""), url(${audience}), url(""), url(""), url("")`,
  };

  //API
  //tag_id
  const [tag_id, setTagId] = useState("");
  const [url, setURL] = useState(null);
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");
  const [user, setUser] = useState(null);

  const [email_form_visible, setEmailFormVisible] = useState(false);
  //SEND TO API BACK
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Subscribe | SongDrop";
    //get path, remove extra stuff it has
    const username = new URL(window.location.href).pathname
      .split("/")
      .filter(Boolean)[1];
    setURL(username);
    ///
    //random tag_id
    const randomTagId = generateRandomTagId();
    setTagId(randomTagId);
    //IF USERNAME EXIST -> DOWNLOAD USERNAME DATA
    if (username) {
      _getUserName(username, randomTagId);
    } else {
      _getUserName("songdropofficial", randomTagId);
    }
  }, []);

  //API_CALL
  async function _getUserName(username, randomTagId) {
    setApiWorking(true);
    setApiError("");

    try {
      //queryparams
      var queryparams = [];
      queryparams.push(`username=${username}`);
      let qParameters = queryparams.join("&");
      //bodyparams
      var bodyParams = [];
      bodyParams.push(`tag_id=${randomTagId}`);
      let bParameters = bodyParams.join("&");

      let result = await getNewsletterUserAPI(qParameters, bParameters);
      // console.log(result);
      //✅SUCCESS
      setUser(result["user"]);
      setApiWorking(false);
      setSelectedArtists([result["user"]["username"]]);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  function scrollToUserSignup() {
    setEmailFormVisible(!email_form_visible);
    const element = document.getElementById("usersignup");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  //VIEW
  return (
    <div className="snewbody" style={snewbodyStyles}>
      <div id="snewbodytop"></div>
      <div className="snwblock">
        {isApiWorking && isEmpty(user) && (
          <div className="userloader">
            <span class="uanimloader"></span>
          </div>
        )}
        {!isApiWorking && !isEmpty(user) && (
          <div>
            <div className="userheader">
              <div className="left">
                <Zoom>
                  <img
                    src={
                      isEmpty(user.profile_img_url)
                        ? _placeholder
                        : user.profile_img_url
                    }
                    alt="User Profile"
                    className="_user-img-sqr"
                  />
                </Zoom>
              </div>
              <div className="right">
                <a href={`/@/${user.username}`} className="username">
                  {user.name}
                </a>
                <div>JOIN THE MAILING LIST</div>
              </div>
            </div>
            <div className="userinvite">
              <div className="_left">
                <ul className="list-style">
                  <li className="list-item">
                    Get notification of album and song Drops
                  </li>
                  <li className="list-item">Exclusive offers</li>
                  <li className="list-item">Music and artist news</li>
                </ul>
              </div>
              <div className="_right">
                <button
                  className="accept_btn"
                  onClick={() => scrollToUserSignup()}
                >
                  <i className="fa fa-envelope"></i>Subscribe
                </button>
              </div>
            </div>
            <div id="usersignup"></div>
            <div>
              {email_form_visible && (
                <div className="usersignup">
                  <div className="fill_out">Please fill out this form</div>
                  <EmailSubscription
                    placeholder="Email(required)"
                    tag_id={tag_id}
                    isApiWorking={false}
                    selectedArtists={selectedArtists}
                    selectedGenres={selectedGenres}
                  />
                  <MusicGenres setSelectedGenres={setSelectedGenres} />
                </div>
              )}
            </div>
            <div className="talk">
              <a href="/" alt="" target="_blank">
                SongDrop
              </a>{" "}
              is the place for Independent Musicians and their fans. Whether you
              are excited about new indie releases, music news or supporting
              your favourite artists{" "}
              <a
                href={`/@/${user.username}`}
                alt=""
                target="_blank"
                rel="noreferrer"
              >
                join {user.name} on SongDrop.
              </a>{" "}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );

  function generateRandomTagId() {
    const letters = "abcdefghijklmnopqrstuvwxyz";
    let tagId = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      tagId += letters[randomIndex];
    }

    return tagId;
  }
}

export default Subscribe;
