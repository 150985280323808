import React, { useState, useEffect } from "react";
import { unsubscribeToNewsLetterAPI } from "../../../utils/apis/public";
import "./Unsubscribe.css";
import unsubscribeImage from "../../../assets/img/songdrop-logo-black.png"; // Path to your SongDrop logo image
function Unsubscribe() {
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.title = "Unsubscribe | SongDrop";
    const urlSearchParams = new URLSearchParams(window.location.search);
    const emailParam = urlSearchParams.get("email");
    setEmail(emailParam);

    if (emailParam) {
      unsubscribe(emailParam); // Assuming you have an unsubscribe function that takes an email as a parameter
    }
  }, []);

  async function unsubscribe(email) {
    try {
      var queryParams = [];
      queryParams.push(`email=${email.toLowerCase()}`);

      let qParameters = queryParams.join("&");
      const result = await unsubscribeToNewsLetterAPI(qParameters);
      console.log(result);
      //   setIsUnsubscribed(isUserUnsubscribed);
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleResubscribe = () => {
    window.location.href = "https://songdrop.uk/subscribe";
  };

  const backToHome = () => {
    window.location.href = "https://songdrop.uk";
  };

  return (
    <div className="unsubscribe-container">
      <img
        src={unsubscribeImage}
        alt="SongDrop Logo"
        className="songdrop-logo"
      />
      <div className="success-message">
        <i className="fa fa-check-circle" /> Success!
      </div>
      <div className="unsubscribe-message">
        We will no longer send automated newsletter emails to your email -{" "}
        <strong>{email}</strong>. You will still receive account related
        messages, if you used this email on your account.
      </div>
      <div className="button-container">
        <button onClick={backToHome} className="resubscribe-button">
          Back to home
        </button>
      </div>
      <br></br>
      <div className="button-container">
        <button onClick={handleResubscribe} className="back-link">
          Mistake?
        </button>
      </div>
    </div>
  );
}

export default Unsubscribe;
