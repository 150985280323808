import React, { useState, useEffect } from "react";
import ScanQRPhone from "./scanQRPhone/ScanQRPhone";
//QRCam class
function QRCam(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div
      style={{
        background: "black",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "12px",
      }}
    >
      <ScanQRPhone />
    </div>
  );
}

export default QRCam;
