const items = [
  {
    index: 1,
    question: "What is SongDrop and how does it work?",
    answer:
      "SongDrop is a platform for discovering and purchasing independent music. You can browse, listen to, and purchase songs from independent artists.",
  },
  {
    index: 2,
    question: "How can I purchase and download music on SongDrop?",
    answer:
      "You can purchase songs and albums on the SongDrop iOS app using Apple's In-App Purchase system. When you click on the price tag, you will be prompted to confirm the purchase. Once the payment is successful, the music will be added to your library and will be available for streaming or manual download. If you choose to not download the music, it will still be accessible via streaming.",
  },
  {
    index: 3,
    question:
      "What is the cost of using SongDrop and are there any subscription fees?",
    answer:
      "The cost of using SongDrop varies depending on the song or album you purchase. Individual songs have a set price, while albums may have a different pricing structure. There are no subscription fees for using SongDrop.",
  },
  {
    index: 4,
    question: "What types of independent music can I find on SongDrop?",
    answer:
      "SongDrop features a wide variety of independent music from various genres.",
  },
  {
    index: 5,
    question: "How can I share my favorite songs as Drops on my profile?",
    answer:
      "You can create a 'Drop' of their purchased song and share it with their followers. This gives followers 24h free access to the song and helps the artist spread their music and encourages further purchases.",
  },
  {
    index: 6,
    question: "What are the minimum requirements for using SongDrop?",
    answer:
      "The minimum requirements for using SongDrop is an iOS device running at least iOS v11.0.",
  },
  {
    index: 7,
    question: "Is SongDrop currently only available in the UK?",
    answer: "Yes, SongDrop is currently only available in the UK.",
  },
  {
    index: 8,
    question:
      "Are there any plans to expand SongDrop to other countries and devices?",
    answer:
      "SongDrop is planning to expand to other countries and devices in the future.",
  },
  {
    index: 9,
    question: "How can I provide feedback or report issues with SongDrop?",
    answer:
      "You can provide feedback and report issues through support@songdrop.uk, using our iOS reporting feature, or by joining our official Discord community.",
  },
  {
    index: 10,
    question:
      "How can I stay updated on new features and releases for SongDrop?",
    answer:
      "You can stay up to date on new features and updates by subscribing to the SongDrop newsletter, following us on social media, or joining our official Discord community.",
  },
];

export default items;
