export function URLParser() {
  return new URL(window.location.href).searchParams
    .toString()
    .split("&")
    .reduce((previous, current) => {
      const [key, value] = current.split("=");
      previous[key] = value;
      return previous;
    }, {});
}
