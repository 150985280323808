import React, { useEffect, useState } from "react";
import ReactPdf from "./ReactPdf";
import { URLParser } from "../../components/URLParser";
//JS
import Footer from "../../components/footer/Footer";
//CSS
import "./Legal.css";
import about from "../../assets/about/about.json";

//UAP class
function UAP() {
  var [noclick, setNoClick] = useState("0");

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Terms of Usage Policy | SongDrop";

    //url paths
    let url_paths = URLParser();
    setNoClick(url_paths["no_click"] ?? "0");
  }, []);

  //VIEW
  return (
    <div className="legalbody">
      <div className="header">
        <div className="title">SONGDROP TERMS OF USAGE POLICY</div>
      </div>
      <div className="content">
        <ReactPdf url={about.terms_pdf} />
      </div>
      {noclick === "0" && <Footer />}
    </div>
  );
}

export default UAP;
