import React, { useState, useEffect } from "react";
import placeholder from "../../assets/img/ic_placeholder_profile.png";
import isEmpty from "../../components/isEmpty";
import Moment from "moment";
//CSS
import "./NewReleases.css";

//NewReleases class
function NewReleases(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="new_releases">
      <div className="n_title_wrap">
        <p>
          <i className="fa fa-volume-up"></i> Latest Releases
        </p>
      </div>
      <div className="n_data">{tableData(props.new_releases)}</div>
    </div>
  );

  function tableData(data) {
    var model = [];
    data.forEach((item, index) => {
      //here is your code
      model.push(
        <a className="n_item" key={index} alt="" href={`/qr/${item.album_id}`}>
          <div>
            <img
              alt=""
              src={item.cover_img_url || placeholder}
              className="n_item_img"
            />
          </div>
          <div className="title">
            <strong>{item.title}</strong>
          </div>
          <div className="artist">{item.artist}</div>
          <div className="year">{setReleaseYear(item.released_at)}</div>
        </a>
      );
    });
    return model;
  }

  //Release year
  function setReleaseYear(releaseDate) {
    if (isEmpty(releaseDate)) {
      return "";
    }
    return Moment(new Date(releaseDate)).format("yyyy");
  }
}

export default NewReleases;
