import React, { useEffect } from "react";
import "./InvestorPage.css";

//InvestorPage
const InvestorPage = () => {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "SongDrop | Investors";
  }, []);

  //VIEW
  return (
    <div className="investor-page">
      <header>
        <h1>SongDrop Investor 1-Pager</h1>
      </header>

      <section className="section">
        <h2>About SongDrop</h2>
        <p>
          <strong>SongDrop</strong> is a dedicated music application that offers
          a platform for buying, selling, and sharing music created by
          independent artists. Our app facilitates seamless transactions for
          purchasing and selling songs and albums. Additionally, we introduce a
          unique feature called 'Drops,' allowing music to be shared for a
          period of 24 hours. Our core mission is to emphasize the advantages of
          supporting and promoting independent musicians.
        </p>
        <p>
          Download our app from our website:{"\n🌎 "}
          <a href="https://songdrop.uk">https://songdrop.uk</a>
        </p>
      </section>

      <section className="section">
        <h2>Problems without SongDrop</h2>
        <ul>
          <li>
            🚫 Limited stream time on streaming services makes it difficult for
            musicians to earn significant income.
          </li>
          <li>
            🚫 Outdated CD technology: Physical sales are no longer a viable
            option.
          </li>
          <li>🚫 No direct relationship between creators and listeners.</li>
        </ul>
      </section>

      <section className="section">
        <h2>Solutions with SongDrop</h2>
        <ul>
          <li>
            ✅ Unlimited streaming time: Musicians can upload and share music
            without restrictions, increasing revenue and fanbase.
          </li>
          <li>
            ✅ Digital sales platform: Artists can directly sell their music to
            fans.
          </li>
          <li>
            ✅ Electronically Distributed (ED) sales: Modernizing physical
            distribution.
          </li>
          <li>
            ✅ Direct fan engagement: Tools to connect artists and fans,
            promoting music and growth.
          </li>
        </ul>
      </section>

      <section className="section">
        <h4>Return on Investment (ROI)</h4>
        <div className="section2">
          <h3>
            <i className="fa fa-money" /> Revenue Streams
          </h3>
          <p>
            <p>
              Invest in SongDrop for direct participation in our diverse revenue
              streams:
            </p>{" "}
          </p>
          <ul>
            <li>
              <strong>1. Digital Sales:</strong> Artists selling music directly
              to fans drive revenue with every sale, where we earn a percentage
              for every transaction.
            </li>
            <li>
              <strong>2. Electronically Distributed(ED) Sales:</strong> Our ED
              format targets music enthusiasts, boosting consumer value and
              driving higher album sales with increased basket value.
            </li>
            <li>
              <strong>3. Industry Partnerships:</strong> Benefit from powerful
              networking within and beyond the music industry.
            </li>
          </ul>
        </div>
        <div className="section2">
          <h3>
            <i className="fa fa-signal" /> Growth and Expansion
          </h3>
          <p>Fuel growth and revenue through:</p>
          <ul>
            <li>
              <strong>1.User Engagement:</strong> More artists, releases,
              listeners and purchases lead to increased income streams.
            </li>
            <li>
              <strong>2.Geographical Expansion:</strong> Plans for global
              expansion tap into diverse music cultures, broadening our customer
              base and increased sales.
            </li>
            <li>
              <strong>3.Partnerships and Collaborations:</strong> Artists and
              event organizers for co-promotion, revenue-sharing, and amplified
              brand visibility.
            </li>
          </ul>
        </div>
        <div className="section2">
          <h3>
            {" "}
            <i className="fa fa-percent" /> Projected ROI
          </h3>
          <p>
            Envision a future of impressive returns through your investment,
            driven by:
          </p>{" "}
          <ul>
            <li>
              <strong>1. Revenue Growth:</strong> Expanding user base and
              diversified income streams directly boost investor earnings,
              ensuring consistent income escalation.
            </li>
            <li>
              <strong>2. Innovative Positioning:</strong> Propel the music
              industry into a new era through cutting-edge digital and ED
              distribution methods, captivating a broader audience and
              accelerating investor rewards.
            </li>
            <li>
              <strong>3. Scalability:</strong> Capitalize on our app's
              scalability to handle expanding demand and growth. As user
              engagement and revenue surge, so does your earnings, making
              scalability a pivotal force behind heightened profitability.
            </li>
          </ul>
        </div>
      </section>
      <div className="section">
        <h3>Summary</h3>
        <p>
          In summary, investing in SongDrop offers the potential for substantial
          returns, driven by a diverse range of revenue streams, strategic
          growth initiatives, and a strong focus on user satisfaction. Join us
          on this exciting journey as we revolutionize the way independent
          musicians connect with their fans and monetize their craft.
        </p>
      </div>
      <div className="section">
        <h2>Technology Partners</h2>
        <div className="partner-logos">
          <a
            href="https://aws.amazon.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Flogos-download.com%2Fwp-content%2Fuploads%2F2016%2F12%2FAmazon_Web_Services_logo_AWS.png&f=1&nofb=1&ipt=b41b62b492bafa382db052995ce40e161ecc6decf5c8aeddbdd0f72ed23faa46&ipo=images"
              alt="Amazon Web Services"
            />
          </a>
          <a
            href="https://www.digitalocean.com/?refcode=582fcc29135e&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%203.svg"
              alt="DigitalOcean Referral Badge"
            />
          </a>

          <a
            href="https://www.apple.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fw7.pngwing.com%2Fpngs%2F506%2F939%2Fpng-transparent-app-store-logo-iphone-app-store-get-started-now-button-electronics-text-telephone-call.png&f=1&nofb=1&ipt=c91d97a4aeedd49896587b20471940ea1530cc47c21f010367d6cc453b5eaba3&ipo=images"
              alt="Apple Inc."
            />
          </a>
          <a
            href="https://www.paypal.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.dotgo.uk%2Fshared%2Fimages%2Fcontent%2Fbus_53447%2Fblog%2Fbanner-paypal-800x300.jpg&f=1&nofb=1&ipt=40e996b38f33c656e03563e586757e377380d5a436127695ec9f60156654f0a9&ipo=images"
              alt="PayPal"
            />
          </a>
          <a
            href="https://react.dev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.icon-icons.com%2Ficons2%2F2699%2FPNG%2F512%2Freactjs_logo_icon_170805.png&f=1&nofb=1&ipt=5360eb786a494f54a32022d8461c4cf873bd7dd9279144fd221d11735b191401&ipo=images"
              alt="React.JS"
            />
          </a>
          <a
            href="https://www.apple.com/uk/swift/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi1.wp.com%2Festatuani.com%2Fwp-content%2Fuploads%2F2019%2F10%2Fswift-8.jpg&f=1&nofb=1&ipt=e5f0388044d8ecc6508d81dec1cc666e1a6220939afacf06c67f3af3aa552f5b&ipo=images"
              alt="Swift Apple"
            />
          </a>
          <a
            href="https://www.python.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Flogos-download.com%2Fwp-content%2Fuploads%2F2016%2F10%2FPython_logo_wordmark.png&f=1&nofb=1&ipt=1a4ca049f3caff6ca041045b78fec30c3305c769bba38fdcaa53dcb80027b27f&ipo=images"
              alt="Python"
            />
          </a>
          <a href="https://js.org/" target="_blank" rel="noopener noreferrer">
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fthesafety.us%2Fimages%2Farticles%2Fjavascript-logo.png&f=1&nofb=1&ipt=3f0e414e8bdd6f117ae5fc2b6f05f9bf6549f34eb9c6c1eaac011e41697d82f0&ipo=images"
              alt="Javascript"
            />
          </a>
          <a
            href="https://www.mysql.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F24x7servermanagement.com%2Fwp-content%2Fuploads%2F2016%2F09%2Fmysql-logo.jpg&f=1&nofb=1&ipt=de0acafd8ef0bd08452451d2649f49ebdc749ff4ab829e635fe7c73f6f10ab8a&ipo=images"
              alt="MySQL"
            />
          </a>
          <a
            href="https://en.wikipedia.org/wiki/MP3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Mp3.svg/400px-Mp3.svg.png"
              alt="MP3"
            />
          </a>
        </div>
      </div>
      <section>
        <p>&copy; {new Date().getFullYear()} SongDrop. All rights reserved.</p>
        <p>
          For more information or to express your interest, please contact us at{" "}
          <a href="mailto:investors@songdrop.uk">investors@songdrop.uk</a> or
          visit our website at{" "}
          <a href="https://songdrop.uk">https://songdrop.uk</a>.
        </p>
      </section>
    </div>
  );
};

export default InvestorPage;
