import React, { useState, useEffect } from "react";
import EmailSubscription from "./EmailSubscription";

//CSS
import "./Newsletter.css";

//Newsletter class
function Newsletter(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="newsletter">
      <div className="left">
        <div className="title">
          <i className="fa fa-bell" />
          Join our newsletter{" "}
        </div>
        <div className="subtitle">
          Never miss out on new albums, exclusive discounts, and more when you
          join the SongDrop mailing list.
        </div>
      </div>
      <div className="right">
        <EmailSubscription isApiWorking={false} />
      </div>
    </div>
  );
}

export default Newsletter;
