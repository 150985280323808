import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

//ROUTES
import Dashboard from "./containers/dashboard/Dashboard3";
import Validate from "./containers/validate/Validate";
import ResetPass from "./containers/resetpass/ResetPass";
import QrPage from "./containers/qr/QrPage";
import CookiePolicy from "./containers/legal/CookiePolicy";
import TermsPolicy from "./containers/legal/TermsPolicy";
import PrivacyPolicy from "./containers/legal/PrivacyPolicy";
import RadioPrivacyPolicy from "./containers/legal/RadioPrivacyPolicy";
import EDShopPolicy from "./containers/legal/EDShop_Terms";
import UAPPolicy from "./containers/legal/UAP";
import FAQ from "./containers/faq/Faq";
import Copyright from "./containers/copyright/Copyright";
import CopyrightCase from "./containers/copyright_case/Copyright_Case";
import Countries from "./containers/countries/Countries";
import NotFound from "./containers/notfound/NotFound";
import Charts from "./containers/charts/Charts";
import Beta from "./containers/beta/Beta";
import BlogHome from "./containers/blog/Blog";
import BlogContent from "./containers/blog/BlogContent/BlogContent";
import MemoryGame from "./containers/memory_game/MemoryGame";
import ProfileCard from "./containers/@/ProfileCard";
import SystemUpdates from "./containers/system_updates/System_Updates";
import HashtagPlayer from "./containers/hashtag/HashtagPlayer";
import Support from "./containers/support/Support";
import InvestorPage from "./containers/investors/InvestorPage";
import Subscribe from "./containers/newsletter/subscribe/Subscribe";
import Unsubscribe from "./containers/newsletter/unsubscribe/Unsubscribe";
import Tutorials from "./containers/tutorials/Tutorials";
import Videos from "./containers/videos/Videos";
import Download from "./containers/download/Download";
import MobileApp from "./containers/mobileapp/MobileApp";
import Radio from "./containers/radio/Radio";
import RadioApp from "./containers/radio/RadioApp";
import RedirectQRDownload from "./containers/download/RedirectQRDownload";
import QRCam from "./containers/qr/QrCam/QrCam";
import Meta from "./containers/meta/Meta";
//AppRoutes class
function AppRoutes({ setActiveHashtag, shareMyProfile }) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <Switch>
      <Route exact path="/">
        <Dashboard />
      </Route>
      <Route exact path="/meta">
        <Meta />
      </Route>
      <Route exact path="/@/songdropofficial">
        <ProfileCard shareMyProfile={shareMyProfile} />
      </Route>
      <Route exact path="/@/:id">
        <ProfileCard shareMyProfile={shareMyProfile} />
      </Route>
      <Route exact path="/helloworldhellouk">
        <Dashboard />
      </Route>
      <Route exact path="/radioapp">
        <Radio />
      </Route>
      <Route path="/radio">
        <RadioApp />
      </Route>
      <Route exact path="/downloads/:id">
        <Download />
      </Route>
      <Route exact path="/blog">
        <BlogHome />
      </Route>
      <Route exact path="/blog/:id">
        <BlogContent />
      </Route>
      <Route exact path="/qrapp">
        <RedirectQRDownload />
      </Route>
      <Route exact path="/qrcam">
        <QRCam />
      </Route>
      <Route exact path="/supportus">
        <Support />
      </Route>
      <Route path="/hashtag">
        <HashtagPlayer setActiveHashtag={setActiveHashtag} />
      </Route>
      <Route exact path="/hashtag/:id">
        <HashtagPlayer setActiveHashtag={setActiveHashtag} />
      </Route>
      <Route path="/charts">
        <Charts />
      </Route>
      <Route exact path="/beta">
        <Beta />
      </Route>
      <Route exact path="/validate:id">
        <Validate />
      </Route>
      <Route exact path="/resetpass">
        <ResetPass />
      </Route>
      <Route path="/countries">
        <Countries />
      </Route>
      <Route path="/qr">
        <QrPage />
      </Route>
      <Route path="/updates">
        <SystemUpdates />
      </Route>
      <Route path="/privacy">
        <PrivacyPolicy />
      </Route>
      <Route path="/radio_privacy">
        <RadioPrivacyPolicy />
      </Route>
      <Route path="/terms">
        <TermsPolicy />
      </Route>
      <Route path="/edshop_terms">
        <EDShopPolicy />
      </Route>
      <Route path="/cookies">
        <CookiePolicy />
      </Route>
      <Route path="/uap">
        <UAPPolicy />
      </Route>
      <Route path="/faq">
        <FAQ />
      </Route>
      <Route path="/copyright_claim">
        <Copyright />
      </Route>
      <Route path="/copyright_case">
        <CopyrightCase />
      </Route>
      <Route path="/investors">
        <InvestorPage />
      </Route>
      <Route path="/sub/:id">
        <Subscribe />
      </Route>
      <Route path="/s/:id">
        <Subscribe />
      </Route>
      <Route path="/subscribe">
        <Subscribe />
      </Route>
      <Route path="/unsubscribe">
        <Unsubscribe />
      </Route>
      <Route path="/tutorials">
        <Tutorials />
      </Route>
      <Route path="/omv">
        <Videos />
      </Route>
      <Route path="/mobileapp">
        <MobileApp />
      </Route>
      {/* <Route exact path="/memorygame">
            <MemoryGame />
          </Route> */}
      {/* NOT FOUND */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default AppRoutes;
