import React, { useState, useEffect } from "react";
import about from "../../assets/about/about.json";

const RedirectQRDownload = () => {
  const [device, setDevice] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      setDevice("Android");
      window.location.href = about.playstore_url;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDevice("iOS");
      window.location.href = about.appstore_url;
    } else {
      setDevice("Unknown");
    }
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  );
};

export default RedirectQRDownload;
