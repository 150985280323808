import React, { useEffect, useState } from "react";
import isEmpty from "../../components/isEmpty";
import Linkify from "react-linkify";
import Highlight from "react-highlighter";
import { URLParser } from "../../components/URLParser";
import { getFaqDash, searchFaq } from "../../utils/apis/public";

//JS
import SearchBar from "../../components/search/SearchBar/SearchBar";
import Footer from "../../components/footer/Footer";
//img
import logoIcon from "../../assets/img/songdrop-logo-white.png";
import faqIcon from "../../assets/img/ic_faq.png";
//css
import "./Faq.css";
import faqDocs from "../../assets/json/faq.json";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const chevronDown = "fa fa-chevron-down";
const chevronRight = "fa fa-chevron-right";
const faExpand = "fa fa-plus-square";
const faCollapse = "fa fa-minus-square";

//baritems
const searchBarPageItems = [{ title: "faq", link: "/faq" }];

//ResetPass class
function FAQ(props) {
  //api
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  const [openAll, setOpenAll] = useState(true);
  const [openedIndex, setOpenedIndex] = useState({ section: -99, row: -99 });

  const [searchText, setSearchText] = useState("");
  //pageitems
  const [faqItems, setFaqItems] = useState(searchBarPageItems);

  //categories
  const [faq_categories, setFaqCategories] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "FAQ | SongDrop.uk";
    //api
    let url_paths = URLParser();
    var searchText = url_paths["search"] ?? "";
    if (isEmpty(searchText)) {
      getData();
    } else {
      setSearchText(searchText);
      searchData(searchText);
    }
  }, []);

  //API
  async function getData() {
    setApiWorking(true);
    setApiError("");

    try {
      //✅SUCCESS
      let result = await getFaqDash();
      let faq_categories = result["categories"];
      console.log("RESULT", faq_categories);
      //
      setFaqCategories(faq_categories);
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //SEARCH
  async function searchData(searchText) {
    setApiWorking(true);
    setApiError("");

    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`search=${searchText}`);
      const qParameters = queryParams.join("&");
      //✅SUCCESS
      let result = await searchFaq(qParameters);
      let faq_categories = result["categories"];
      console.log("RESULT", faq_categories);
      //
      setFaqCategories(faq_categories);
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //VIEW
  return (
    <div className="faq-container">
      <div className="top">
        <img
          title="SongDrop"
          className="logo"
          alt=""
          src={logoIcon}
          onClick={goToLoginPage}
        />
        <div title="FAQ" className="info" onClick={goToFaq}>
          <img alt="" src={faqIcon} />
        </div>
      </div>
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={searchBarPageItems}
        path="/faq"
        path_char="?"
      />
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}

      <div className="content">
        <div className="welcome">
          <h1>{faqDocs.title}</h1>
          <p>{faqDocs.message}</p>
        </div>
        {isApiWorking && (
          <button disabled={true} className="fa-loader">
            <i className={apiFaIcon} />
          </button>
        )}
        {!isApiWorking && (
          <div>
            <div className="expand_collapse">
              <button onClick={() => collapseAll()}>Collapse</button>
              <button disabled={true}>/</button>
              <button onClick={() => expandAll()}>Expand</button>
            </div>
            {isEmpty(faq_categories) && (
              <div className="no-results">No results found</div>
            )}
            {!isEmpty(faq_categories) && (
              <div className="categories">{faqItemCategories()}</div>
            )}
          </div>
        )}{" "}
        <div className="footercontainer">
          <Footer />
        </div>
      </div>
    </div>
  );

  //FAQ_CATEGORIES
  function faqItemCategories() {
    var tabs = [];
    if (isEmpty(faq_categories)) {
      return tabs;
    }
    // Sort faq_categories by title in alphabetical order
    var sorted_faq_categories = faq_categories.sort(function (left, right) {
      return left.title.localeCompare(right.title);
    });
    sorted_faq_categories.forEach((category, category_index) => {
      let row = (
        <div key={category.category_id} className="category">
          <div
            className="_title"
            onClick={() => openCloseCategory(category.category_id)}
          >
            <i
              className={
                isCatToggled(category.category_id) ? chevronDown : chevronRight
              }
              style={{ marginRight: "6px" }}
            />
            {category.title}
          </div>
          {openedIndex.section === category.category_id && (
            <div>
              <div className="_text">{category.description}</div>
              {/* <div className="_hashtags">{category.hashtags}</div> */}
            </div>
          )}
          {faqItemTopics(category.topics, category.category_id)}
        </div>
      );

      tabs.push(row);
    });
    return tabs;
  }

  //FAQ_TOPICS
  function faqItemTopics(category_topics, category_id) {
    var tabs = [];
    if (isEmpty(category_topics)) {
      return tabs;
    }
    // Sort category_topics by title in alphabetical order
    var sorted_faq_topics = category_topics.sort(function (left, right) {
      return left.title.localeCompare(right.title);
    });
    sorted_faq_topics.forEach((topic, topic_index) => {
      let row = (
        <div key={topic_index} className="topic">
          <div
            className="_topic-title"
            onClick={() => openCloseTopic(category_id, topic.topic_id)}
          >
            <i
              className={
                isTopicToggled(category_id, topic.topic_id)
                  ? faCollapse
                  : faExpand
              }
              style={{ marginRight: "6px" }}
            />
            {topic.title}
          </div>
          {isTopicToggled(category_id, topic.topic_id) && (
            <div>
              <div
                className="_topic-text"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                  margin: "2px",
                  padding: "6px",
                }}
              >
                {!isEmpty(searchText) && (
                  <Highlight search={searchText}>{topic.description}</Highlight>
                )}
                {isEmpty(searchText) && (
                  <Linkify> {topic.description} </Linkify>
                )}
              </div>
              {/* <div className="_topic-hashtags">{topic.hashtags}</div> */}
            </div>
          )}
        </div>
      );
      tabs.push(row);
    });
    return tabs;
  }

  function openCloseCategory(category_id) {
    setOpenAll(false);
    if (openedIndex.section === category_id) {
      setOpenedIndex({ section: -99, row: -99 });
    } else {
      setOpenedIndex({ section: category_id, row: 0 });
    }
  }

  function openCloseTopic(category_id, topic_id) {
    setOpenAll(false);
    if (openedIndex.row === topic_id) {
      setOpenedIndex({ section: category_id, row: 0 });
    } else {
      setOpenedIndex({ section: category_id, row: topic_id });
    }
  }

  function expandAll() {
    setOpenAll(true);
  }

  function collapseAll() {
    setOpenAll(false);
    setOpenedIndex({ section: -99, row: -99 });
  }

  function isCatToggled(category_id) {
    if (openAll) {
      return true;
    }
    return openedIndex.section === category_id;
  }

  function isTopicToggled(category_id, topic_id) {
    if (openAll) {
      return true;
    }
    return openedIndex.section === category_id && openedIndex.row === topic_id;
  }

  //show info(what songdrop is about)
  function goToFaq() {
    window.location.href = "/faq";
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/";
  }
}

export default FAQ;
