import React, { useState, useEffect } from "react";
import CountrySelect from "../../components/CountrySelect";
import Moment from "moment";
import emailvalidator from "email-validator";
import { getCopyrightCase } from "../../utils/apis/public";
import isEmpty from "../../components/isEmpty";
import copyObject from "../../components/copyObject";
import { URLParser } from "../../components/URLParser";
//JS
import Footer from "../../components/footer/Footer";

//CSS
import "./Copyright_Case.css";

//assets
import brandlogo from "../../assets/img/songdrop-logo-black.png";

//json
import copyrightJSON from "../../assets/json/copyright.json";
import about from "../../assets/about/about.json";
//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const successFaIcon = "fa fa-check-circle";
const cdownFaIcon = "fa fa-chevron-down";
const cupFaIcon = "fa fa-chevron-up";
const squareFaIcon = "fa fa-square-o";
const checkFaIcon = "fa fa-check-square";

const validation_code_length = 6;

const cform = {
  track_id: "",
  album_id: "",
  claimant: "Myself",
  name: "",
  phone: "",
  email: "",
  description: "",
  country: "",
  address: "",
  city: "",
  county: "",
  postcode: "",
};

const legal = {
  l1: false,
  l2: false,
  l3: false,
};

const cop_mat = {
  album_id: "",
  track_id: "",
  uploader_id: "",
};

//Copyright class
function CopyrightCase() {
  //
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  const [case_status, setCaseStatus] = useState("");
  const [case_id, setCaseId] = useState("");
  const [caseLogs, setCaseLogs] = useState([]);
  const [caseResult, setCaseResult] = useState(null);

  const [albumsResult, setAlbumsResult] = useState([]);
  const [tracksResult, setTracksResult] = useState([]);

  const [openedIndex, setOpenedIndex] = useState(-1);
  const [copyrighted_material, setCopyrightMaterial] = useState(cop_mat);

  const [c_form, setCForm] = useState(cform);
  const [c_legal, setCLegal] = useState(legal);

  const [form_success, setFormSuccess] = useState(false);
  const [code_success, setCodeSuccess] = useState(false);
  const [validation_code, setValidationCode] = useState("");

  //ViewDidLoad
  useEffect(() => {
    let isMounted = true; // note mutable flag
    //title
    document.title = "Copyright Case | SongDrop.uk";

    let url_paths = URLParser();
    var case_id = url_paths["case_id"];
    getCase(case_id).then((result) => {
      if (isMounted) setCase(result);
    });
    //
    return () => {
      isMounted = false;
    };
  }, []);

  //SEARCH
  const getCase = async (case_id) => {
    setApiWorking(true);
    if (isEmpty(case_id)) {
      setApiWorking(false);
      setApiError("Missing copyrigth case id.");
      return;
    }
    try {
      //✅Success: Copyright_issue
      let result = await getCopyrightCase(case_id);
      setApiWorking(false);
      return result;
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      //
      setFormSuccess(false);
      alert(error.message);
      setApiError(error.message);
    }
  };

  //SET_CASE
  function setCase(result) {
    setCaseResult(result.case);
    setCaseId(result.case.case_id);
    const _cop_mat = {
      album_id: result.album.album_id,
      track_id: result.album.track_id,
      uploader_id: "",
    };
    setCopyrightMaterial(_cop_mat);
    setCaseStatus(result.case.status);
    setCaseLogs(result.case_logs);
    setOpenedIndex(0);
    setAlbumsResult(!isEmpty(result.album) ? [result.album] : []);
    setTracksResult(!isEmpty(result.track) ? [result.track] : []);
  }

  //SEARCH
  const submitForm = async (e) => {
    e.preventDefault();
    setApiWorking(true);
    //
    try {
      //✅Success: Form sent
      var bodyParams = [];
      bodyParams.push(`c_album_id=${copyrighted_material.album_id}`);
      bodyParams.push(`c_track_id=${copyrighted_material.track_id}`);
      bodyParams.push(`c_uploader_id=${copyrighted_material.uploader_id}`);
      bodyParams.push(`claimant=${c_form.claimant}`);
      bodyParams.push(`name=${c_form.name}`);
      bodyParams.push(`phone=${c_form.phone}`);
      bodyParams.push(`email=${c_form.email}`);
      bodyParams.push(`description=${c_form.description}`);
      bodyParams.push(`country=${c_form.country}`);
      bodyParams.push(`address=${c_form.address}`);
      bodyParams.push(`city=${c_form.city}`);
      bodyParams.push(`county=${c_form.county}`);
      bodyParams.push(`postcode=${c_form.postcode}`);
      // let bParameters = bodyParams.join("&");
      // let result = await sendClaimForm(bParameters);
      // //
      // console.log(result);
      // //SAVE CASE_ID for validation
      // setCaseId(result["case_id"]);
      // //
      // alert(result["message"]);
      // setApiWorking(false);
      // setFormSuccess(true);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setFormSuccess(false);
      alert(error.message);
    }
  };

  //VALIDATE
  const validateForm = async (e) => {
    e.preventDefault();
    setApiWorking(true);
    //
    try {
      // //✅Success: User Validated account
      // var queryStringParams = [];
      // queryStringParams.push(`case_id=${case_id}`);
      // queryStringParams.push(`validation_code=${validation_code}`);
      // let qParameters = queryStringParams.join("&");
      // let result = await validateClaim(qParameters);
      // console.log(result);
      // setApiWorking(false);
      // setCodeSuccess(true);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setCodeSuccess(false);
      alert(error.message);
    }
  };

  //INPUT
  function onInputChange(key, value) {
    let form = copyObject(c_form);
    form[key] = value;
    setCForm({ ...form });
  }

  //CHECKBOX
  function onCheckboxChange(key, value) {
    let form = copyObject(c_legal);
    form[key] = value;
    setCLegal({ ...form });
  }

  //CODE
  function onCodeChange(validation_code) {
    setValidationCode(validation_code);
  }

  function setMaterial(_copyrighted_material) {
    // if (
    //   copyrighted_material.album_id === _copyrighted_material.album_id &&
    //   copyrighted_material.track_id === _copyrighted_material.track_id
    // ) {
    //   setCopyrightMaterial(cop_mat);
    // } else {
    //   setCopyrightMaterial(_copyrighted_material);
    // }
  }

  function setIndex(index) {
    if (index === openedIndex) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(index);
    }
  }

  //COUNTRY
  function selectedCountry(country) {
    onInputChange("country", country);
  }

  //VIEW
  return (
    <div className="copyright-body">
      <div className="header">
        <div className="title">Copyright Infridgement Case</div>
      </div>
      <div className="content">
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        {!isEmpty(apiError) && <div className="error-row">{apiError}</div>}
        {!isEmpty(caseResult) && (
          <div className="case-content">
            <p>
              <strong>CASE ID: </strong>
              {caseResult.case_id}
            </p>
            <p>
              <strong>Created at: </strong>
              {caseResult.created_at} ({convertDate(caseResult.created_at)})
            </p>
            {!isEmpty(caseResult.closed_at) && (
              <p>
                <strong>Closed at: </strong>
                {caseResult.closed_at}
              </p>
            )}
            {isEmpty(caseResult.closed_at) && (
              <p>
                <strong>Closed at: </strong>-
              </p>
            )}
            <p>
              <strong>Last update: </strong>
              {caseResult.updated_at}
            </p>
            <p>
              <strong>Status: </strong>
              {caseResult.status}
            </p>
            <div className="case_description">
              {caseResult.status_description}
            </div>
            <div className="logs">
              <table>
                <thead>
                  <tr>
                    <td>
                      <strong>Activity</strong>
                    </td>
                    <td>
                      <strong>Log</strong>
                    </td>
                    <td>
                      <strong>Date</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>{getLogs()}</tbody>
              </table>
            </div>
            <div className="support">
              <p>
                If you need any further assistance in your case, please feel
                free to send an email to our copyright team describing your case
                and how we can help you to resolve this issue as soon as
                possible.
              </p>
              <p>
                Please use the case number <strong>{caseResult.case_id}</strong>{" "}
                as your email's subject.
              </p>
              <p>
                Email:{" "}
                <a
                  href={`mailto:${about["copyright_email"]}?subject=${caseResult.case_id}&body=`}
                >
                  {about.copyright_email}
                </a>
              </p>
            </div>
          </div>
        )}
        <div className="section bcgGray">
          <div className="section-title">1. Copyrighted Material</div>
          <div>{copyrightJSON["0"]}</div>
          <br />
          <div>
            <div className="result_title">
              <strong>Albums({albumsResult.length}):</strong>
            </div>
            <div>{getAlbumsResults()}</div>
            <div className="result_title">
              <strong>Tracks({tracksResult.length}):</strong>
            </div>
            <div>{getTracksResults()}</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

  function getAlbumsResults() {
    var data = [];
    albumsResult.forEach((item, index) => {
      let isOpened = index === openedIndex;
      let isSelected = copyrighted_material.album_id === item.album_id;
      let copyright = {
        album_id: item.album_id,
        track_id: "",
        uploader_id: item.uploader_id,
      };
      //full_album_infridgement
      let _item = (
        <div key={index} className="result_row">
          <div onClick={() => setIndex(index)}>
            <i className={isSelected ? checkFaIcon : squareFaIcon} />
            <i className={!isOpened ? cdownFaIcon : cupFaIcon} />
            {item.title} - {item.artist}
          </div>
          {isOpened && (
            <div className="box_info">
              <div>
                <img alt="" src={item.cover_img_url} />
                <p>
                  Title: <strong>{item.title}</strong>
                </p>
                <p>
                  Artist: <strong>{item.artist}</strong>
                </p>
                <p>Genres: {item.genres}</p>
                <p>Released: {item.released_at}</p>
                <p>
                  Uploader: <strong>{item.uploader_username}</strong>
                </p>
              </div>
              <label className="input_label">
                <div className="checkbox-wrapper">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => setMaterial(copyright)}
                  />
                </div>
                {copyrightJSON["copyright_counter"]}
              </label>
            </div>
          )}
        </div>
      );
      data.push(_item);
    });
    return data;
  }

  function getTracksResults() {
    var data = [];
    tracksResult.forEach((item, index) => {
      let _index = albumsResult.length + index;
      let isOpened = _index === openedIndex;
      let isSelected =
        copyrighted_material.track_id === item.track_id ||
        copyrighted_material.album_id === item.album_id;
      let copyright = {
        album_id: item.album_id,
        track_id: item.track_id,
        uploader_id: item.uploader_id,
      };
      //song_infridgement
      let _item = (
        <div key={_index} className="result_row">
          <div onClick={() => setIndex(_index)}>
            <div>
              <div>
                <i className={isSelected ? checkFaIcon : squareFaIcon} />
                <i className={!isOpened ? cdownFaIcon : cupFaIcon} />
                {item.title} - {item.artist}
              </div>
              <div className="album_title">[{item.album_title}]</div>
            </div>
            <p></p>
          </div>
          {isOpened && (
            <div className="box_info">
              <div>
                <img alt="" src={item.cover_img_url} />
                <p>
                  Title: <strong>{item.title}</strong>
                </p>
                <p>
                  Artist: <strong>{item.artist}</strong>
                </p>
                <p>
                  Album: <strong>[{item.album_title}]</strong>
                </p>
                <p>Genres: {item.genres}</p>
                <p>Released: {item.released_at}</p>
                <p>
                  Uploader: <strong>{item.uploader_username}</strong>
                </p>
              </div>
              <div className="input_label">
                <div className="checkbox-wrapper">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => setMaterial(copyright)}
                  />
                </div>
                {copyrightJSON["copyright_counter"]}
              </div>
            </div>
          )}
        </div>
      );
      data.push(_item);
    });
    return data;
  }

  function getLogs() {
    var data = [];
    {
      caseLogs.forEach((item, index) => {
        let row = (
          <tr key={index}>
            <td>{item.log}</td>
            <td>{item.message}</td>
            <td>{item.created_at}</td>
          </tr>
        );
        data.push(row);
      });
    }
    return data;
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default CopyrightCase;
