import React from "react";
//json countries file
import countries from "../assets/json/countries.json";

//CountrySelect class
function CountrySelect(props) {
  //Select Country id, than sort the selected value and send as props if avialable
  function didSelectCountry(index) {
    const country = countries[index];
    props.selectedCountry(country);
  }
  //VIEW
  return (
    <select
      defaultValue="Home country"
      onChange={(e) => didSelectCountry(e.target.value)}
    >
      <option disabled>Home country</option>
      {countries.map((country, index) => (
        <option key={index} value={index}>
          {country.emoji}
          {country.name}
        </option>
      ))}
    </select>
  );
}

export default CountrySelect;
