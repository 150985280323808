import React, { useState, useRef } from "react";
import { CirclePicker, SketchPicker } from "react-color";
import { web_fonts } from "./WebsiteRandomContent";
import { generateRandomWebColor } from "./WebsiteRandomGenerator";
//CSS
import "./WebsitePopupSettings.css";

export function ShareWebsiteBox({
  id,
  websiteData,
  aspectRatio,
  backgroundColor,
  backgroundImage,
  font,
  fontColor,
  fontSize,
}) {
  // Split aspect ratio into width and height
  const [width, height] = aspectRatio.split(":").map(Number);
  const [inputText, setInputText] = useState(null);
  // Calculate the width and height of the box based on the aspect ratio
  const boxStyle = {
    width: "100%",
    paddingTop: `${(height / width) * 100}%`, // Maintain aspect ratio using padding trick
    position: "relative", // Required for absolute positioning of content
    backgroundColor: backgroundColor, // Add your desired background color
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
  };

  const contentStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: font, // Apply font family
    color: fontColor, // Apply font color
    fontSize: `${fontSize}px`, // Apply font size
  };

  const handleAlert = () => {
    const newText = prompt(websiteData.title);
    if (newText !== null) {
      setInputText(newText);
    } else {
      setInputText(null);
    }
  };

  return (
    <div id={id} className="share-website-box" style={boxStyle}>
      <div className="story-content" style={contentStyle}>
        {websiteData && (
          <div className="website-metadata">
            {websiteData.icon && (
              <img
                className="website-image"
                src={websiteData.icon}
                alt="Icon"
              />
            )}
            <div className="website-text" onClick={() => handleAlert()}>
              {inputText || websiteData.title}
              <span className="website-domain">{websiteData.domain}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
//
export function AspectRatioSettings({ handleChangeAspectRatio, aspectRatio }) {
  return (
    <div className="settings-container">
      <div className="aspect-ratio-buttons">
        <button
          className={aspectRatio === "1:1" ? "active" : ""}
          onClick={() => handleChangeAspectRatio("1:1")}
        >
          <i className="fa fa-instagram" />
          1:1
        </button>
        <button
          className={aspectRatio === "9:16" ? "active" : ""}
          onClick={() => handleChangeAspectRatio("9:16")}
        >
          <i className="fa fa-instagram" />
          9:16
        </button>
        <button
          className={aspectRatio === "4:5" ? "active" : ""}
          onClick={() => handleChangeAspectRatio("4:5")}
        >
          <i className="fa fa-instagram" />
          4:5
        </button>
      </div>
    </div>
  );
}

export function FontSettings({
  font,
  fontColor,
  fontSize,
  setFont,
  setFontColor,
  setFontSize,
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleFontChange = (event) => {
    const fontName = event.target.value;
    setFont(fontName); // Call the setFont function with the selected font
  };

  const handleFontColorChange = (color) => {
    setFontColor(color.hex);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleFontSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setFontSize(newSize);
  };

  const renderFontOptions = () => {
    // Sort the web_fonts array alphabetically
    const sortedFonts = web_fonts.sort((a, b) => a.localeCompare(b));

    // Map through the sorted fonts to generate the option elements
    return sortedFonts.map((font, index) => (
      <option key={index} value={font}>
        {font}
      </option>
    ));
  };

  const randomFontGenerator = () => {
    const randomFont = web_fonts[Math.floor(Math.random() * web_fonts.length)];
    const randomFontSize = Math.floor(Math.random() * (30 - 12 + 1)) + 12; // Random font size between 12 and 30
    setFontSize(randomFontSize);
    setFont(randomFont);
  };

  const color_picker_styles = {
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    color: {
      width: "36px",
      height: "14px",
      borderRadius: "2px",
      background: fontColor,
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <div className="settings-container">
      <div className="font-settings-row">
        <div className="font-settings-column">
          <div className="font-color-picker-container">
            <div className="font-color-picker">
              <i className="fa fa-adjust" />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={color_picker_styles.swatch} onClick={handleClick}>
                <div style={color_picker_styles.color} />
              </div>
              {displayColorPicker && (
                <div style={color_picker_styles.popover}>
                  <div
                    style={color_picker_styles.cover}
                    onClick={handleClose}
                  />
                  <SketchPicker
                    color={fontColor}
                    onChange={handleFontColorChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="font-select-container">
            <div className="font-select">
              <i className="fa fa-font" />
            </div>
            <select
              value={font}
              onChange={handleFontChange}
              style={{ font: font }}
            >
              {renderFontOptions()}
            </select>
          </div>
        </div>
        <div className="font-settings-row">
          <div className="font-settings-column">
            <div className="font-size-slider-container">
              <button
                className="random-font-btn"
                onClick={() => randomFontGenerator()}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <div className="font-size">
                <i className="fa fa-font" />
              </div>
              <input
                type="range"
                id="font-size-slider"
                min={12}
                max={30}
                value={fontSize}
                onChange={handleFontSizeChange}
              />
              <span>{fontSize}px</span>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function BackgroundSettings({
  backgroundColor,
  setBackgroundColor,
  setBackgroundImage,
}) {
  const [showColorPicker, setShowColorPicker] = useState(true);

  const handleTogglePicker = () => {
    setShowColorPicker((prevState) => !prevState);
  };

  const handleChangeBackgroundColor = (color) => {
    setBackgroundImage(null); // Clear background image when changing background color
    setBackgroundColor(color.hex);
  };

  const handleSelectImage = (image) => {
    setBackgroundColor(""); // Clear background color when selecting an image
    setBackgroundImage(image);
  };

  const randomBcgColorGenerate = () => {
    setBackgroundImage(null); // Clear background image when changing background color
    const color = generateRandomWebColor();
    setBackgroundColor(color);
  };

  return (
    <div className="settings-container">
      <div className="background-container">
        <button
          className="random-bg-btn"
          onClick={() => randomBcgColorGenerate()}
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </button>
        <button
          className={showColorPicker ? "active" : ""}
          onClick={handleTogglePicker}
        >
          Color
        </button>
        <button
          className={!showColorPicker ? "active" : ""}
          onClick={handleTogglePicker}
        >
          Image
        </button>
      </div>
      <div className="background-content">
        {showColorPicker ? (
          <CirclePicker
            color={backgroundColor}
            onChange={handleChangeBackgroundColor}
          />
        ) : (
          <BackgroundImagePicker onSelectImage={handleSelectImage} />
        )}
      </div>
    </div>
  );
}

function BackgroundImagePicker({ onSelectImage }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataURL = e.target.result;
        setSelectedImage(imageDataURL);
        onSelectImage(imageDataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={handleChange}
        style={{ display: "none" }}
        ref={fileInputRef} // Ref variable
      />
      <button
        onClick={() => fileInputRef.current.click()}
        className="photo-selector"
      >
        <i className="fa fa-picture-o" />
        Select Image
      </button>
      {/* {selectedImage && (
        <div>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      )} */}
    </div>
  );
}
