import React, { useEffect } from "react";
import DownloadRadio from "../dashboard/DownloadRadio";
//JS
import ImageSlider from "../dashboard/ImageSlider";
import Socials from "../../components/socials/socials";
import Footer from "../../components/footer/Footer";
//CSS
import "./Radio.css";
import Zoom from "react-medium-image-zoom";

//assets
const picList = [
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/radio1.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/radio2.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/radio3.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/radio4.png",
];

//Radio className
function Radio() {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Radio! | SongDrop";
    //
  }, []);

  //VIEW
  return (
    <div className="radiobody">
      <div className="radioblur" />
      {/* <Background /> */}
      <div className="radiocontent">
        <div style={{ marginTop: "12px" }}>
          <div className="air_title">⌁ON AIR⌁</div>
          <div className="air_name">Live Audio Sharing - from your Phone.</div>
          <div className="songdrop-intro">
            <div>
              Whether you're a performer, a podcaster, or a DJ,{" "}
              <strong>
                <i className="fa fa-microphone" /> Radio!
              </strong>{" "}
              makes it easy to broadcast live content.
            </div>
          </div>
        </div>
        <div className="hello_radio">
          Setup a <strong>broadcasting server</strong> in 5 minutes and share
          your live voice as a <strong>simple .mp3 link</strong> or a{" "}
          <strong>QR code.</strong>
        </div>
        <div className="img-holder">
          <ImageSlider images={picList} />
        </div>
        <DownloadRadio />
        <div className="readmore">
          <div className="title">
            How to setup <strong>Radio!</strong> on Digital Ocean?
          </div>
          <div className="youtube-wrapper">
            <div>
              <strong>1️⃣ Setup a server</strong>
            </div>
            <div>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/j_kXyfqeZ_E"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              {/* <YouTube videoId={youtubeOpts.url} opts={youtubeOpts} /> */}
            </div>
            <div style={{ padding: "20px" }}>
              <div>
                <strong>2️⃣ Download the app</strong>
              </div>
              <br />
              <div>
                <div>How to connect to your radio station with Radio! app?</div>
                <strong>3️⃣ Connect the server to the app</strong>
              </div>
              <div>
                After opening the app, press the <i className="fa fa-cog" />{" "}
                icon to setup your server details.
              </div>
              <div>
                <strong>Name:</strong> {"<your radio name>"}
              </div>
              <div>
                <strong>Host:</strong> {"<eg.: 210.97.131.07>"}
              </div>
              <div>
                <strong>Port:</strong> {"<eg.: 8000>"}
              </div>
              <div>
                <strong>Mount:</strong> {"<eg.: /radio.mp3>"}
              </div>
              <div>
                <strong>Username:</strong> {"source"}
              </div>
              <div>
                <strong>Password:</strong> {"<your_server_password>"}
              </div>
              <div>
                Once you made sure you entered the correct server details, click
                on the <strong>Save</strong> button on the right navigation
                icon.
              </div>
              <div>
                <br />
                <div>
                  <strong>4️⃣ Start boardcast with the app</strong>
                </div>
                To start broadcast press the <i className="fa fa-microphone" />{" "}
                <strong>Stream</strong> button.
              </div>
            </div>
            <br />
            <div className="registerhost">
              <span>
                To use Radio! you need to setup a server. Register an account{" "}
              </span>
              <a href="https://www.digitalocean.com/?refcode=582fcc29135e&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge">
                <img
                  src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%203.svg"
                  alt="DigitalOcean Referral Badge"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="dd_title">How it works?</div>
        <div className="diagram_holder">
          <div className="diagram">
            <Zoom>
              <img
                style={{ width: "80%" }}
                src="https://cdn.songdrop.cloud/docs/radio_how_it_works_mic_speaking.png"
                alt=""
              />
            </Zoom>
            <div className="d_title">Share your live voice</div>
          </div>
          <div className="diagram">
            <Zoom>
              <img
                style={{ width: "80%" }}
                src="https://cdn.songdrop.cloud/docs/radio_how_it_works_irig.png"
                alt=""
              />
            </Zoom>
            <div className="d_title">Connect audio input with iRig</div>
            <div class="flex-container">
              <div class="item" title="iRig 2">
                <a
                  href="https://www.ikmultimedia.com/products/irig2/?pkey=irig-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://www.ikmultimedia.com/dev/images/stories/irig-2-prodmenu@2x.jpg"
                    alt="iRig 2"
                    width="40"
                    height="40"
                  />
                </a>
              </div>

              <div class="item" title="iRig HD 2">
                <a
                  href="https://www.ikmultimedia.com/products/irighd2/?pkey=irig-hd-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://www.ikmultimedia.com/dev/images/stories/irighd2-prodmenu@2x.jpg"
                    alt="iRig HD 2"
                    width="40"
                    height="40"
                  />
                </a>
              </div>

              <div class="item" title="iRig Stream Solo">
                <a
                  href="https://www.ikmultimedia.com/products/irigstreamsolo/?pkey=irigstreamsolo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://www.ikmultimedia.com/dev/images/stories/irigstream-solo-prodmenu_no_badge@2x.jpg"
                    alt="iRig Stream Solo"
                    width="40"
                    height="40"
                  />
                </a>
              </div>
              <div class="item" title="iRig Stream Pro">
                <a
                  href="https://www.ikmultimedia.com/products/irigstreampro/?pkey=irigstreampro"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://www.ikmultimedia.com/dev/images/stories/irigstream-pro-prodmenu_no_badge@2x.jpg"
                    alt="iRig Stream Pro"
                    width="40"
                    height="40"
                  />
                </a>
              </div>
            </div>
            <div class="item" title="IK Multimedia">
              <a
                href="https://www.ikmultimedia.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.dj-technik.de%2Fbilder%2Fhersteller%2Fnormal%2Fik-multimedia.png&f=1&nofb=1&ipt=c04403df3c210b10a5f4e65b037fcf3c9e063046608d6efa3fe83d7359496b27&ipo=images"
                  alt="IK Multimedia"
                  width="120"
                  height="32"
                />
              </a>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "white" }}>
          <Socials />
        </div>
        <div className="discord">
          <div className="left">
            <div className="title">NEED MORE HELP?</div>
            <div className="subtitle">
              Join our Discord server and get help with setting up your radio
              server!
            </div>
          </div>
          <div className="right">
            <iframe
              style={{ padding: "0px 12px 0px 12px" }}
              src="https://discord.com/widget?id=1060578263560364102&theme=dark"
              width="280px"
              height="250px"
              allowtransparency="true"
              frameBorder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              title="SONGDROP DISCORD SERVER"
            ></iframe>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Radio;
