import React, { useEffect, useState } from "react";
//CSS
import "./Download.css";
import isEmpty from "../../components/isEmpty";

//IMAGE
import linkExp from "../../assets/img/link_expired.png";
//
const BucketURL = process.env.REACT_APP_DO_HTML_BUCKET;

//Download
function Download() {
  //ui
  const [apiText, setApiText] = useState("  Loading content to download...");
  const [isApiLoading, setApiLoading] = useState(true);
  const [apiError, setApiError] = useState("");

  const [htmlContent, setHtmlContent] = useState("");
  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Downloads | SongDrop";
    //
    getDownloadUrl();
  }, []);

  //GET.API fetching list from S3
  function getDownloadUrl() {
    //Api UI set
    setApiLoading(true);
    setApiText("Loading...");
    setApiError("");
    //https://songdrop.uk/download/3742923 -> 3742923
    const folder = BucketURL;
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const lastPart = parts[parts.length - 1];
    const url = `https://cdn.songdrop.cloud/${folder}/${lastPart}`;

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        setHtmlContent(html);
        setApiLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        // if (err.message.toLowerCase().includes("access denied")) {
        //   setApiError(
        //     "Sorry, but this link has expired. If you want to download your music again, please request a new export link in the SongDrop App."
        //   );
        // } else {
        //   setApiError(err.message);
        // }
        setApiLoading(false);
        setApiError(
          "Sorry, but this link has likely expired. Please request a new export link in the SongDrop App."
        );
      });
  }

  //VIEW
  return (
    <div className="download-body">
      {isApiLoading && (
        <div className="ApiLoading">
          <div className="spinner-block">
            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>{" "}
            {apiText}
          </div>
        </div>
      )}
      {!isApiLoading && !isEmpty(apiError) && (
        <div className="apierror">
          <div className="error-block">
            <br />
            <h2>Your dowload link expired.</h2>
            <br />
            <img className="" src={linkExp} alt="" />
          </div>
          <div className="error-block">{apiError}</div>
        </div>
      )}
      <iframe
        title="Embedded HTML"
        srcDoc={htmlContent}
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    </div>
  );
}

export default Download;
