import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GithubPicker, TwitterPicker } from "react-color";
//CSS
import "./ReactCopyCode.css";
//ReactCopyCode class
function ReactCopyCode({ parameters, closeCopy }) {
  //
  const [iframeCode, setIFrameCode] = useState("");
  //copy_paste
  const [code_copied, setCodeCopied] = useState(false);
  const [link_copied, setLinkCopied] = useState(false);
  const [color_picker_displayed, displayColorPicker] = useState(false);

  //
  const [themeColor, setThemeColor] = useState("black");
  const [pickerColor, setPickerColor] = useState("black");

  //ViewDidLoad
  useEffect(() => {
    let _iframe = `<iframe
  src="https://widget.songdrop.uk/?${parameters}"
  width="100%"
  height="250px"
  title="SONGDROP WIDGET"
></iframe>`;
    setIFrameCode(_iframe);

    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        displayColorPicker(false);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const changeTheme = (theme) => {
    let old_url = `https://widget.songdrop.uk/?${parameters}`;
    old_url = old_url.replace(/&?theme=[^&]*/, "");
    let new_url = `${old_url}&theme=${theme.replace("#", "")}`;
    let src = new_url;
    const _iframeCode = `<iframe
  src="${src}"
  width="100%"
  height="250px"
  title="SONGDROP WIDGET"
></iframe>`;
    setIFrameCode(_iframeCode);
    setThemeColor(theme);
  };

  const randomTheme = () => {
    changeTheme("random");
    displayColorPicker(false);
  };

  const switchThemeMode = () => {
    let url = iframeCode.match(/src="([^"]*)"/)?.[1] || null;
    let theme = url.includes("theme=dark") ? "light" : "dark";
    setThemeColor(theme === "dark" ? "black" : "white");
    changeTheme(theme);
    displayColorPicker(false);
  };

  const handleCodeCopy = () => {
    setCodeCopied(true);
    setLinkCopied(false);
    setTimeout(() => setCodeCopied(false), 2000);
    displayColorPicker(false);
  };

  const handleLinkCopy = () => {
    setLinkCopied(true);
    setCodeCopied(false);
    setTimeout(() => setLinkCopied(false), 2000);
    displayColorPicker(false);
  };

  function openColorPicker(picker_opened) {
    if (picker_opened) {
      displayColorPicker(picker_opened);
    } else {
      closeColorPicker();
    }
  }
  function closeColorPicker() {
    displayColorPicker(false);
    changeTheme(pickerColor);
  }

  function handleColorPickerChange(color) {
    setThemeColor(color.hex);
    setPickerColor(color.hex);
  }

  function generateRandomPickerColor() {
    let random_theme_color = generateRandomColor();
    setThemeColor(random_theme_color);
    setPickerColor(random_theme_color);
    changeTheme(random_theme_color);
  }

  const titleStyles = {
    position: "relative",
    padding: "2px 12px 2px 12px",
    margin: "12px 12px 0px 12px",
    backgroundColor: "darkgray",
    color: "black",
  };

  const closeStyles = {
    position: "absolute",
    top: "2px",
    right: "6px",
    cursor: "pointer",
    outline: "none",
    focus: "none",
    borderRadius: "2px",
    backgroundColor: "transparent",
    border: "none",
    padding: "0px 4px 0px 4px",
  };

  const modeStyles = {
    position: "absolute",
    top: "5px",
    left: "5px",
    cursor: "pointer",
    outline: "none",
    focus: "none",
    borderRadius: "2px",
    backgroundColor: "#e9e9ed",
    border: "none",
    padding: "2px 4px 2px 4px",
  };

  const colorStyles = {
    position: "absolute",
    top: "5px",
    left: "35px",
    cursor: "pointer",
    outline: "none",
    focus: "none",
    borderRadius: "2px",
    backgroundColor: "#e9e9ed",
    border: "none",
    padding: "2px 4px 2px 4px",
    zIndex: 1,
  };

  const randomStyles = {
    position: "absolute",
    top: "5px",
    left: "65px",
    cursor: "pointer",
    outline: "none",
    focus: "none",
    borderRadius: "2px",
    backgroundColor: "#e9e9ed",
    border: "none",
    padding: "2px 4px 2px 4px",
    zIndex: 1,
  };

  const linkStyles = {
    position: "absolute",
    top: "5px",
    right: "35px",
    cursor: "pointer",
    outline: "none",
    focus: "none",
    borderRadius: "2px",
    backgroundColor: "#e9e9ed",
    border: "none",
    padding: "2px 4px 2px 4px",
    zIndex: 1,
  };
  const copyStyles = {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
    outline: "none",
    focus: "none",
    borderRadius: "2px",
    backgroundColor: "#e9e9ed",
    border: "none",
    padding: "2px 4px 2px 4px",
    zIndex: 1,
  };

  const color_picker_styles = {
    position: "absolute",
    top: "40px",
    left: "20px",
    cursor: "pointer",
    outline: "none",
    focus: "none",
    borderRadius: "2px",
    backgroundColor: "#e9e9ed",
    border: "none",
    padding: "2px 4px 2px 4px",
    zIndex: 1,
  };

  const random_color_picker_styles = {
    position: "absolute",
    width: "30px",
    height: "30px",
    bottom: "18px",
    right: "19px",
    cursor: "pointer",
    outline: "none",
    focus: "none",
    borderRadius: "4px",
    backgroundColor: "#e9e9ed",
    border: "none",
    padding: "2px 4px 2px 4px",
    zIndex: 1,
  };

  const _closeStyles = { ...closeStyles, "--hover-color": "lightgray" };
  const _modeStyles = { ...modeStyles, "--hover-color": "lightgray" };
  const _colorStyles = {
    ...colorStyles,
    "--hover-color": "lightgray",
    color: themeColor,
  };
  const _randomStyles = { ...randomStyles, "--hover-color": "lightgray" };
  const _randomPickerStyles = {
    ...random_color_picker_styles,
    "--hover-color": "lightgray",
    color: themeColor,
  };
  const _copyStyles = { ...copyStyles, "--hover-color": "lightgray" };
  const _linkStyles = { ...linkStyles, "--hover-color": "lightgray" };

  //VIEW
  return (
    <div className="r-copy-code-div">
      {/* {console.log(iframeCode)} */}
      <div style={titleStyles}>
        Share SongDrop Widget in your website{" "}
        <button style={_closeStyles} onClick={closeCopy}>
          <i className="fa fa-times-circle" aria-hidden="true"></i>
        </button>
      </div>
      <pre style={{ position: "relative", margin: "0px 12px 0px 12px" }}>
        <code onClick={() => displayColorPicker(false)}>
          <br />
          {iframeCode}
        </code>
        <button
          title="Dark/Light Mode"
          style={_modeStyles}
          onClick={() => switchThemeMode()}
        >
          <i className="fa fa-adjust" aria-hidden="true"></i>
        </button>

        <div>
          <button
            title="Pick Color"
            style={_colorStyles}
            onClick={() => openColorPicker(!color_picker_displayed)}
          >
            <i className="fa fa-square" aria-hidden="true"></i>
          </button>
        </div>
        <button
          title="Random Theme"
          style={_randomStyles}
          onClick={() => randomTheme()}
        >
          <i className="fa fa-random" aria-hidden="true"></i>
        </button>
        <CopyToClipboard
          text={iframeCode.match(/src="([^"]*)"/)?.[1] || ""}
          onCopy={handleLinkCopy}
        >
          <button style={_linkStyles} title="Copy Link">
            {link_copied ? (
              "Link Copied!"
            ) : (
              <i className="fa fa-link" aria-hidden="true"></i>
            )}
          </button>
        </CopyToClipboard>
        <CopyToClipboard text={iframeCode} onCopy={handleCodeCopy}>
          <button style={_copyStyles} title="Copy Code">
            {code_copied ? (
              "Code Copied!"
            ) : (
              <i className="fa fa-clone" aria-hidden="true"></i>
            )}
          </button>
        </CopyToClipboard>
        {color_picker_displayed && (
          <div style={color_picker_styles}>
            <TwitterPicker
              color={themeColor}
              onChange={handleColorPickerChange}
            />
            <button
              title="Random Color"
              style={_randomPickerStyles}
              onClick={() => generateRandomPickerColor()}
            >
              <i className="fa fa-square" aria-hidden="true"></i>
            </button>
          </div>
        )}
      </pre>
      <div
        style={{ position: "relative", margin: "0px 12px 12px 12px" }}
        dangerouslySetInnerHTML={{ __html: iframeCode }}
      />
    </div>
  );

  //generateRandomColor
  function generateRandomColor() {
    // Generate random values for red, green, and blue components
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    // Construct the color string in hexadecimal format
    const color =
      "#" +
      red.toString(16).padStart(2, "0") +
      green.toString(16).padStart(2, "0") +
      blue.toString(16).padStart(2, "0");
    return color;
  }
}

export default ReactCopyCode;
