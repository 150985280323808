import React, { useEffect, useState } from "react";
import passwordValidator from "password-validator";
import isEmpty from "../../components/isEmpty";
import { resetPass } from "../../utils/apis/auth";
//JS
import Footer from "../../components/footer/Footer";
//css
import "./ResetPass.css";

//assets
import logoIcon from "../../assets/img/songdrop-logo-white.png";
import resetIcon from "../../assets/img/resetpass.png";
import about from "../../assets/about/about.json";
//fa icons
const successFaIcon = "fa fa-check-circle";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const errorFaIcon = "fa fa-exclamation-circle";
var schema = new passwordValidator();

//ResetPass class
function ResetPass() {
  //icons
  const [apiIcon, setApiIcon] = useState("");
  const [apiText, setApiText] = useState("");
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //get from urlpath
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  //userinput
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //password visibility
  const [showPassword, setShowPassword] = useState(false);

  //password checklist
  const [checklist, setChecklist] = useState({
    minLength: false,
    maxLength: false,
    uppercase: false,
    lowercase: false,
    digits: false,
    symbols: false,
  });

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Reset password | SongDrop.band";
    //password schema setup
    setupPasswordSchema();
    //
    retrieveDataFromUrl();
  }, []);

  //Get data from urlpath like email+code
  function retrieveDataFromUrl() {
    try {
      const params = new URLSearchParams(window.location.search);

      // Initial split-based parsing
      const hrefParts = window.location.href.split("/")[3].split("?");
      const _emailPart =
        hrefParts.length > 1 ? hrefParts[1].split("=")[1] : null;
      const _codePart =
        hrefParts.length > 2 ? hrefParts[2].split("=")[1] : null;

      // Fallback to URLSearchParams if initial parsing fails
      const _email = _emailPart || params.get("email");
      const _code = _codePart || params.get("code");

      if (!_email || !_code) {
        document.getElementById("resetpassid").innerHTML =
          "<div style='display: flex; justify-content: center; align-items: center; color: red; height: 100px;'>Email or code cannot be found</div>";
        throw new Error("Invalid URL parameters: email or code is missing");
      }

      setEmail(_email);
      setCode(_code);
    } catch (error) {
      setApiError(error.message);
      setApiText(error.message);
      setApiIcon(errorFaIcon);
      setApiWorking(false);
    }
  }

  //Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiWorking(true);
    setApiError("");
    setApiIcon(null);
    setApiText("Connecting...");

    //Validate password
    if (!schema.validate(password)) {
      setApiError("Password isn't in the correct format");
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText("");
      return;
    }
    //Confirm matching passwords
    if (password !== confirmPassword) {
      const error = "Passwords are not matching";
      setApiError(error);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText("");
      return;
    }
    //api
    try {
      //✅Success: User Password resetted
      await resetPass(email, code, password);
      //redirectAfterSuccess
      redirectAfterSuccess();
    } catch (error) {
      //❌Error: Validation falied
      setApiError(error.message);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
    }
  };

  //API.SUCCESS
  function redirectAfterSuccess() {
    setTimeout(function () {
      setApiIcon(successFaIcon);
      setApiText("Your password has been changed");
    }, 2000);
    setTimeout(function () {
      setApiIcon(apiFaIcon);
      setApiText("Redirecting...");
    }, 3500);
    setTimeout(function () {
      window.location.href = `/`;
      setApiIcon(successFaIcon);
      setApiText("Your password has been changed");
    }, 4000);
  }

  //UPDATE: password value as state
  function handlePasswordChanged(password) {
    setPassword(password);
    validateChecklist(password);
  }

  //UPDATE: confirm password value as state
  function handleConfirmPasswordChanged(password) {
    setConfirmPassword(password);
  }

  //Toggle password visibility
  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  //VIEW
  return (
    <div className="reset-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoClicked()}
            />
          </div>
        </div>
        <div id="resetpassid">
          <div className="header">
            <div className="welcome">
              <p>Reset your password:</p>
            </div>
          </div>
          <div className="content">
            <div className="icon-container">
              <img className="logo" alt="" src={resetIcon} />
            </div>
            {/* API ERROR */}
            {!isEmpty(apiError) && (
              <div className="info-block info-error">
                <i className={errorFaIcon} /> {apiError}
              </div>
            )}
            {/* ENTER PASSWORD */}
            {isEmpty(apiError) && (
              <div className="info-block">
                {!isEmpty(apiText) && (
                  <p>
                    <i className={apiIcon} /> {apiText}
                  </p>
                )}
                {isEmpty(apiText) && (
                  <div>
                    <div>Enter a new password for your account</div>
                    <p style={{ whiteSpace: "pre-line", fontSize: "12px" }}>
                      {`Password should have:\n`}
                      {`${checklist.minLength ? "☑︎" : "□"} min 8 characters\n`}
                      {`${
                        checklist.maxLength ? "☑︎" : "□"
                      } max 16 characters\n`}
                      {`${checklist.digits ? "☑︎" : "□"} 1 digit\n`}
                      {`${checklist.lowercase ? "☑︎" : "□"} 1 lowercase\n`}
                      {`${checklist.uppercase ? "☑︎" : "□"} 1 uppercase\n`}
                      {`${checklist.symbols ? "☑︎" : "□"} 1 special char`}
                    </p>
                  </div>
                )}
              </div>
            )}
            {/*  API WORKING  */}
            {isApiWorking === true && (
              <button
                className="btn btn_sign_in btn-primary"
                style={{
                  backgroundColor: "transparent",
                  color: "darkgray",
                }}
                disabled={true}
              >
                <i className={apiFaIcon} aria-hidden="true"></i>
              </button>
            )}
            {/*  PASSWORD FORM */}
            {isApiWorking !== true && (
              <form className="input-container" onSubmit={handleSubmit}>
                <div className="input-field">
                  <p>*New Password</p>
                  <div className="password-container">
                    <input
                      value={password}
                      placeholder="•••••••"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => handlePasswordChanged(e.target.value)}
                    />
                    <i
                      className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                      onClick={toggleShowPassword}
                    ></i>
                  </div>
                </div>
                <div className="input-field">
                  <p>*Confirm New Password</p>
                  <div className="password-container">
                    <input
                      value={confirmPassword}
                      placeholder="•••••••"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) =>
                        handleConfirmPasswordChanged(e.target.value)
                      }
                    />
                    <i
                      className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                      onClick={toggleShowPassword}
                    ></i>
                  </div>
                </div>
                <div className="confirm-btn">
                  <button
                    type="submit"
                    className="reset-btn"
                    disabled={!isSubmitEnabled()}
                  >
                    Change Password
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/";
  }

  function setupPasswordSchema() {
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(16) // Maximum length 16
      .has()
      .uppercase() // Must have at least one uppercase letter
      .has()
      .lowercase() // Must have at least one lowercase letter
      .has()
      .digits() // Must have at least one digit (0-9)
      .has()
      .symbols() // Must have at least one special character
      .has()
      .not()
      .spaces() // Should not contain spaces
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]); // Blacklist specific values
  }

  //Validate password checklist
  function validateChecklist(password) {
    const failedRules = schema.validate(password, { list: true });

    setChecklist({
      minLength: !failedRules.includes("min"), // Check if 'min' rule failed
      maxLength: !failedRules.includes("max"), // Check if 'max' rule failed
      uppercase: !failedRules.includes("uppercase"), // Check if 'uppercase' rule failed
      lowercase: !failedRules.includes("lowercase"), // Check if 'lowercase' rule failed
      digits: !failedRules.includes("digits"), // Check if 'digits' rule failed
      symbols: !failedRules.includes("symbols"), // Check if 'symbols' rule failed
    });
  }

  //Is Submission Enabled
  function isSubmitEnabled() {
    if (isApiWorking === true) {
      return false;
    } else {
      return password.length > 0 && confirmPassword.length > 0;
    }
  }
}

export default ResetPass;
