const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

export function getCognitoUser(email) {
  const userData = {
    Username: email,
    Pool: getUserPool(),
  };
  return new AmazonCognitoIdentity.CognitoUser(userData);
}

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

function getUserPool() {
  return new AmazonCognitoIdentity.CognitoUserPool(poolData);
}
