import React, { useEffect, useState } from "react";
import Chip from "../BlogContent/Chip";
import EmptyList from "../BlogContent/BlogEmptyList";
import "./BlogContent.css";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactLinkify from "react-linkify";
import { getBlogArticleByIdAPI } from "../../../utils/apis/public";
//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//BlogContent
const BlogContent = () => {
  //
  const [isApiWorking, setApiWorking] = useState(true);
  const [blog, setBlog] = useState(null);
  const [linkCopied, setLinkToCopy] = useState(false);

  useEffect(() => {
    //
    //get path, remove extra stuff it has
    var blog_id = window.location.href.split("/")[4];
    getBlogById(blog_id);
    //
  }, []);

  //API
  async function getBlogById(blog_id) {
    setApiWorking(true);

    try {
      //✅SUCCESS
      var bodyParams = [];
      bodyParams.push(`blog_id=${blog_id}`);
      let bParameters = bodyParams.join("&");
      let result = await getBlogArticleByIdAPI(bParameters);
      let blog = result["blog"];
      setBlog(blog);
      // console.log("RESULT", result);
      //
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
    }
  }

  return (
    <div className="blog-content">
      <Link className="blog-goBack" to="/blog">
        <i className="fa fa-arrow-left" />
        <span> Go Back</span>
      </Link>
      {/* API_LOADING */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {isApiWorking && (
        <div style={{ textAlign: "center", marginBottom: "60px" }}>
          Loading...
          <br />
        </div>
      )}
      {!isApiWorking && (
        <div>
          {blog ? (
            <div className="blog-wrap">
              <div className="sharing">
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => setLinkToCopy(true)}
                >
                  <div className="share-button">
                    <i className="fa fa-share-square-o"></i>
                  </div>
                </CopyToClipboard>
                {linkCopied && (
                  <div style={{ display: "inline-block" }}>Link copied. </div>
                )}
                <Mailto
                  email=""
                  subject={blog.title}
                  body={window.location.href}
                >
                  <i className="fa fa-envelope" />
                </Mailto>
                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    window.location.href
                  )}&text=${encodeURIComponent(
                    blog.title
                  )}&amp;media=${encodeURIComponent(blog.cover)}`}
                  target="_blank"
                  onclick="return Share.me(this)"
                >
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href={`http://www.facebook.com/sharer/sharer.php?s=100&
p%5Btitle%5D=${blog.title}&p%5Bsummary%5D=DESC&p%5Burl%5D=
${window.location.href}&p%5Bimages%5D%5B0%5D=${blog.cover}`}
                  target="_blank"
                  onclick="return Share.me(this);"
                >
                  <i className="fa fa-facebook" />
                </a>
              </div>
              <header>
                <p className="blog-date">
                  Published {blog.created_at} - {blog.duration} to read{" "}
                  <i className="fa fa-eye" />
                  {blog.views} views
                </p>
                <h1>{blog.title}</h1>
                <div className="blog-subCategory">
                  {blog.subcategories.map((category, index) => (
                    <div key={index}>
                      <Chip label={category} />
                    </div>
                  ))}
                </div>
              </header>
              <img src={blog.cover} alt="" />
              <ReactLinkify>
                <div className="blog-desc">{blog.description}</div>
              </ReactLinkify>
              <div className="blog_content">
                <React.Fragment>
                  <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                </React.Fragment>
              </div>
              <div className="blog_author">
                Author: <strong>{blog.author_name}</strong>
              </div>
            </div>
          ) : (
            <EmptyList />
          )}
        </div>
      )}
    </div>
  );
};

export default BlogContent;

//
const Mailto = ({ email, subject, body, children }) => {
  return (
    <a
      href={`mailto:${email}?subject=${
        encodeURIComponent(subject) || ""
      }&body=${encodeURIComponent(body) || ""}`}
    >
      {children}
    </a>
  );
};
