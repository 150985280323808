import React from "react";

//CSS
import "./SearchInput.css";

//fa icons
const faSearchIcon = "fa fa-search";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

// SearchInput class
function SearchInput(props) {
  //UPDATE: SearchBar text
  function handleSearchChange(searchtext) {
    props.textDidChange(searchtext);
  }

  //SEARCH
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    props.searchForText();
  };

  return (
    <div className="_searchinput">
      <div className="search-container">
        <form className="searchbar" onSubmit={handleSearchSubmit}>
          <input
            placeholder="Search"
            type="text"
            value={props.searchText}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          {props.isApiWorking === false && (
            <button type="submit" disabled={props.searchText.length === 0}>
              <i className={faSearchIcon}></i>
            </button>
          )}
          {props.isApiWorking === true && (
            <button type="submit" disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default SearchInput;
