import DownloadRadio from "../dashboard/DownloadRadio";
//JS
import ImageSlider from "../dashboard/ImageSlider";

//CSS
import "./RadioSetupGuide.css";

//assets
const picList = [
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/radio1.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/radio2.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/radio3.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/radio4.png",
];

export function WelcomeToRadio() {
  return (
    <div className="step step-welcome">
      <div className="radiowelcome">
        <div style={{ marginTop: "12px" }}>
          <div className="air_title">⌁ON AIR⌁</div>
          <div className="air_name">Live Audio Sharing - from your Phone.</div>
          <div className="songdrop-intro">
            <div>
              Whether you're a performer, a podcaster, or a DJ,{" "}
              <strong>
                <i className="fa fa-microphone" /> Radio!
              </strong>{" "}
              makes it easy to broadcast live content.
            </div>
          </div>
        </div>
        <div className="hello_radio">
          Setup a <strong>broadcasting server</strong> in 5 minutes and share
          your live voice as a <strong>simple .mp3 link</strong> or a{" "}
          <strong>QR code.</strong>
        </div>
      </div>
    </div>
  );
}

// Step 1: Download the Radio! app from App Store
export function Step1() {
  return (
    <div className="step step1">
      <h2>Step 1</h2>
      <p>Download the Radio! app from App Store</p>
      <ImageSlider images={picList} />
      <DownloadRadio />
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}

// Step 2: Setup your server (create Digital Ocean account)
export function Step2() {
  return (
    <div className="step step2">
      <h2>Step 2</h2>
      <p>Setup your server (create Digital Ocean account)</p>
      <div className="youtube-wrapper">
        <div className="setup_msg">
          How to setup <strong>Radio!</strong> on Digital Ocean?
        </div>
        <div>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/j_kXyfqeZ_E"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

// Step 3: Connect Radio! app to your server (get MP3 link, you will need this)
export function Step3() {
  return (
    <div className="step step3">
      <h2>Step 3</h2>
      <p>
        Connect Radio! app to your server (get MP3 link, you will need this)
      </p>
      <div>
        <br />
        <div>
          <strong>How to connect to your radio station with Radio! app?</strong>
        </div>
        <div>
          After opening the app, press the <i className="fa fa-cog" /> icon to
          setup your server details.
        </div>
        <div>
          <strong>Name:</strong> {"<your radio name>"}
        </div>
        <div>
          <strong>Host:</strong> {"<eg.: 210.97.131.07>"}
        </div>
        <div>
          <strong>Port:</strong> {"<eg.: 8000>"}
        </div>
        <div>
          <strong>Mount:</strong> {"<eg.: /radio.mp3>"}
        </div>
        <div>
          <strong>Username:</strong> {"source"}
        </div>
        <div>
          <strong>Password:</strong> {"<your_server_password>"}
        </div>
        <div>
          Once you made sure you entered the correct server details, click on
          the <strong>Save</strong> button on the right navigation icon.
        </div>
        <div>
          <br />
          To start broadcast press the <i className="fa fa-microphone" />{" "}
          <strong>Stream</strong> button.
        </div>
      </div>
    </div>
  );
}

// Step 4: Create a profile on SongDrop.band
export function Step4() {
  return (
    <div className="step step4">
      <h2>Step 4</h2>
      <p>Create a profile on SongDrop.band</p>
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}

// Step 5: Create a broadcast
export function Step5() {
  return (
    <div className="step step5">
      <h2>Step 5</h2>
      <p>Create a broadcast</p>
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}

// Step 6: Add PayPal (create PayPal account if you need)
export function Step6() {
  return (
    <div className="step step6">
      <h2>Step 6</h2>
      <p>Add PayPal (create PayPal account if you need)</p>
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}

// Step 7: Set your price for your show
export function Step7() {
  return (
    <div className="step step7">
      <h2>Step 7</h2>
      <p>Set your price for your show</p>
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}

// Step 8: Go live, start charging your listeners
export function Step8() {
  return (
    <div className="step step8">
      <h2>Step 8</h2>
      <p>
        Go live, start charging your listeners on https://songdrop.live/
        {"your_broadcast_id"}
      </p>
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}

// Step 9: Print out QR code and place in front of your audience
export function Step9() {
  return (
    <div className="step step9">
      <h2>Step 9</h2>
      <p>
        Print out QR code and place in front of your audience, so they can share
        it with their friends
      </p>
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}

// Step 10: Export your saved MP3 from your show (in the app)
export function Step10() {
  return (
    <div className="step step10">
      <h2>Step 10</h2>
      <p>Export your saved MP3 from your show (in the app)</p>
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}

// Step 11: Release your show’s audio on SongDrop to sell
export function Step11() {
  return (
    <div className="step step11">
      <h2>Step 11</h2>
      <p>Release your show’s audio on SongDrop to sell</p>
      {/* Add any additional content, images, or buttons here */}
    </div>
  );
}
