// MultiSelect.js
import React, { useState } from "react";

import categorised_genres from "../../../assets/json/categorised_genres.json";
//CSS
import "./MusicGenres.css";

function MultiGenres(props) {
  //LEFT SIDE
  //ALL GENRES BUT SHOW AS FILTERED BECAUSE IT HAS TO BE ABLE TO SEARCH
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredGenres, setFilteredGenres] = useState(categorised_genres);
  const [focusedGenreOnLeftSide, setFocusedGenreOnLeftSide] = useState("");

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = Object.keys(categorised_genres).reduce((acc, category) => {
      const filteredCategory = categorised_genres[category].filter((genre) =>
        genre.toLowerCase().includes(searchTerm)
      );

      if (filteredCategory.length > 0) {
        acc[category] = Array.from(new Set(filteredCategory)); // Ensure unique genres
      }
      return acc;
    }, {});
    setFilteredGenres(filtered);
  };

  //CENTER BUTTON: ADD THE FOCUSED GENRE FROMTHE LEFT SIDE TO THE RIGHT SIDE (selectedGEnres)
  const handleAddSelectedGenres = () => {
    // Logic to add selected genres to the right side
    if (
      focusedGenreOnLeftSide &&
      !selectedGenres.includes(focusedGenreOnLeftSide)
    ) {
      setSelectedGenres([...selectedGenres, focusedGenreOnLeftSide]);
      props.setSelectedGenres([...selectedGenres, focusedGenreOnLeftSide]);
    }
    setFocusedGenreOnLeftSide(""); //remove focus from cell
  };

  //CENTER BUTTON: REMOVE THE FOCUSED GENRE FROM THE RIGHT SIDE (selectedGenres)
  const handleRemoveSelectedGenres = () => {
    // Logic to remove selected genres from the right side
    // Logic to add selected genres to the right side
    if (focusedGenreOnRightSide) {
      const updatedSelectedGenres = selectedGenres.filter(
        (genre) => genre !== focusedGenreOnRightSide
      );
      setSelectedGenres(updatedSelectedGenres);
      props.setSelectedGenres(updatedSelectedGenres);
    }
    setFocusedGenreOnLeftSide(""); //remove focus from cell
    setFocusedGenreOnRightSide("");
  };

  function handleRemoveAllSelectedGenres() {
    setSelectedGenres([]); // Clears all selected genres
    props.setSelectedGenres([]);
    setFocusedGenreOnRightSide(""); // Remove focus from any cell
  }

  //RIGHT SIDE
  //SELECTED GENRES USER SELECTED
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [focusedGenreOnRightSide, setFocusedGenreOnRightSide] = useState("");
  ///

  function handleGenreClickLeft(genre) {
    setFocusedGenreOnLeftSide(genre);
  }

  function handleDoubleClickLeft(genre) {
    if (genre && !selectedGenres.includes(genre)) {
      setSelectedGenres([...selectedGenres, genre]);
      props.setSelectedGenres([...selectedGenres, genre]);
    }
    setFocusedGenreOnLeftSide(""); //remove focus from cell
  }

  function handleGenreClickRight(genre) {
    setFocusedGenreOnRightSide(genre);
  }

  function handleDoubleClickRight(genre) {
    if (genre) {
      const updatedSelectedGenres = selectedGenres.filter(
        (selectedGenre) => selectedGenre !== genre
      );
      setSelectedGenres(updatedSelectedGenres);
      props.setSelectedGenres(updatedSelectedGenres);
    }
    setFocusedGenreOnRightSide(""); // Remove focus from cell
  }

  //VIEW
  return (
    <div className="musicgenresselect">
      <div className="subscrtitle">
        <i className="fa fa-music" /> Subscribe for different genres(optional)
      </div>
      <div className="twosideselect">
        {/* Left side - Available Genres */}
        <div className="left-side">
          <input
            type="text"
            placeholder="Select Genres:"
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          {/* // Rendering for the left side genres */}
          <ul className="available-genres">
            {Object.keys(filteredGenres).map((category) => (
              <li key={category}>
                <strong>{category}</strong>
                <ul>
                  {filteredGenres[category].map((genre) => (
                    <li
                      key={genre}
                      onClick={() => handleGenreClickLeft(genre)}
                      onDoubleClick={() => handleDoubleClickLeft(genre)}
                      className={
                        focusedGenreOnLeftSide === genre ? "focused" : ""
                      }
                    >
                      <label htmlFor={`checkbox-${genre}`}>
                        {selectedGenres.includes(genre) && (
                          <i className="fa fa-check-circle"></i>
                        )}{" "}
                        {genre
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ? (
                          <>{highlightSearchTerm(genre, searchTerm)}</>
                        ) : (
                          genre
                        )}
                      </label>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        {/* Center - Add/Remove Buttons */}
        <div className="center">
          <button title="+Add" onClick={handleAddSelectedGenres}>
            &rarr;
          </button>
          <button title="-Remove" onClick={handleRemoveSelectedGenres}>
            &larr;
          </button>
          <button title="Remove All" onClick={handleRemoveAllSelectedGenres}>
            &laquo;
          </button>
        </div>

        {/* Right side - Selected Genres */}
        <div className="right-side">
          <h3>Selected Genres:</h3>
          {selectedGenres.length === 0 ? (
            <p>
              Select the music genres you'd like to subscribe to and receive
              email alerts whenever new tracks are added!
            </p>
          ) : (
            <ul className="selected-genres">
              {selectedGenres.map((genre) => (
                <li
                  className={focusedGenreOnRightSide === genre ? "focused" : ""}
                  key={genre}
                  onClick={() => handleGenreClickRight(genre)}
                  onDoubleClick={() => handleDoubleClickRight(genre)}
                >
                  {genre}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );

  // Function to highlight search term within a genre
  function highlightSearchTerm(text, searchTerm) {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.split(regex).map((part, index) => (
      <span
        key={index}
        className={
          part.toLowerCase() === searchTerm.toLowerCase() ? "highlight" : ""
        }
      >
        {part}
      </span>
    ));
  }
}

export default MultiGenres;
