import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
//CSS
import "./AppShareTopBar.css";

const twitterShare =
  "https://twitter.com/intent/tweet?original_referer=http%3A%2F%2Ffiddle.jshell.net%2F_display%2F&text=I'm on SongDrop! @songdropuk&url=https://songdrop.uk";
const facebookShare =
  "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsongdrop.uk&picture=https://cdn.songdrop.cloud/docs/songdropukbanner.png&title=I'm+on+SongDrop!+@songdropuk&quote=I'm+on+SongDrop!+@songdropuk&description=I'm+on+SongDrop!+@songdropuk";

//AppShareTopBar class
function AppShareTopBar(props) {
  ///
  const [linkCopyPasted, setLinkCopypasted] = useState(false);
  function shareSongDropUk() {
    const shareAppNavbar = document.getElementById("shareappnavbar");
    if (shareAppNavbar) {
      shareAppNavbar.scrollLeft = 0; // Scrolls to the beginning horizontally
    }
    setLinkCopypasted(true);
  }

  const handleStoryClick = () => {
    props.showWebsiteLinkShare();
  };

  //VIEW
  return (
    <div
      className="shareappnavbar"
      id="shareappnavbar"
      style={{ backgroundColor: !linkCopyPasted ? "#0064f1" : "orange" }}
    >
      {!linkCopyPasted && (
        <div className="toshare">
          <button
            className="_close-button"
            onClick={() => props.hideShareMsg()}
          >
            <i className="fa fa-close" />
          </button>
          <p>
            SongDrop is currently limited for the UK App Store(iOS), but
            together we can make it available for everyone, on any device! Help
            us by sharing it
          </p>
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => shareSongDropUk()}
          >
            <div className="share-button">
              <i className="fa fa-share-square-o"></i>
              <span>Share</span>
            </div>
          </CopyToClipboard>{" "}
        </div>
      )}
      {linkCopyPasted && (
        <div className="linkcopied">
          <div className="copiedclose" onClick={() => props.hideShareMsg()}>
            <i className="fa fa-close" />
          </div>
          <div className="copied">Link Copied!</div>{" "}
          <button onClick={() => handleStoryClick()}>
            <i className="fa fa-instagram" />
            Story
          </button>
          <a
            href={twitterShare}
            target="_blank"
            onClick="return Share.me(this)"
            rel="noreferrer"
          >
            <i className="fa fa-twitter" />
            Twitter
          </a>
          <a
            href={facebookShare}
            target="_blank"
            onClick="return Share.me(this);"
            rel="noreferrer"
          >
            <i className="fa fa-facebook" />
            Facebook
          </a>
        </div>
      )}
    </div>
  );
}

export default AppShareTopBar;
