//external fonts(replace this with yours)
//example:
//1. Add your custom font in web_fonts/ folder
//2. Add your custom font in WebsiteRandomFonts.css to use
//3. Add your custom font to web_font_external list "your_custom_font_name"
// @font-face {
//   font-family: "3DIsometric";
//   src: url(./web_fonts/3DIsometric-YzLLy.ttf) format("truetype");
// }

const web_font_external = [
  "3DIsometric",
  "3DIsometricBlack",
  "3DIsometricBold",
  "AstroSpace",
  "BerkshireSwash",
  "CloisterBlackLight",
  "CollegiateheavyoutlineMedium",
  "DigitaltsLime",
  "DigitaltsOrange",
  "DigitaltsPlum",
  "Freshman",
  "InLivingColorRegular",
  "LowballNeueBold",
  "LowballNeueBoldItalic",
  "LowballNeueExtraLight",
  "LowballNeueExtraLightItalic",
  "LowballNeueItalic",
  "LowballNeueLight",
  "LowballNeueLightItalic",
  "LowballNeueMedium",
  "LowballNeueMediumItalic",
  "LowballNeueRegular",
  "LowballNeueSemiBold",
  "LowballNeueSemiBoldItalic",
  "LowballNeueThin",
  "LowballNeueThinItalic",
  "MetalMacabre",
  "Montserrat-Bold",
  "Montserrat-Regular",
  "Moonhouse",
  "PublicPixel",
  "Roboto-Black",
  "Roboto-BlackItalic",
  "Roboto-Bold",
  "Roboto-BoldItalic",
  "Roboto-Italic",
  "Roboto-Light",
  "Roboto-LightItalic",
  "Roboto-Medium",
  "Roboto-MediumItalic",
  "Roboto-Regular",
  "Roboto-Thin",
  "Roboto-ThinItalic",
  "Robtronika",
  "RobtronikaItalic",
  "SuperCorn",
  "SuperDream",
  "SuperFunky",
];

//default fonts in browser
const web_fonts_default = [
  "Impact Label",
  "Evogria",
  "Arial",
  "Helvetica",
  "Verdana",
  "Times New Roman",
  "Courier New",
  "Georgia",
  "Palatino",
  "Arial Black",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Courier",
  "Copperplate",
  "Futura",
  "Century Gothic",
  "Baskerville",
  "Optima",
  "Rockwell",
];

//web color palette
const web_color_palette_list = [
  ["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51"], //Earth Tones
  ["#ff6b6b", "#f4f1de", "#2a9d8f", "#e9c46a", "#264653"], //Vintage Summer
  ["#1e293b", "#3e4c5e", "#f9fafb", "#c3cfe2", "#ced4da"], //Cool Blue
  ["#2d6a4f", "#419d78", "#76d7c4", "#abebc6", "#d4f3ef"], //Minty Fresh
  ["#ff6f61", "#ffb04b", "#ffff44", "#62d2a2", "#1fa2ff"], //Bright & Bold
  ["#2d00f7", "#6246ea", "#9d4edd", "#ff46b5", "#ff8c00"], //Neon Lights
  ["#ff968a", "#fce38a", "#eaffd0", "#95e1d3", "#aaf0d1"], //Pastel Dream
  ["#ffcccc", "#ffb3b3", "#ff9999", "#ff8080", "#ff6666"], //Red Gradient
  ["#ffbdbd", "#ff9f9f", "#ff7d7d", "#ff5e5e", "#ff4040"], //Pink Gradient
  ["#ffe6e6", "#ffd1d1", "#ffbcbc", "#ffa6a6", "#ff9191"], //Peach Gradient
  ["#ffecd3", "#ffd1ba", "#ffbfa1", "#ffa685", "#ff8a66"], //Orange Gradient
  ["#fff3cd", "#ffe8b6", "#ffdd9e", "#ffd087", "#ffc773"], //Yellow Gradient
  ["#d6ffe8", "#abedc6", "#7cdcb5", "#5fd0a3", "#3cb77a"], //Green Gradient
  ["#d7f8f1", "#a4e4df", "#76d0cc", "#46aeae", "#2b8a8a"], //Blue Gradient
  ["#c9e4de", "#98c7c2", "#6aacb8", "#4787a6", "#2d5d67"], //Turquoise Gradient
  ["#f7d1cd", "#edbfb9", "#e2aea3", "#d8937d", "#cc7a68"], //Coral Gradient
  ["#ffffff", "#000000"], //black and white
];

//web hashtag content text
const web_hashtags = [
  "#today",
  "#birthday",
  "#peace",
  "#love",
  "#fresh",
  "#feelgood",
  "#dancing",
  "#morning",
  "#night",
  "#moody",
  "#relaxingvibes",
  "#uplifting",
  "#chillout",
  "#summergrooves",
  "#feelgoodmusic",
  "#partyplaylist",
  "#mellowtones",
  "#nightlife",
  "#energeticbeats",
  "#acousticlovers",
  "#soulfulsounds",
  "#melancholicmelodies",
  "#lovesongs",
  "#sentimentalmood",
  "#weekendvibes",
  "#roadtripmusic",
  "#pumpupthevolume",
  "#danceallnight",
  "#coffeeandmusic",
  "#inspiringmelodies",
  "#happytunes",
  "#rainymood",
  "#throwbackjams",
  "#groovybeats",
  "#indievibes",
  "#electroflow",
  "#bluesyvibes",
  "#classicsoul",
  "#jazzandchill",
  "#altrock",
  "#bedroomplaylist",
];

//seo hashtag for social media posts
const web_hashtag_seo = [
  "#indiemusic",
  "#newmusic",
  "#musicdiscovery",
  "#independentartists",
  "#musicpromotion",
  "#supportlocalmusic",
  "#unsignedartist",
  "#musiccommunity",
  "#discovermusic",
  "#musiclovers",
  "#indieartists",
  "#originalmusic",
  "#musicscene",
  "#musicindustry",
  "#musicianlife",
  "#independentmusic",
  "#musicdiscovery",
  "#indieartists",
  "#unsignedtalent",
  "#emergingartists",
  "#supportindiemusic",
  "#originalsongs",
  "#musiccommunity",
  "#newmusicfinds",
  "#discovernewmusic",
  "#upandcomingartists",
  "#undergroundmusic",
  "#musiccreators",
  "#indiemusicians",
  "#musicpromo",
  "#musicscene",
  "#musicianlife",
  "#musicbiz",
  "#independentcreators",
  "#musicproducers",
  "#musicspotlight",
  "#indievibes",
  "#musicpromo",
  "#talentedmusicians",
  "#musicmarketing",
  "#indielove",
  "#indieculture",
  "#independentartists",
  "#independentband",
  "#originalmusic",
  "#independentartist",
  "#musicmakers",
  "#musicfans",
  "#musicforthesoul",
  "#indieartist",
  "#indiemusician",
  "#indieband",
  "#indiealbum",
  "#indiehit",
  "#indiehitmaker",
  "#indiehitmakers",
];

//smiley phrases
const web_smiley_phrases = [
  "😊 Keep spreading joy and positivity!",
  "😃 Your smile brightens up every room!",
  "😄 Let your happiness shine through!",
  "😁 Share your smile with the world!",
  "😆 Keep laughing and spreading cheer!",
  "😋 Your smile is like a ray of sunshine!",
  "😎 Keep rocking that confident smile!",
  "😍 Smile big and let your inner light shine!",
  "🥰 Your smile is a reflection of your beautiful soul!",
  "😇 Smile and let kindness be your superpower!",
  "🙂 Choose happiness and wear your smile proudly!",
  "🙃 Embrace the ups and downs with a smile!",
  "😄 Life is beautiful, so keep smiling!",
  "😊 Wear your smile like a crown and conquer the day!",
  "😄 Your smile has the power to change the world!",
  "🤗 Spread warmth and comfort with your smile!",
  "😊 Every smile makes the world a better place!",
  "😁 Let your smile be a beacon of hope!",
  "😊 A smile is the prettiest thing you can wear!",
  "😃 Remember, you're never fully dressed without a smile!",
];

//music quote list
const web_music_quotes = [
  "Music is the soundtrack of life.",
  "Where words fail, music speaks.",
  "Music is the universal language of mankind.",
  "Without music, life would be a mistake.",
  "Music washes away from the soul the dust of everyday life.",
  "One good thing about music, when it hits you, you feel no pain.",
  "Music expresses that which cannot be said and on which it is impossible to be silent.",
  "Music gives a soul to the universe, wings to the mind, flight to the imagination, and life to everything.",
  "Music can change the world because it can change people.",
  "Life is a song, love is the music.",
  "Where words leave off, music begins.",
  "Music is the strongest form of magic.",
  "Music in the soul can be heard by the universe.",
  "Music is the art of thinking with sounds.",
  "Music is the poetry of the air.",
  "Music is like a dream. One that I cannot hear.",
  "Music is a higher revelation than all wisdom and philosophy.",
  "Music is the wine that fills the cup of silence.",
  "Music is the shorthand of emotion.",
  "Music is what feelings sound like.",
];

//emoji art
const web_emoji_art = [
  "😂👌🎶",
  "🤣🎵",
  "😆🎧",
  "😄🎼",
  "😜🎹",
  "😎🎸",
  "🤪🎷",
  "😊🎤",
  "😃🥁",
  "😋🎻",
  "😝🎺",
  "😂🎵",
  "🤣🎶",
  "😆🎧",
  "😄🎼",
  "😜🎹",
  "😎🎸",
  "🤪🎷",
  "😊🎤",
  "😃🥁",
];

const web_text = [
  "Hey, I'm on SongDrop!",
  "Join me on SongDrop!",
  "SongDrop is a great music app.",
  "Catch me on SongDrop!",
  "Let's vibe together on SongDrop!",
  "Discover new tunes with me on SongDrop!",
  "Dive into the world of music on SongDrop!",
  "Jam out with me on SongDrop!",
  "Rock out with SongDrop!",
  "Find your groove with SongDrop!",
  "Experience music like never before with SongDrop!",
  "Find your rhythm on SongDrop!",
  "Discover your sound with SongDrop!",
  "Get lost in the music on SongDrop!",
  "Let the beats flow with SongDrop!",
  "Unlock your playlist on SongDrop!",
  "Tune in to SongDrop for endless music!",
  "Make waves in the music world with SongDrop!",
  "Elevate your playlist game with SongDrop!",
  "Embrace the groove with SongDrop!",
  "Feel the vibes on SongDrop!",
  "Create your own melody on SongDrop!",
  "Dance to your own beat on SongDrop!",
  "Find your favorite tunes on SongDrop!",
  "Unleash your inner DJ with SongDrop!",
  "Turn up the volume with SongDrop!",
  "Plug in and play with SongDrop!",
  "Let the music play with SongDrop!",
  "Share your playlist passion on SongDrop!",
  "Rock on with SongDrop!",
  "Let the music move you on SongDrop!",
  "Sing your heart out with SongDrop!",
  "Jam on SongDrop and chill!",
  "Get groovy on SongDrop!",
  "Let the music speak on SongDrop!",
  "Let's get loud with SongDrop!",
  "Hit the right notes with SongDrop!",
  "Step into the beat with SongDrop!",
  "Experience music magic with SongDrop!",
  "Discover your sonic sanctuary with SongDrop!",
  "Create your sonic signature with SongDrop!",
  "Dive into the sonic universe of SongDrop!",
  "Escape to the world of music on SongDrop!",
  "Let your playlist be your guide on SongDrop!",
  "Find your vibe and thrive on SongDrop!",
];

const web_bg_urls = [""];

//web_content_text: This will be used to generate text based content
export const web_content_text = [
  ...web_text,
  ...web_hashtags,
  ...web_music_quotes,
  ...web_emoji_art,
  ...web_smiley_phrases,
];
//web_fonts: This will be used to generate font
export const web_fonts = [...web_font_external, ...web_fonts_default];
//web_background_img_urls: background URLs to share as content background(this is not used now)
export const web_background_img_urls = [...web_bg_urls];
//web_colors is used to generate background color
export const web_colors = [...web_color_palette_list];
//seo hashtags
export const web_seo_copypaste = [...web_hashtag_seo];
