import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
//API
import { metaGet } from "../../components/getMeta";
import { clearMetaCache } from "../../utils/apis/public";
//CSS
import "./Meta.css";

//FAICONS
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

function Meta() {
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  //
  const [url, setUrl] = useState("");
  const [metadata, setMetadata] = useState({
    favIcon: "",
    title: "Preview SongDrop Links",
    url: "https://songdrop.uk",
    description:
      "Preview how your link description will look on Google, Facebook, Twitter and more!",
    image: "https://cdn.songdrop.cloud/docs/imageplaceholder.jpg",
  });

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "MetaTags | SongDrop.uk";
    //album_id
    // Parse the URL and extract the 'url' parameter
    const urlObj = new URL(window.location.href);
    const urlParam = urlObj.searchParams.get("url");

    if (urlParam) {
      fetchMetadata(urlParam);
    }
  }, []);

  const fetchMetadata = async (url) => {
    setApiWorking(true);
    setApiError("");
    try {
      // Check if URL has protocol, add 'https://' if missing
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        // Check if it starts with 'www.' and remove 'www.'
        if (url.startsWith("www.")) {
          url = `https://${url.substring(4)}`; // Remove 'www.' and add 'https://'
        } else {
          url = `https://${url}`; // Add 'https://' if neither 'http://' nor 'https://' is present
        }
      } else if (url.startsWith("www.")) {
        url = `https://${url.substring(4)}`; // Remove 'www.' and ensure 'https://'
      }
      setUrl(url);

      //queryParams
      var queryParams = [];
      queryParams.push(`url=${url}`);
      const qParameters = queryParams.join("&");
      //✅SUCCESS
      let result = await clearMetaCache(qParameters);
      //   console.log("RESULT", result);
      setApiWorking(false);
      setMetadata(result);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchMetadata(url);
  };

  return (
    <div className="meta">
      <div className="meta-header">
        <h1>Metadata Previewer</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter URL"
            required
          />
          {/* API_LOADING */}
          {isApiWorking && (
            <button type="submit" disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
          {/* API_READY */}
          {!isApiWorking && <button type="submit">Metadata</button>}
        </form>
        <div className="index-nav__list index-nav__list--secondary">
          <a
            href="https://developers.facebook.com/tools/debug/"
            target="_blank"
            className="index-nav__link"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://cards-dev.twitter.com/validator"
            target="_blank"
            className="index-nav__link"
            rel="noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.linkedin.com/post-inspector/inspect/"
            target="_blank"
            className="index-nav__link"
            rel="noreferrer"
          >
            LinkedIn
          </a>
          <a
            href="https://search.google.com/structured-data/testing-tool/u/0/"
            target="_blank"
            className="index-nav__link"
            rel="noreferrer"
          >
            Structured Data
          </a>
        </div>
      </div>

      <div className="metadata-previews">
        <div>{apiError}</div>
        <div>Preview</div>
        <GooglePreview metadata={metadata} />
        <TwitterPreview metadata={metadata} />
        <FacebookPreview metadata={metadata} />
        <LinkedInPreview metadata={metadata} />
        <PinterestPreview metadata={metadata} />
        <SlackPreview metadata={metadata} />
      </div>
      <Footer />
    </div>
  );
}

const GooglePreview = ({ metadata }) => (
  <div id="google" className="metadata-group__display">
    <h4 className="metadata-group__title">
      <span>Google</span>
    </h4>
    <div className="card-seo-google">
      <span className="card-seo-google__title js-preview-title">
        {metadata.title}
      </span>
      <div className="card-seo-google__url">
        <span className="card-seo-google__url-title js-preview-domain">
          {metadata.url}
        </span>
        <span className="card-seo-google__url-arrow"></span>
      </div>
      <span className="card-seo-google__description js-preview-description">
        {metadata.description}
      </span>
    </div>
  </div>
);

const TwitterPreview = ({ metadata }) => (
  <div id="twitter" className="metadata-group__display">
    <h4 className="metadata-group__title">
      <span>Twitter</span>
    </h4>
    <div className="card-seo-twitter">
      <div
        className="card-seo-twitter__image"
        style={{ backgroundImage: `url(${metadata.image})` }}
      ></div>
      <div className="card-seo-twitter__text">
        <span className="card-seo-twitter__title">{metadata.title}</span>
        <span className="card-seo-twitter__description">
          {metadata.description}
        </span>
        <span className="card-seo-twitter__link">{metadata.url}</span>
      </div>
    </div>
  </div>
);

const FacebookPreview = ({ metadata }) => (
  <div id="facebook" className="metadata-group__display">
    <h4 className="metadata-group__title">
      <span>Facebook</span>
    </h4>
    <div className="card-seo-facebook">
      <div
        className="card-seo-facebook__image js-preview-image"
        style={{ backgroundImage: `url(${metadata.image})` }}
      ></div>
      <div className="card-seo-facebook__text">
        <span className="card-seo-facebook__link js-preview-domain">
          {metadata.url}
        </span>
        <div className="card-seo-facebook__content">
          <div style={{ marginTop: "5px" }}>
            <div className="card-seo-facebook__title js-preview-title">
              {metadata.title}
            </div>
          </div>
          <span className="card-seo-facebook__description js-preview-description">
            {metadata.description}
          </span>
        </div>
      </div>
    </div>
  </div>
);

const LinkedInPreview = ({ metadata }) => (
  <div id="linkedin" className="metadata-group__display">
    <h4 className="metadata-group__title">
      <span>LinkedIn</span>
    </h4>
    <div className="card-seo-linkedin">
      <div
        className="card-seo-linkedin__image js-preview-image"
        style={{ backgroundImage: `url(${metadata.image})` }}
      ></div>
      <div className="card-seo-linkedin__text">
        <div className="card-seo-linkedin__content">
          <div className="card-seo-linkedin__title js-preview-title">
            {metadata.title}
          </div>
          <span className="card-seo-linkedin__link js-preview-domain">
            {metadata.url}
          </span>
        </div>
      </div>
    </div>
  </div>
);

const PinterestPreview = ({ metadata }) => (
  <div id="pinterest" className="metadata-group__display">
    <h4 className="metadata-group__title">
      <span>Pinterest</span>
    </h4>
    <div className="card-seo-pinterest">
      <div className="card-seo-pinterest__image">
        <img src={metadata.image} alt="Preview" />
      </div>
      <div className="card-seo-pinterest__content">
        <div className="card-seo-pinterest__title js-preview-title">
          {metadata.title}
        </div>
        <div className="card-seo-pinterest__dots">
          <div className="card-seo-pinterest__dot"></div>
          <div className="card-seo-pinterest__dot"></div>
          <div className="card-seo-pinterest__dot"></div>
        </div>
      </div>
    </div>
  </div>
);

const SlackPreview = ({ metadata }) => (
  <div id="slack" className="metadata-group__display">
    <h4 className="metadata-group__title">
      <span>Slack</span>
    </h4>
    <div className="card-seo-slack">
      <div className="card-seo-slack__bar"></div>
      <div className="card-seo-slack__content">
        <div className="flex">
          <img
            src={metadata.favIcon}
            className="card-seo-slack__favicon"
            alt="Favicon"
          />
          <span className="card-seo-slack__link js-preview-site-name">
            {metadata.url}
          </span>
        </div>
        <div className="card-seo-slack__title js-preview-title">
          {metadata.title}
        </div>
        <span className="card-seo-slack__description js-preview-description">
          {metadata.description}
        </span>
        <div
          className="card-seo-slack__image js-preview-image js-slack-image"
          style={{ backgroundImage: `url(${metadata.image})` }}
        ></div>
      </div>
    </div>
  </div>
);

export default Meta;
