import React, { useState, useEffect } from "react";
import ReactPdf from "./ReactPdf";
import { URLParser } from "../../components/URLParser";

//JS
import Footer from "../../components/footer/Footer";
//CSS
import "./Legal.css";
import about from "../../assets/about/about.json";

//EDShopPolicy class
function EDShopPolicy() {
  var [noclick, setNoClick] = useState("0");

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "EDShop Terms of Usage | SongDrop";

    //url paths
    let url_paths = URLParser();
    setNoClick(url_paths["no_click"] ?? "1");
  }, []);

  //VIEW
  return (
    <div className="legalbody">
      <div className="header">
        <div className="title">SONGDROP EDSHOP TERMS OF USAGE</div>
      </div>
      <div className="content">
        <ReactPdf url={about.edshop_terms_pdf} />
      </div>
      {noclick === "0" && <Footer />}
    </div>
  );
}

export default EDShopPolicy;
