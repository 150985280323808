import React, { useState, useEffect, useRef } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import isEmpty from "../../components/isEmpty";
import "./ImageSlider.css";

function ImageSlider(props) {
  // State to manage the flipping index
  useEffect(() => {
    const intervalId1 = setInterval(() => {
      flipCard("card1");
    }, 4000);

    const intervalId2 = setInterval(() => {
      flipCard("card2");
    }, 6000);

    return () => {
      clearInterval(intervalId1);
      clearInterval(intervalId2);
    };
  }, []);

  function flipCard(cardId) {
    const cardElement = document.getElementById(cardId);
    if (cardElement) {
      cardElement.style.transform =
        cardElement.style.transform === "rotateY(180deg)"
          ? "rotateY(0deg)"
          : "rotateY(180deg)";
    }
  }

  // Mouse hover function
  function handleMouseHover(cardId, isHovering) {
    if (isHovering) {
      const cardElement = document.getElementById(cardId);
      if (cardElement) {
        cardElement.style.transform = "rotateY(180deg)";
      }
    } else {
      flipCard(cardId);
    }
  }

  //VIEW
  function images() {
    if (isEmpty(props.images)) {
      return null;
    }

    return (
      <div className="image_sliders">
        <div className="image">
          <div
            id="card1"
            className="flip-container"
            onMouseEnter={() => handleMouseHover("card1", true)}
            onMouseLeave={() => handleMouseHover("card1", false)}
          >
            <div className="front">
              <img src={props.images[0]} alt="" />
            </div>
            <div className="back">
              <img src={props.images[2]} alt="" />
            </div>
          </div>
        </div>
        <div className="image">
          <div
            id="card2"
            className="flip-container"
            onMouseEnter={() => handleMouseHover("card2", true)}
            onMouseLeave={() => handleMouseHover("card2", false)}
          >
            <div className="front">
              <img src={props.images[1]} alt="" />
            </div>
            <div className="back">
              <img src={props.images[3]} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return images();
}

export default ImageSlider;
