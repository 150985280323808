import React, { useState, useEffect } from "react";
import openWikiForGenre from "../../components/wikiLink";
import isEmpty from "../../components/isEmpty";
import Newsletter from "../dashboard/emailsubscription/Newsletter";
import EDTutorial from "./EDTutorial/EDTutorial";
import DownloadApp from "../dashboard/DownloadApp";
import DiscordPanel from "../../components/discord_panel/DiscordPanel";
import useLocalStorage from "../../components/useLocalStorage";
//API
import { getAlbumQR } from "../../utils/apis/public";
//JS
import VerticalTimeline from "./VerticalTimeLine/VerticalTimeline";
import Card from "./Card";
import Zoom from "react-medium-image-zoom";
import QRCode from "react-qr-code";
//CSS
import "./QrPage.css";

import about from "../../assets/about/about.json";
import Footer from "../../components/footer/Footer";

import distribution_history from "../../assets/img/music_distribution_history.png";

const fontSize = 12;
const trackFontSize = 12;
const windowMargin = 32;
const maxCardDimension = 400;

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

const def_album = {
  album_id: "",
  explicit: "0",
  title: "",
  artist: "",
  cover_img_url: "",
  tracks: [],
};

//QrCode class
function QR() {
  //Helper:siteWidth ref(resize chartview)
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  const [edTextVisible, setEdTextVisible] = useLocalStorage(
    "edTextVisible",
    false
  );
  //qrcode
  const [album, setAlbum] = useState(def_album);
  const [artist, setArtist] = useState(null);
  const [qrcode, setQrCode] = useState("");
  const [timeline, setTimeline] = useState(null);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "QR | SongDrop.uk";
    //album_id
    var album_id = window.location.href.split("/").pop();
    //api_call
    getAlbumWithId(album_id);
    //resize waves
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  //api
  async function getAlbumWithId(album_id) {
    setApiWorking(true);
    setApiError("");

    try {
      let result = await getAlbumQR(album_id);
      // console.log("RESULT", result);
      //✅SUCCESS
      setAlbum(result["album"]);
      setArtist(result["artist"]);
      setTimeline(result["artist_timeline"]);
      setApiWorking(false);
      document.title = `${result["album"]["title"]} - ${result["album"]["artist"]} | SongDrop`;
      //QR
      var qrUrl = window.location.href;
      setQrCode(qrUrl);
      //🚫ERROR
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //GET: Title
  function titleWiki(genre) {
    return `go-to:wikipedia-${genre.toLowerCase()}`;
  }

  //VIEW
  return (
    <div className="qrbody">
      <div className="header">
        <div className="title" onClick={() => setEdTextVisible(!edTextVisible)}>
          <div className="ed_text">ELECTRONICALLY DISTRIBUTED(ED)</div>
          <div className="learn_more">
            - <i className={"fa fa-info-circle"} />
            Learn More{" "}
            <i
              className={
                edTextVisible ? "fa fa-chevron-up" : "fa fa-chevron-down"
              }
            />
          </div>
        </div>
        {edTextVisible && (
          <div className="what_ed_is">
            An <strong>ED (Electronically Distributed)</strong> product is a
            physical print of an album with a QR code. Scanning the code lets
            you purchase the digital album instantly, which can be downloaded to
            your device for offline listening. It combines the traditional
            physical album experience with the convenience of digital listening.
            {"\n"}{" "}
          </div>
        )}
      </div>
      <div className="musicalbum">
        <Card
          width={containerWidth}
          album={album}
          isApiWorking={isApiWorking}
          apiError={apiError}
        />
        <div className="genre-container">
          {!isEmpty(album.genres) &&
            album.genres.split(",").map((genre, index) => {
              return (
                <div
                  key={index}
                  title={titleWiki(genre)}
                  onClick={() => openWikiForGenre(genre)}
                  className="genre"
                >
                  {genre}
                </div>
              );
            })}
        </div>
      </div>
      <div className="download_now">
        <p>{`Buy / Drop / Download the digital album and songs in our app\n`}</p>
        <img src={distribution_history} alt="" />
        <div className="usage">
          <strong>
            🤏Hold{"   "}🤳Scan{"   "} ▶︎Play{"  "}{" "}
            <QRCode
              id="QRCode"
              value={`https://songdrop.uk/qr/${album.album_id}`}
              size={50}
            />
          </strong>
        </div>
      </div>
      <div>
        {artist && <VerticalTimeline timeline={timeline} user={artist} />}
      </div>
      <div>
        <div className="download_now">Platforms/countries</div>
        <DownloadApp />
      </div>
      <div>
        <EDTutorial />
      </div>
      <div>
        <DiscordPanel />
      </div>
      <div>
        <Newsletter />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );

  //UPDATE::Window - Update ContentWidth
  function updateWindowDimensions() {
    const _containerWidth = window.innerWidth;
    setContainerWidth(_containerWidth);
  }
}

export default QR;
