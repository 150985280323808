import React, { useEffect } from "react";
import Newsletter from "../dashboard/emailsubscription/Newsletter";
//css
import "./Blog.css";

import BlogHome from "./BlogHome";
import DiscordPanel from "../../components/discord_panel/DiscordPanel";
import Socials from "../../components/socials/socials";
import Footer from "../../components/footer/Footer";

//
//Blog class
function Blog(props) {
  //USE_EFFECT
  useEffect(() => {
    //title
    document.title = "Blog | SongDrop";
  }, []);

  //VIEW
  return (
    <div className="blog-body">
      <BlogHome />
      <Newsletter />
      <DiscordPanel />
      <Socials />
      <Footer />
    </div>
  );
}

export default Blog;
