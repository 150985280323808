import React, { useState, useEffect } from "react";
import "./DashLoader.css"; // Your CSS file for styling the loader

const DashLoader = (props) => {
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoading((prevLoading) => (prevLoading === 4 ? 0 : prevLoading + 1));
    }, 500); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, []);

  const renderIcons = () => {
    const icons = [
      "fa fa-volume-up",
      "fa fa-user-circle-o",
      "fa fa-film",
      "fa fa-rss-square",
      "fa fa-podcast",
    ];

    return icons.map((icon, index) => (
      <i
        key={index}
        className={`loader-icon ${index === loading ? "expand" : ""} ${icon}`}
        aria-hidden="true"
      ></i>
    ));
  };

  return (
    <div>
      <div className="loader-container">{renderIcons()}</div>
    </div>
  );
};

export default DashLoader;
