import React, { useState, useEffect } from "react";

//CSS
import "./AppTabBar.css";

//AppTabBar class
function AppTabBar(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  function shareSongDrop() {
    props.shareSongDrop();
  }
  //VIEW
  return (
    <div id="app-navbar" className="app-navbar-bottom">
      <a
        className="app-navbar-bottom-button"
        rel="noreferrer"
        href={"/mobileapp"}
        alt=""
        title="Download For Mobile  "
      >
        <i className="fa fa-mobile" />
        <p>App</p>
      </a>
      <a
        className="app-navbar-bottom-button"
        rel="noreferrer"
        href={"/omv"}
        alt=""
        title="Official Music Video Clips"
      >
        <i className="fa fa-film" />
        <p>
          OMV
          {true && ( // Add a conditional to show the badge
            <span className="notification-badge">1</span> // You can style this span to be your red notification badge
          )}
        </p>
      </a>

      <a
        className="app-navbar-bottom-button"
        rel="noreferrer"
        href={"/@/songdropofficial"}
        alt=""
        title="Find Users"
      >
        <i className="fa fa-at" />
        <p>Users</p>
      </a>
      <a
        className="app-navbar-bottom-button alt-red air-now-font"
        rel="noreferrer"
        href={"https://songdrop.live/"}
        alt=""
        title="LIVE! What's On?"
        target="_blank"
      >
        <i className="fa fa-volume-up" />
        <p> ⌁ON AIR⌁</p>
      </a>
      <button
        className="app-navbar-bottom-button"
        rel="noreferrer"
        onClick={() => shareSongDrop()}
        alt=""
        title="Share SongDrop"
      >
        <i className="fa fa-share-square" />
        <p>Share</p>
      </button>
      {/* <a
        className="app-navbar-bottom-button"
        rel="noreferrer"
        href={"/supportus"}
        alt=""
        title="Support Us"
      >
        <i className="fa fa-heart" />
        <p>Support</p>
      </a> */}
    </div>
  );
}

export default AppTabBar;
