import React, { useState } from "react";
import { submitOMVReport } from "../../utils/apis/public";
// CSS
import "./VideoInfoModal.css";
import isEmpty from "../../components/isEmpty";

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

function VideoInfoModal(props) {
  //
  const { video, onClose } = props;

  // State to control the reporting feature visibility
  const [isApiWorking, setApiWorking] = useState(false);
  const [isReportSubmitted, setReportSubmitted] = useState(false);
  const [reportingVisible, setReportingVisible] = useState(false);
  const [reportText, setReportText] = useState("");

  // Function to handle the reporting feature
  const handleReportClick = () => {
    setReportingVisible(!reportingVisible);
  };

  // Function to submit the report
  const handleSubmitReport = (e) => {
    e.preventDefault();
    //
    if (isEmpty(reportText)) {
      alert("Please describe your issue or feedback on this video link.");
      return;
    }
    if (reportText.length < 50) {
      alert("Please provide a description of at least 50 characters.");
      return;
    }
    if (reportText.length > 200) {
      alert("Please limit your description to a maximum of 200 characters.");
      return;
    }
    //SUBMIT REPORT API CALL
    submitReport(video.video_id, reportText);
  };

  async function submitReport(video_id, report) {
    setApiWorking(true);
    try {
      var queryParams = [];
      queryParams.push(`video_id=${video_id}`);
      let qParameters = queryParams.join("&");
      var bodyParams = [];
      bodyParams.push(`report=${report}`);
      let bParameters = bodyParams.join("&");
      //✅SUCCESS
      const result = await submitOMVReport(qParameters, bParameters);
      console.log(result["message"]);
      alert(result["message"]);
      // Perform the necessary actions to submit the report, e.g., send to your server
      // Clear the report text and hide the reporting feature
      setReportText("");
      // setReportingVisible(false);
      setReportSubmitted(true);
      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      console.log(error.message);
      setApiWorking(false);
    }
  }

  //VIEW
  return (
    <div className="video-info-modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h1>
          <a
            className="title_url"
            href={`${video.t_link}`}
            alt=""
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-external-link-square" />
            {video.a_title}
          </a>
        </h1>
        <p>{video.description}</p>
        <iframe
          title={video.a_title}
          src={video.video_url}
          frameBorder="0"
          allowFullScreen
          height={220}
        ></iframe>
        <p className="shared-by">
          {`This video link is shared by the artist `}
          <a
            href={`https://songdrop.uk/@/${video.username}`}
            target="_blank"
            rel="noreferrer"
          >
            {`@${video.username}`}
          </a>
          {`. If you have any issues with this link, `}
          <span className="report-link" onClick={handleReportClick}>
            report it here
          </span>
          {`.`}
        </p>
        {isReportSubmitted && (
          <div className="report_submitted">
            <i className="fa fa-check-circle" />
            Thank you for submitting your report. We will review the video link.
            If necessary, we will take appropriate actions. If you have issues
            with the video content itself, please consider reporting it on the
            platform where it is hosted.
          </div>
        )}
        {reportingVisible && !isReportSubmitted && (
          <form className="reporting-feature" onSubmit={handleSubmitReport}>
            <div style={{ position: "relative" }}>
              <textarea
                disabled={isApiWorking}
                value={reportText}
                onChange={(e) => {
                  const inputText = e.target.value;
                  if (inputText.length <= 200) {
                    setReportText(inputText);
                  }
                }}
                placeholder="Report issues or provide feedback (max 200 chars)"
                maxLength="200"
                rows="4"
                cols="50"
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "12px",
                  left: "5px",
                  fontSize: "12px",
                  color: reportText.length > 200 ? "red" : "inherit",
                }}
              >
                {reportText.length}/200
              </div>
            </div>
            {!isApiWorking && (
              <button disabled={isEmpty(reportText)}>Submit</button>
            )}
            {isApiWorking && (
              <button disabled={true}>
                <i className={apiFaIcon} />
              </button>
            )}
          </form>
        )}
      </div>
    </div>
  );
}

export default VideoInfoModal;
