import React, { useState, useEffect } from "react";
import numberFormatter from "../../components/numberFormatter";
import isEmpty from "../../components/isEmpty";
import openWikiForGenre from "../../components/wikiLink";
import Linkify from "react-linkify";
import { getUsername } from "../../utils/apis/public";
//JS
import ProfileSearch from "./ProfileSearch";
import ProfileSearchBar from "./ProfileSearchBar";
import ProfileHeader from "./ProfileHeader";
import DropCell from "./DropCell";
import VerticalTimeline from "../qr/VerticalTimeLine/VerticalTimeline";
//CSS
import "./ProfileCard.css";

import Footer from "../../components/footer/Footer";
import ListenDrop from "./ListenDrop";
import ReactCopyCode from "../../components/copycode/ReactCopyCode";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//ProfileCard class
function ProfileCard({ shareMyProfile }) {
  //
  const [user, setUser] = useState(null);
  const [_title, setTitle] = useState("songdropofficial");
  //API:
  //def: 0, sync: 1, success: 2, error: 3
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");
  //user's is in editing mode.
  //viewing_drop
  const [dropToListen, setSelecteddropToListen] = useState(null);
  const [timeline, setTimeline] = useState(null);
  //streamurl to play audio source
  const [streamUrl, setStreamUrl] = useState("");

  //SEARCH
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  //
  const [isCodeOpen, setIsCopeOpen] = useState(false);

  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "SongDrop | @";
    //get path, remove extra stuff it has
    var username = window.location.href.split("/")[4].split("?")[0];
    setTitle(username);
    _getUserName(username);
  }, []);

  //api
  async function _getUserName(username) {
    setApiWorking(true);
    setApiError("");

    try {
      let result = await getUsername(username);
      if (result === "USER_NOT_FOUND") {
        //❌Error: USER NOT FOUND
        throw new Error("USER NOT FOUND");
      } else {
        //✅SUCCESS
        setUser(result["user"]);
        setTimeline(result["artist_timeline"]);
        setApiWorking(false);
        document.title = `${result["user"]["name"]} (@${result["user"]["username"]}) | SongDrop`;
      }
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //UPDATE: SearchBar text
  function textDidChange(searchtext) {
    setSearchText(searchtext);
  }

  //SEARCH
  const searchForText = () => {
    setApiWorking(true);
    setApiError("");
    setIsSearching(true);
  };

  const togglePopup = () => {
    setApiWorking(false);
    setApiError("");
    setIsSearching(false);
  };

  //VIEW
  return (
    <div className="profile_body">
      {isCodeOpen && (
        <ReactCopyCode
          parameters={user ? `u=${user.username}&theme=dark` : ""}
          closeCopy={() => setIsCopeOpen(!isCodeOpen)}
        />
      )}
      {/* <ReactCopyCode parameters={user ? `u=${user.username}&theme=pink` : ""} /> */}

      {isSearching && (
        <ProfileSearch searchText={searchText} handleClose={togglePopup} />
      )}
      {isEmpty(user) && isApiWorking && (
        <div>
          <div className="profile-api-loading">
            <p>
              <i className={apiFaIcon} /> {_title}
            </p>
          </div>
          {socialNumbers(0, 0, 0, 0, false)}
        </div>
      )}
      {isEmpty(user) && !isApiWorking && apiError && (
        <div className="profile-error-title">
          <p>{apiError}</p>
        </div>
      )}
      {!isEmpty(user) && (
        <div>
          <div style={{ backgroundColor: "#f0f0f0" }}>
            <ProfileSearchBar
              isApiWorking={isApiWorking}
              searchText={searchText}
              searchForText={searchForText}
              textDidChange={textDidChange}
            />
          </div>
          <div className="header-container">
            <ProfileHeader
              user={user}
              shareMyProfile={shareMyProfile}
              shareCode={() => setIsCopeOpen(!isCodeOpen)}
              setApiError={setApiError}
            />
          </div>
          {/* PROFILE_VIEWING */}
          <div className="user-profile">
            <div className="about">
              <Linkify>{formatMessage(user.about)}</Linkify>
            </div>
            <div className="genre-container">
              {!isEmpty(user.genres) &&
                user.genres.split(",").map((genre, index) => {
                  return (
                    <div
                      key={index}
                      title={titleWiki(genre)}
                      onClick={() => openWikiForGenre(genre)}
                      className="genre"
                    >
                      {genre}
                    </div>
                  );
                })}
            </div>
          </div>
          {/* SOCIAL_INDICATOR_NUMBERS */}
          {socialNumbers(
            user.albums_number,
            user.drops_number,
            user.followers_number,
            user.following_number,
            true
          )}
          {/* USER_ACTIVE_DROPS */}
          <div className="drops-container">
            <div id="drops" className="header">
              Drops
            </div>
            {/* NO DROPS */}
            {isEmpty(user.last_drops) && (
              <div className="no-drops-content">
                <p>No drops in the last 24h </p>
              </div>
            )}
            {/* ACTIVE DROPS */}
            {!isEmpty(user.last_drops) && (
              <div className="active-drops">
                {user.last_drops.map((drop, index) => {
                  return (
                    <DropCell
                      key={index}
                      drop={drop}
                      didSelectDrop={didSelectDrop}
                      dropToListen={dropToListen}
                    />
                  );
                })}
              </div>
            )}
            {!isEmpty(dropToListen) && (
              <ListenDrop
                dropToListen={dropToListen}
                streamUrl={streamUrl}
                setSelecteddropToListen={setSelecteddropToListen}
              />
            )}
          </div>
          {/* VERTICAL TIMELINE */}
          {!isEmpty(timeline) && !isEmpty(user) && (
            <VerticalTimeline timeline={timeline} user={user} />
          )}
        </div>
      )}
      <br />
      <Footer />
    </div>
  );

  //@username click added
  function formatMessage(message) {
    const regex = /@(\w+)/g; // Matches @username
    const parts = message.split(regex);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // Every odd index contains a username
        return (
          <a
            key={index}
            href={`https://songdrop.uk/@/${part}`}
            target="_blank"
            rel="noopener noreferrer"
            className="username-link"
          >
            @{part}
          </a>
        );
      } else {
        return part;
      }
    });
  }
  //GET: Title
  function titleWiki(genre) {
    return `go-to:wikipedia-${genre.toLowerCase()}`;
  }

  //SELECT: Drop
  function didSelectDrop(drop) {
    setSelecteddropToListen(drop);
    setStreamUrl(drop.song.prev_url);
    var element = document.getElementById("drops");
    setTimeout(function () {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }, 10);
  }

  function socialNumbers(
    albums_number,
    drops_number,
    followers_number,
    following_number,
    show_numbers
  ) {
    return (
      <div className="stat-info-container">
        <div className="profile-stat-table">
          <div>
            <div className="p-stat-title">Albums</div>
            <div className="p-stat-value">
              {show_numbers && <div> {numberFormatter(albums_number, 1)}</div>}
              {!show_numbers && (
                <i className="fa fa-volume-up" aria-hidden="true"></i>
              )}
            </div>
          </div>
          <div>
            <div className="p-stat-title">Drops</div>
            <div className="p-stat-value">
              {show_numbers && <div> {numberFormatter(drops_number, 1)}</div>}
              {!show_numbers && (
                <i className="fa fa-music" aria-hidden="true"></i>
              )}
            </div>
          </div>
          <div>
            <div className="p-stat-title">Following</div>
            <div className="p-stat-value">
              {show_numbers && (
                <div> {numberFormatter(following_number, 1)}</div>
              )}
              {!show_numbers && (
                <i className="fa fa-user-plus" aria-hidden="true"></i>
              )}
            </div>
          </div>
          <div>
            <div className="p-stat-title">Followers</div>
            <div className="p-stat-value">
              {show_numbers && (
                <div> {numberFormatter(followers_number, 1)}</div>
              )}
              {!show_numbers && (
                <i className="fa fa-users" aria-hidden="true"></i>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileCard;
