import React, { useState, useEffect } from "react";
import isEmpty from "../../components/isEmpty";
//CSS
import "./System_Updates.css";

//system_updates.json
import system_updates from "../../assets/about/system_updates.json";

//System_Updates class
function System_Updates(props) {
  //ViewDidLoad
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "SongDrop | Updates";
    //
  }, []);

  //VIEW
  return (
    <div className="system_updates">
      <div className="_title">
        <i className="fa fa-floppy-o" aria-hidden="true"></i> System Updates
      </div>
      <div className="_table">{get_versions()}</div>
      <div>{new Date().toISOString().split("T")[0]}</div>
    </div>
  );

  function get_versions() {
    var tableRows = [];
    if (isEmpty(system_updates)) {
      return tableRows;
    }
    system_updates.forEach((item, index) => {
      console.log(item);
      const tableRow = (
        <div key={index} id={item.platform}>
          <div className="platform">
            <div className="p_title">
              <strong>{item.platform.toUpperCase()}</strong>
              <div>
                <a href={item.url}>{item.url}</a>
              </div>
            </div>

            {get_versions_platform(item.app)}
          </div>
        </div>
      );
      tableRows.push(tableRow);
    });

    return tableRows;
  }

  function get_versions_platform(platform_versions) {
    var tableRows = [];
    if (isEmpty(platform_versions)) {
      return tableRows;
    }
    platform_versions.forEach((app, index) => {
      const tableRow = (
        <div key={index}>
          <div className="app">
            <div>Version {app.version}</div>
            <div>
              <strong>What's new?</strong>
            </div>
            <div>{app.whats_new}</div>
          </div>
        </div>
      );

      tableRows.push(tableRow);
    });

    tableRows = tableRows.sort(function (left, right) {
      return left.index < right.index;
    });
    return tableRows;
  }
}

export default System_Updates;
