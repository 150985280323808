import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import QRCode from "react-qr-code";
import JustCountries from "../../components/countriesavailable/JustCounties";
import Zoom from "react-medium-image-zoom";

import about from "../../assets/about/about.json";
//CSS
import "./DownloadApp.css";

//Dash class
function DownloadApp() {
  //
  const [shareActive, setShareActive] = useState(false);
  function shareSongDropLink() {
    setShareActive(true);
  }

  //VIEW
  return (
    <div className="download-app">
      <div className="country">
        <JustCountries />
      </div>
      <div className="download">
        <a
          rel={"noreferrer"}
          href={about.appstore_url}
          className="appstore"
          title={"Download SongDrop iOS App"}
          target="_blank"
        >
          <i className="fa fa-apple" />
          <p className="app_text">Download iOS {about.ios_version}</p>
          <p className="app_store">App Store</p>
        </a>
        <a
          rel={"noreferrer"}
          // href={"#"}
          style={{ border: "2px solid lightgray", cursor: "default" }}
          className="appstore"
          title={"Coming soon to Android"}
          target="_blank"
        >
          <i className="fa fa-android" style={{ color: "lightgray" }} />
          <p className="app_store" style={{ backgroundColor: "lightgray" }}>
            Coming soon
          </p>
        </a>
      </div>
      <p className="information">
        <i className={"fa fa-info-circle"} />
        Listening songs/drops in the app requires user registration.{"\n"}{" "}
        Please read our <a href={about.terms_url}>Terms and Usage</a> before you
        create your account.
      </p>
      <div className="share-app">
        <div className="text">
          SongDrop is currently limited for the UK App Store(iOS), but together
          we can make it available for everyone, on any device! Help us by
          sharing this link
        </div>
        {shareActive && (
          <CopyToClipboard
            text={window.location.href}
            // onCopy={() => shareSongDropLink()}
          >
            <div className="share-button">
              <i className="fa fa-check-circle"></i>
              <span>Copied.</span>
            </div>
          </CopyToClipboard>
        )}
        {!shareActive && (
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => shareSongDropLink()}
          >
            <div className="share-button">
              <i className="fa fa-share-square-o"></i>
              <span>Share</span>
            </div>
          </CopyToClipboard>
        )}
      </div>
    </div>
  );
}

export default DownloadApp;
