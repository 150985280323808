import React, { useState, useEffect } from "react";
import ReactLinkify from "react-linkify";
//data
import faqdata from "./faqdata";
//css
import "./FaqLanding.css";

//FaqLanding class
function FaqLanding(props) {
  //
  const [_index, setIndex] = useState(-99);

  //ViewDidLoad
  useEffect(() => {
    //
  }, []);

  function setIndexPath(index) {
    if (index === _index) {
      setIndex(-99);
    } else {
      setIndex(index);
    }
  }
  //VIEW
  return <div className="faqlanding">{landingPageResults(faqdata)}</div>;

  //forEachExample
  function landingPageResults(data) {
    var model = [];
    data.forEach((item, index) => {
      //here is your code
      model.push(
        <div
          className="question"
          key={index}
          onClick={() => setIndexPath(item.index)}
        >
          {_index === item.index && (
            <div>
              <strong>
                <i
                  className={
                    _index === item.index
                      ? "fa fa-minus-square"
                      : "fa fa-plus-square"
                  }
                />{" "}
                {item.question}
              </strong>
            </div>
          )}
          {_index !== item.index && (
            <div>
              <i
                className={
                  _index === item.index
                    ? "fa fa-minus-square"
                    : "fa fa-plus-square"
                }
              />{" "}
              {item.question}
            </div>
          )}
          {_index === item.index && (
            <ReactLinkify className="answer">{item.answer}</ReactLinkify>
          )}
        </div>
      );
    });
    return model;
  }
}

export default FaqLanding;
