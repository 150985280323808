import React, { useState, useEffect } from "react";
import TutorialsHeader from "./TutorialsHeader";
import "./Tutorials.css";
import Footer from "../../components/footer/Footer";

const initialVideos = [
  {
    id: "1",
    title: "Album cover flow in the iOS app",
    link: "https://www.youtube.com/embed/HvRk3N8GHnk",
    tags: ["iOS", "Cover Flow", "Album"],
  },
  {
    id: "2",
    title: "How to order your first ED(Electronically Distributed)",
    link: "https://www.youtube.com/embed/8E9XiLhVvJQ",
    tags: ["ED", "Electronically Distributed"],
  },
  {
    id: "4",
    title: "Showcasing our app video",
    link: "https://www.youtube.com/embed/gRZ1vHTJd7Y",
    tags: ["iOS", "Mobile App", "Showcase"],
  },
];

function Tutorials(props) {
  // Parse query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const initialSearchText = urlParams.get("search") || "";
  const initialFilterTags = urlParams.getAll("tag") || [];

  const [videos, setVideos] = useState(initialVideos);
  const [searchText, setSearchText] = useState(initialSearchText);
  const [filterTags, setFilterTags] = useState(initialFilterTags);

  useEffect(() => {
    // Any initialization code you want to run when the component mounts
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleTagClick = (tag) => {
    if (!filterTags.includes(tag)) {
      setFilterTags([...filterTags, tag]);
    }
    setSearchText(tag);
  };

  const filterVideos = (video) => {
    // Check if the video's title contains the searchText (case-insensitive)
    const titleMatch = video.title
      .toLowerCase()
      .includes(searchText.toLowerCase());

    // Check if any of the filterTags are included in the video's tags (case-insensitive)
    const tagsMatch =
      filterTags.length === 0 ||
      filterTags.some((tag) =>
        video.tags.some((videoTag) =>
          videoTag.toLowerCase().includes(searchText.toLowerCase())
        )
      );

    // If the search bar is empty, return true to show all videos
    if (!searchText.trim()) {
      return true;
    }

    //return if titleMatch or tagsMatch
    return titleMatch || tagsMatch;
  };

  const getUniqueTags = () => {
    const allTags = videos.reduce((tags, video) => {
      return tags.concat(video.tags);
    }, []);

    return Array.from(new Set(allTags));
  };

  return (
    <div className="tutorials">
      <TutorialsHeader />
      <div className="search-bar">
        <form>
          <div className="input-container">
            <input
              type="text"
              name="focus"
              required
              className="search-box"
              placeholder="Enter search term"
              value={searchText}
              onChange={handleSearch}
            />
            {searchText && (
              <button
                className="close-icon"
                type="reset"
                onClick={() => setSearchText("")}
              >
                <i className="fa fa-times-circle" />
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="filter-tags">
        {getUniqueTags().map((tag, tagIndex) => (
          <span
            key={tagIndex}
            className={`tag ${searchText.includes(tag) ? "active" : ""}`}
            onClick={() => handleTagClick(tag)}
          >
            {tag}
          </span>
        ))}
      </div>
      <div className="video-container">
        {videos.filter(filterVideos).map((video, index) => (
          <div key={index} className="video-card block-display">
            <iframe
              title={video.title}
              src={video.link}
              frameBorder="0"
              allowFullScreen
              height={220}
            ></iframe>
            <p>{video.title}</p>
            <div className="video-tags">
              {video.tags.map((tag, tagIndex) => (
                <span
                  key={tagIndex}
                  className="tag"
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
      {videos.filter(filterVideos).length === 0 && (
        <div className="empty-data-view">
          <p>No videos found. Please try a different search.</p>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Tutorials;
