import React, { useEffect, useRef, useState } from "react";
import "./ScanQRPhone.css"; // Import your CSS file here

function ScanQRPhone() {
  const videoRef = useRef(null);
  const [firstInteractionDone, setFirstInteractionDone] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    function handleZoom(event) {
      const zoomLevel = parseFloat(event.target.getAttribute("data-zoom"));
      videoElement.style.transform = `scale(${zoomLevel})`;
      document
        .querySelectorAll(".controls button")
        .forEach((btn) => btn.classList.remove("active"));
      event.target.classList.add("active");
    }

    document.querySelectorAll("button[data-zoom]").forEach((button) => {
      button.addEventListener("click", handleZoom);
    });

    document
      .querySelector(".switchCamera")
      .addEventListener("click", function () {
        if (!firstInteractionDone) {
          videoElement.play();
          setFirstInteractionDone(true);
        }

        const isWebcam = videoElement.getAttribute("data-iswebcam") === "true";
        videoElement.classList.add("flipping-out");

        videoElement.onanimationend = () => {
          if (isWebcam) {
            if (videoElement.srcObject) {
              const tracks = videoElement.srcObject.getTracks();
              tracks.forEach((track) => track.stop());
            }
            videoElement.srcObject = null;
            videoElement.setAttribute("crossorigin", "anonymous");
            videoElement.src =
              "https://ismailvtl.github.io/images/video-codepen-dance.mp4";
            videoElement.setAttribute("data-iswebcam", "false");
            videoElement.classList.remove("flipping-out");
            videoElement.classList.add("flipping-in");

            videoElement.play();
          } else {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
              navigator.mediaDevices
                .getUserMedia({ video: true })
                .then(function (stream) {
                  videoElement.setAttribute("crossorigin", "anonymous");
                  videoElement.srcObject = stream;
                  videoElement.setAttribute("data-iswebcam", "true");
                  videoElement.classList.remove("flipping-out");
                  videoElement.classList.add("flipping-in");
                  videoElement.play();
                })
                .catch(function (err) {
                  console.error("Error accessing the camera", err);
                });
            } else {
              console.error("Your browser does not support getUserMedia API");
            }
          }
          // On animation end, remove the flipping-in class
          videoElement.onanimationend = () => {
            videoElement.classList.remove("flipping-in");
          };
        };
      });

    document
      .querySelector(".camerabutton")
      .addEventListener("click", function () {
        const flashElement = document.querySelector(".flash");
        flashElement.classList.add("shutterClick");
        flashElement.addEventListener("animationend", function () {
          flashElement.classList.remove("shutterClick");
        });

        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        const thumbnailDataURL = canvas.toDataURL("image/jpeg");

        const thumbnailImage = document.querySelector(".thumbnail img");
        thumbnailImage.src = thumbnailDataURL;
      });

    const thumbnailButton = document.querySelector(".thumbnail");
    thumbnailButton.addEventListener("click", function () {
      const heart = document.createElement("span");
      heart.classList.add("heart");
      heart.textContent = "😉";
      thumbnailButton.appendChild(heart);
      setTimeout(() => {
        heart.style.bottom = "100%";
        heart.style.opacity = "1";
        heart.style.fontSize = "30px";
      }, 10);
      setTimeout(() => {
        thumbnailButton.removeChild(heart);
      }, 2000);
    });

    return () => {
      // Clean up event listeners when the component unmounts
      document.querySelectorAll("button[data-zoom]").forEach((button) => {
        button.removeEventListener("click", handleZoom);
      });
    };
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  return (
    <div className="imageContainer">
      <div className="imageContainerInner">
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          data-iswebcam="false"
          poster="https://images.pexels.com/videos/8688878/pexels-photo-8688878.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          crossOrigin="anonymous"
          preload="metadata"
          type="video/mp4"
        >
          <source
            src="https://ismailvtl.github.io/images/video-codepen-dance.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="flash"></div>
      </div>
      <div className="controls">
        <button data-zoom="1" className="active">
          .5<span>&times;</span>
        </button>
        <button data-zoom="1.5">
          1<span>&times;</span>
        </button>
        <button data-zoom="2.5">
          3<span>&times;</span>
        </button>
      </div>

      <div className="otherControls">
        <button className="thumbnail">
          <img
            src="https://images.pexels.com/videos/8688878/pexels-photo-8688878.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt=""
          />
        </button>
        <button className="camerabutton"></button>
        <button className="switchCamera">
          <span className="material-symbols-outlined">
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>
  );
}

export default ScanQRPhone;
