import React, { useState, useEffect } from "react";
import { URLParser } from "./components/URLParser";
import isEmpty from "./components/isEmpty";

import logo from "./assets/img/songdrop-logo-black.png";
import about from "./assets/about/about.json";

//CSS
import "./AppNavBar.css";

//AppNavBar class
function AppNavBar(props) {
  //hashtags
  const [active_hashtag, setActiveHashtag] = useState(null);
  //index
  const [activeTabName, setActiveTabName] = useState("/");
  const [noNavBar, setNoNavBar] = useState(false);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    let url_paths = URLParser();
    //no navbar
    var searchText = url_paths["no_click"] ?? "0";
    if (searchText === "1") {
      setNoNavBar(true);
    }
    //set pagebar active
    var page_name = window.location.pathname.split("/")[1];
    setActiveTabName(page_name);
    //
    //hashtag if active
    if (window.location.pathname.includes("/hashtag/")) {
      const hashtagIndex = window.location.pathname.lastIndexOf("/");
      const hashtag = window.location.pathname.substring(hashtagIndex + 1);
      setActiveHashtag(hashtag);
      setActiveTabName("#");
    }

    //
  }, []);

  //VIEW
  return (
    <div
      className="app-navbar"
      style={{ display: noNavBar ? "none" : "block" }}
    >
      <a className={`app-bar-item`} href="/" title="SongDrop: Social Music App">
        <div
          className="bcg"
          style={{
            backgroundColor: activeTabName === "" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          <img src={logo} alt="" />{" "}
        </div>
      </a>
      {!isEmpty(active_hashtag) && (
        <a
          className={`app-bar-item`}
          href={`/hashtag/${active_hashtag}`}
          title={`Discover music: #${active_hashtag}`}
        >
          <div
            className="bcg"
            style={{
              color: activeTabName === "#" ? "white" : "black",
              borderRadius: activeTabName === "#" ? "6px" : "0px",
              transform:
                activeTabName === "#" ? "scale(0.7,0.7)" : "scale(1,1)",
              backgroundColor:
                activeTabName === "#" ? "#0762ff" : "transparent",
              borderBottom:
                activeTabName === "#" ? "4px solid #d3d3d355" : "transparent",
            }}
          >
            {`#${active_hashtag}`}
          </div>
        </a>
      )}
      <a
        className={`app-bar-item`}
        href="https://songdrop.band"
        title="Upload your music on songdrop.band"
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="bcg"
          style={{
            color: "white",
            backgroundColor: "#006eff",
            borderBottom: "transparent",
          }}
        >
          <i className="fa fa-cloud-upload" />
          {".band"}
        </div>
      </a>

      <a className={`app-bar-item`} href="/subscribe" title="Newsletter">
        <div
          className="bcg"
          style={{
            color: "#006eff",
            backgroundColor:
              activeTabName === "subscribe" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "subscribe"
                ? "4px solid #d3d3d355"
                : "transparent",
          }}
        >
          <i className="fa fa-inbox" />
          Subscribe
        </div>
      </a>
      <a className="app-bar-item" href="/blog" title="Blog">
        <div
          className="bcg"
          style={{
            color: "black",
            backgroundColor:
              activeTabName === "blog" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "blog" ? "4px solid #d3d3d355" : "transparent",
            paddingRight: "18px",
          }}
        >
          <i className="fa fa-rss-square" />
          Blog
          {true && ( // Add a conditional to show the badge
            <span className="notification-badge">1</span> // You can style this span to be your red notification badge
          )}
        </div>
      </a>
      <a
        className={`app-bar-item`}
        href="https://edshop.songdrop.uk"
        title="Electronically Distributed(ED)"
      >
        <div
          className="bcg"
          style={{
            color: "black",
            backgroundColor: "transparent",
            borderBottom: "transparent",
          }}
        >
          <i className="fa fa-qrcode" />
          {"ED"}
        </div>
      </a>

      {showMoreVisible && (
        <a
          className={`app-bar-item air_now`}
          href={about.live_url}
          title={about.live_url}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className="bcg"
            style={{
              color: "#fc2d2d",
              fontSize: "16px",
              backgroundColor:
                activeTabName === "about" ? "lightgray" : "transparent",
              borderBottom:
                activeTabName === "about"
                  ? "4px solid #d3d3d355"
                  : "transparent",
            }}
          >
            ⌁ON AIR⌁
          </div>
        </a>
      )}
      {showMoreVisible && (
        <a className={`app-bar-item`} href="/faq" title={"Read more FAQ"}>
          <div
            className="bcg"
            style={{
              color: "black",
              backgroundColor:
                activeTabName === "faq" ? "lightgray" : "transparent",
              borderBottom:
                activeTabName === "faq" ? "4px solid #d3d3d355" : "transparent",
            }}
          >
            <i className="fa fa-question-circle" />
            FAQ
          </div>
        </a>
      )}

      {showMoreVisible && (
        <a className={`app-bar-item`} href="/tutorials" title={"Tutorials"}>
          <div
            className="bcg"
            style={{
              color: "black",
              backgroundColor:
                activeTabName === "tutorials" ? "lightgray" : "transparent",
              borderBottom:
                activeTabName === "tutorials"
                  ? "4px solid #d3d3d355"
                  : "transparent",
            }}
          >
            <i className="fa fa-info-circle" aria-hidden="true"></i>
            {"Hub"}
          </div>
        </a>
      )}

      {showMoreVisible && (
        <a
          className={`app-bar-item`}
          href={about.instagram_url}
          title={about.instagram_url}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className="bcg"
            style={{
              color: "black",
              backgroundColor:
                activeTabName === "?" ? "lightgray" : "transparent",
              borderBottom:
                activeTabName === "?" ? "4px solid #d3d3d355" : "transparent",
            }}
          >
            <i className="fa fa-instagram" />
            Follow
          </div>
        </a>
      )}

      {showMoreVisible && (
        <a
          className={`app-bar-item`}
          href="/supportus"
          title={"Support our journey"}
        >
          <div
            className="bcg"
            style={{
              color: "black",
              backgroundColor:
                activeTabName === "supportus" ? "lightgray" : "transparent",
              borderBottom:
                activeTabName === "supportus"
                  ? "4px solid #d3d3d355"
                  : "transparent",
            }}
          >
            <i className="fa fa-heart" />
            Support
          </div>
        </a>
      )}

      {/* SHOW MORE/HIDE */}
      {!showMoreVisible && (
        <div
          className={`app-bar-item`}
          title="Show More"
          onClick={() => setShowMoreVisible(true)}
        >
          <div
            className="bcg"
            style={{
              color: "#006eff",
              backgroundColor:
                activeTabName === "subscribe" ? "lightgray" : "transparent",
              borderBottom:
                activeTabName === "subscribe"
                  ? "4px solid #d3d3d355"
                  : "transparent",
            }}
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
            <i className="fa fa-caret-right" aria-hidden="true"></i>
          </div>
        </div>
      )}

      {showMoreVisible && (
        <div
          className={`app-bar-item`}
          title="Show Less"
          onClick={() => setShowMoreVisible(false)}
        >
          <div
            className="bcg"
            style={{
              color: "#006eff",
              backgroundColor:
                activeTabName === "subscribe" ? "lightgray" : "transparent",
              borderBottom:
                activeTabName === "subscribe"
                  ? "4px solid #d3d3d355"
                  : "transparent",
            }}
          >
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </div>
        </div>
      )}

      <div className={`app-bar-item`}>
        <div
          className="bcg"
          style={{
            color: "transparent",
            backgroundColor: "transparent",
            borderBottom: activeTabName === "transparent",
          }}
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
          <i className="fa fa-caret-right" aria-hidden="true"></i>
        </div>
      </div>
      <a
        className="app-navbar-download-button"
        rel="noreferrer"
        href={"/mobileapp"}
        alt=""
        title="Download"
      >
        <i className="fa fa-download" aria-hidden="true"></i>{" "}
      </a>
    </div>
  );
}

export default AppNavBar;
