import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import ImageSlider from "../../dashboard/ImageSlider";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

//CSS
import "./EDTutorial.css";

const picList = ["https://cdn.songdrop.cloud/docs/ed_square1.png"];

//EDTutorial className
function EDTutorial(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="edtutorial">
      <div className="left">
        <div className="guide-container">
          <h2 className="guide-title">
            How to scan the ED within the SongDrop iOS app?
          </h2>
          <ol className="guide-steps">
            <li>
              <strong>1. Download </strong>the SongDrop iOS app from the UK App
              Store.
            </li>
            <li>
              <div className="qr-text">
                <strong>2. Open the app </strong>and go to the "Browse" page by
                pulling down from the "Space" home page. Tap the QR code icon on
                the bottom right.{" "}
              </div>
              <div className="qr-code">
                {/* <Zoom> */}
                <QRCode id="QRCode" value={window.location.href} size={60} />
                {/* </Zoom> */}
              </div>
            </li>
            <li>
              <strong>3. Scan the ED</strong>'s QR code with the camera to
              purchase the digital song/album and get exclusive extras.
            </li>
            <li>
              <strong>4. Drop the song</strong> and give your followers 24h
              access to the music.
            </li>
          </ol>

          <p className="guide-note">
            Note: Android version coming soon. Help us release it in other
            regions and platforms.
          </p>
        </div>
      </div>
      <div className="right">
        <div className="screenshot-container">
          <Zoom>
            <img
              className="screenshot"
              src={picList[0]}
              alt="Screenshot of the SongDrop app"
            />
          </Zoom>
          <div className="example">Example of Scanning an ED</div>
          {/* <ImageSlider images={picList} /> */}
        </div>
      </div>
    </div>
  );
}

export default EDTutorial;
