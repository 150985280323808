import React, { useState, useEffect } from "react";
import isEmpty from "../../components/isEmpty";
import DownloadQR from "./DownloadQR";
import ListenDrop from "../@/ListenDrop";
import HashtagDropCell from "./HashtagDropCell";
import DiscordPanel from "../../components/discord_panel/DiscordPanel";
import Footer from "../../components/footer/Footer";
import { getHashtagDrops } from "../../utils/apis/public";
import ReactCopyCode from "../../components/copycode/ReactCopyCode";
//CSS
import "./HashtagPlayer.css";

//about
import about from "../../assets/about/about.json";

//
const hashtag_cover_placeholder =
  "https://cdn.songdrop.cloud/docs/hashtag_cover_img.png";

//FA
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//HashtagPlayer class
function HashtagPlayer(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");
  //PROPS
  const [hashtag, setHashtag] = useState("");
  const [hashtag_cover, setHashtagCover] = useState(hashtag_cover_placeholder);
  const [other_hashtags, setOtherHashtags] = useState([]);
  const [drops, setDrops] = useState([]);
  //LOAD MORE
  const [download_qr_visible, setDownloadQRVisible] = useState(false);
  const [is_other_hastags_visible, setOtherHashtagsVisible] = useState(false);
  //viewing_drop
  const [dropToListen, setSelecteddropToListen] = useState(null);
  //streamurl to play audio source
  const [streamUrl, setStreamUrl] = useState("");
  //
  const [isCodeOpen, setIsCopeOpen] = useState(false);

  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "# | SongDrop";
    //get path, remove extra stuff it has
    var hashtag = window.location.href.split("/")[4].split("?")[0];
    setHashtag(hashtag);
    //CALL_API
    _getHashtag(hashtag);
  }, []);

  //API_CALL
  async function _getHashtag(hashtag) {
    setApiWorking(true);
    setApiError("");
    props.setActiveHashtag(hashtag);
    document.title = `#${hashtag} | SongDrop`;
    //
    try {
      let result = await getHashtagDrops(hashtag);
      //✅SUCCESS
      console.log(result);
      let hashtagResult = result["hashtag"];
      setHashtagCover(hashtagResult["hashtag_cover_url"]);
      setOtherHashtags(hashtagResult["other_hashtags"]);
      setDrops(hashtagResult["drops"]);

      setApiWorking(false);
      //❌Error: Validation falied
    } catch (error) {
      console.log("Hashtagplayer_error", error.message);
      setApiWorking(false);
      // setApiError(error.message);
    }
  }

  function closeQRView() {
    setDownloadQRVisible(false);
  }
  //VIEW
  return (
    <div className="hashtag_web_content">
      {download_qr_visible && (
        <DownloadQR
          qr_code_url={window.location.href}
          qr_text={`#${hashtag}`}
          closeQRView={closeQRView}
        />
      )}
      {isCodeOpen && (
        <ReactCopyCode
          parameters={`#=${hashtag}&theme=dark`}
          closeCopy={() => setIsCopeOpen(false)}
        />
      )}
      <div className="h_content">
        <div className="hashtag_banner">
          <button
            className="share_embed_code"
            onClick={() => setIsCopeOpen(!isCodeOpen)}
          >
            <i className="fa fa-code" />
          </button>
          <img className="cover_img" src={hashtag_cover} alt="" />
          <div className="hashtag-container">
            <div className="hashtag" title={`#${hashtag}`}>
              #{hashtag}
            </div>
            <button
              className="hashtag-qr-button"
              onClick={() => setDownloadQRVisible(!download_qr_visible)}
              title="Print QR code"
            >
              <i className="fa fa-qrcode"></i>
            </button>
          </div>
        </div>
        <div
          title="Try more Drops!"
          className="more_hashtags_btn"
          onClick={() => setOtherHashtagsVisible(!is_other_hastags_visible)}
        >
          {`(${drops.length})Drops with this hashtag `}
          <i
            className={`fa fa-chevron-${
              is_other_hastags_visible ? "up" : "down"
            }`}
          />
        </div>
        {is_other_hastags_visible && (
          <div className="o_hashtag_container">
            {other_hashtags.map((hashtag, index) => {
              return (
                <a
                  key={index}
                  title={hashtag}
                  href={`/hashtag/${hashtag.replace(/^#/, "")}`}
                  // onClick={() => openWikiForGenre(genre)}
                  className="o_hashtag"
                >
                  {hashtag}
                </a>
              );
            })}
          </div>
        )}
      </div>
      {/* API_WORKING */}
      {isApiWorking && (
        <button disabled={true} className="h_api_loading">
          <i className={apiFaIcon}></i>
        </button>
      )}
      {/* API_ERROR_WARNING */}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="empty_drops">
          <strong>{`#${apiError}`}</strong>
        </div>
      )}
      {/* NO_DROPS_WITH_HASHTAGS */}
      {!isApiWorking && isEmpty(drops) && (
        <div className="empty_drops">
          {`♫No Drops shared with `}
          <strong>{`#${hashtag}`}</strong>
        </div>
      )}
      {/* ACTIVE_DROPS_WITH_HASHTAG */}
      {!isApiWorking && !isEmpty(drops) && (
        <div className="list_of_drops">
          {drops.map((drop, index) => {
            return (
              <div key={index}>
                <HashtagDropCell
                  key={index}
                  drop={drop}
                  didSelectDrop={didSelectDrop}
                  dropToListen={dropToListen}
                />
                {!isEmpty(dropToListen) &&
                  dropToListen.drop_id === drop.drop_id && (
                    <div>
                      <ListenDrop
                        dropToListen={dropToListen}
                        streamUrl={streamUrl}
                        setSelecteddropToListen={setSelecteddropToListen}
                      />
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      )}
      <div className="download_app_text">
        Songs with <strong>{`#${hashtag}`}</strong> hashtag shared by other
        users{"\n"}
        <a className="down_a_a" href={"/mobileapp"} title="Download For Mobile">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_iconCarrier">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.163 2.819C9 3.139 9 3.559 9 4.4V11H7.803c-.883 0-1.325 0-1.534.176a.75.75 0 0 0-.266.62c.017.274.322.593.931 1.232l4.198 4.401c.302.318.453.476.63.535a.749.749 0 0 0 .476 0c.177-.059.328-.217.63-.535l4.198-4.4c.61-.64.914-.96.93-1.233a.75.75 0 0 0-.265-.62C17.522 11 17.081 11 16.197 11H15V4.4c0-.84 0-1.26-.164-1.581a1.5 1.5 0 0 0-.655-.656C13.861 2 13.441 2 12.6 2h-1.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656zM5 21a1 1 0 0 0 1 1h12a1 1 0 1 0 0-2H6a1 1 0 0 0-1 1z"
                fill="#ffffff"
              ></path>
            </g>
          </svg>
          Download
        </a>
        {` our app to listen the full songs as Drops\n\n`}
        {`SongDrop is available in these `}
        <a href={"/countries"}>{"countries."}</a>
      </div>
      <DiscordPanel />
      <br />
      <Footer />
    </div>
  );

  //SELECT: Drop
  function didSelectDrop(drop) {
    setSelecteddropToListen(drop);
    setStreamUrl(drop.song.prev_url);
    var element = document.getElementById("list_of_drops");
    setTimeout(function () {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }, 10);
  }
}

export default HashtagPlayer;
