import React, { useState, useEffect } from "react";
import { URLParser } from "./components/URLParser";
import useLocalStorage from "./components/useLocalStorage";
//CSS
import "./App.css";

import AppNavBar from "./AppNavBar";
import AppShareTopBar from "./AppShareTopBar";
import AppRoutes from "./AppRoutes";
import AppTabBar from "./AppTabBar";
import WebsitePopup from "./WebsitePopup";
import LaptopDetector from "./LaptopDetector";

//

const _icon = "https://cdn.songdrop.cloud/docs/%5Blogo-black%5D.png";
const _domain = "https://songdrop.uk";
const _title = "Independent Music | SONGDROP";
//
//APP class
function App() {
  //heights
  const shareBarHeight = 28;
  const navBarHeight = 42;
  const tabBarHeight = 50;

  //hashtag
  const [active_hashtag, setActiveHashtag] = useState(null);
  //
  const [noNavBar, setNoNavBar] = useState(false);
  const [noTabBar, setNoTabBar] = useState(false);
  const [shareAppBarVisible, setShareAppBarVisible] = useLocalStorage(
    "share_app_top_visible",
    false
  );

  // const [shareAppBarVisible, setShareAppBarVisible] = useState(false);
  const [websitePopActive, setWebsitePopActive] = useState(false);
  const [web_title, setWebTitle] = useState(_title);
  const [web_icon, setWebIcon] = useState(_icon);
  const [web_domain, setWebDomain] = useState(_domain);

  //ViewDidLoad
  useEffect(() => {
    //title
    let url_paths = URLParser();
    //no navbar
    var searchText = url_paths["no_click"] ?? "0";
    if (searchText === "1") {
      setNoNavBar(true);
      setNoTabBar(true);
      setShareAppBarVisible(false);
    } else {
      loadBannerWithOffset(5000);
    }
    //hashtag if active
    if (window.location.pathname.includes("/hashtag/")) {
      const hashtagIndex = window.location.pathname.lastIndexOf("/");
      const hashtag = window.location.pathname.substring(hashtagIndex + 1);
      // console.log("hashtag:", hashtag);
      setActiveHashtag(hashtag);
    }
    //@ hide share automatically
    if (window.location.pathname.includes("@")) {
      hideShareMsg();
    }
  }, []);

  function hideShareMsg() {
    setShareAppBarVisible(false);
  }
  function showWebsiteLinkShare() {
    setWebsitePopActive(true);
  }
  function hideWebsiteLinkShare() {
    setWebsitePopActive(false);
  }
  function shareSongDrop() {
    setWebsitePopActive(true);
  }
  function shareMyProfile(to_share_user) {
    setWebTitle(`We are on SongDrop!|\n ${to_share_user.title} `);
    setWebIcon(to_share_user.image);
    setWebDomain(to_share_user.domain);
    setWebsitePopActive(true);
  }
  function loadBannerWithOffset(offset) {
    setTimeout(() => {
      // Your code to load the banner goes here
      // setShareAppBarVisible(true);
    }, offset);
  }

  //VIEWS
  return (
    <div className="app-body">
      {websitePopActive && (
        <WebsitePopup
          closePopUp={hideWebsiteLinkShare}
          websiteTitle={web_title}
          websiteDomain={web_domain}
          websiteIcon={web_icon}
        />
      )}
      {!noNavBar && (
        <div className="app-navbar">
          <AppNavBar />
        </div>
      )}
      <div className="app-share">
        {shareAppBarVisible && (
          <AppShareTopBar
            hideShareMsg={hideShareMsg}
            showWebsiteLinkShare={showWebsiteLinkShare}
          />
        )}
      </div>
      <div
        className="app-content"
        style={{
          height: noNavBar
            ? "100%"
            : `calc(100% - ${navBarHeight}px - ${tabBarHeight}px - ${
                shareAppBarVisible ? `${shareBarHeight}` : 0
              }px)`,
        }}
      >
        <AppRoutes
          setActiveHashtag={setActiveHashtag}
          shareMyProfile={shareMyProfile}
        />
      </div>
      <div>
        <LaptopDetector />
      </div>
      <div className="app-tabbar">
        {!noTabBar && <AppTabBar shareSongDrop={shareSongDrop} />}
      </div>
    </div>
  );
}

export default App;
