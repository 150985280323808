import React, { useState, useEffect } from "react";
import isEmpty from "../../components/isEmpty";
import Zoom from "react-medium-image-zoom";
//API
import { getNewsAndUsers } from "../../utils/apis/public";
//JS
import Footer from "../../components/footer/Footer";
import Newsletter from "./emailsubscription/Newsletter";
import FaqLanding from "./faq_landing/FaqLanding";
import Socials from "../../components/socials/socials";
import HashtagPlaylist from "./HashtagPlaylists";
import NewArtists from "./NewArtists";
import NewReleases from "./NewReleases";
import DashSearchBar from "./DashSearchBar";
import DashLoader from "./DashLoader";
import ProfileSearch from "../@/ProfileSearch";
import VideosLoaderBig from "./VideosLoaderBig";
//CSS
import "./Dashboard3.css";

//assets
import appstoreIcon from "../../assets/img/appstore-logo.png";
import about from "../../assets/about/about.json";
import bandlogo from "../../assets/img/band-logo.png";
import songdropios from "../../assets/img/app-image-songdrop-nobg.png";
import distribution_history from "../../assets/img/music_distribution_history.png";

//FAICONS
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

const default_hashtags = [
  "#london",
  "#today",
  "#peace",
  "#love",
  "#songdrop",
  "#today",
  "#fresh",
  "#feelgood",
  "#dancing",
  "#morning",
  "#night",
  "#moody",
];

//Dashboard class
function Dashboard() {
  //
  //index
  const [accIndexActive, setAccIndexActive] = useState(0);
  const [selected_index, setSelectedIndex] = useState(-99);
  const [selected_date, setSelectedDate] = useState("");

  const [isApiWorking, setApiWorking] = useState(true);
  const [new_hashtags, setNewHashtags] = useState(default_hashtags);
  const [new_artists, setNewArtists] = useState([]);
  const [new_releases, setNewReleases] = useState([]);
  const [widgetURL, setWidgetURL] = useState(
    "https://widget.songdrop.uk/?u=songdropofficial&theme=light"
  );
  //SEARCH
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [user, setUser] = useState(null);
  const [isSearchApiWorking, setSearchApiWorking] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "SongDrop | Independent Music";
    //
    //API
    getNews();
  }, []);

  //api
  async function getNews() {
    setApiWorking(true);
    try {
      //✅SUCCESS
      let result = await getNewsAndUsers();
      setTimeout(() => {
        updatePage(result);
        setApiWorking(false);
      }, 3000);

      //🚫ERROR
    } catch (error) {
      console.log(error.message);
      setApiWorking(false);
    }

    //
  }

  function updatePage(result) {
    let r_artists = result["artists"] || [];
    let r_releases = result["releases"] || [];
    let r_hashtags = result["hashtags"] || default_hashtags;
    let r_widget = result["widget"] || widgetURL;
    setWidgetURL(r_widget);
    setNewArtists(r_artists);
    setNewReleases(r_releases);
    setNewHashtags(r_hashtags);
  }

  //UPDATE: SearchBar text
  function textDidChange(searchtext) {
    setSearchText(searchtext);
  }

  //SEARCH
  const searchForText = () => {
    setApiWorking(true);
    setIsSearching(true);
  };

  //Toggle
  const togglePopup = () => {
    setApiWorking(false);
    setIsSearching(false);
  };

  //VIEW
  return (
    <div className="dash_body">
      {isSearching && (
        <ProfileSearch searchText={searchText} handleClose={togglePopup} />
      )}
      <DashSearchBar
        isApiWorking={isSearchApiWorking}
        searchText={searchText}
        searchForText={searchForText}
        textDidChange={textDidChange}
      />
      <iframe
        src={widgetURL}
        width="100%"
        height="250px"
        title="SONGDROP WIDGET"
      ></iframe>
      <HashtagPlaylist hashtags={new_hashtags} />
      {/* <AppDownload /> */}
      {isApiWorking && <DashLoader loading={isApiWorking} />}
      {!isEmpty(new_releases) && <NewReleases new_releases={new_releases} />}
      {!isEmpty(new_artists) && <NewArtists new_artists={new_artists} />}

      <div className="body1">
        <div className="benefits">
          <div className="title">What's new?</div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-retweet" />
            </div>
            <div className="right">
              <div className="title">Drops 24/7 </div>
              <div>Share songs with your followers</div>
            </div>
          </div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-hand-lizard-o" />
            </div>
            <div className="right">
              <div className="title">Electronically Distributed(ED) </div>
              <div>Hold your digital album in your hand</div>
            </div>
          </div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-check-square" />
            </div>
            <div className="right">
              <div className="title">No subscription </div>
              <div>Only pay for the music you want</div>
            </div>
          </div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-download" />
            </div>
            <div className="right">
              <div className="title">Download your music </div>
              <div>Own the music, download the music</div>
            </div>
          </div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-rotate-left" />
            </div>
            <div className="right">
              <div className="title">Music History </div>
              <div>Reverse your purchased list anytime</div>
            </div>
          </div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-image" />
            </div>
            <div className="right">
              <div className="title">Digital Leaflet </div>
              <div>Get exclusive media of your album</div>
            </div>
          </div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-puzzle-piece" />
            </div>
            <div className="right">
              <div className="title">Puzzle </div>
              <div>Turn your albums into games</div>
            </div>
          </div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-microphone" />
            </div>
            <div className="right">
              <div className="title">Radio!</div>
              <div>Worldwide live broadcasts</div>
            </div>
          </div>
          <div className="ben">
            <div className="left">
              <i className="fa fa-text-width" />
            </div>
            <div className="right">
              <div className="title">Synced Lyrics</div>
              <div>Sing along with the music</div>
            </div>
          </div>
        </div>
        <div className="what-is-songdrop">
          <div className="left">
            <div className="title">Download App</div>
            <div className="subtitle" style={{ marginBottom: "0px" }}>
              <i className="fa fa-mobile" /> Download for iOS (🇬🇧United Kingdom
              AppStore)
            </div>
            <div className="subtitle">
              <strong>
                <a href="https://en.m.wikipedia.org/wiki/Independent_music">
                  Independent music.
                </a>{" "}
              </strong>
              <strong> No subscriptions, just great tunes. </strong>
              <div>
                <ul>
                  <li>Seamless music purchase on smartphones</li>
                  <li>Flexible pricing for artists</li>
                  <li>Metadata-enriched digital collections</li>
                  <li>(ED)Electronically Distributed built-in scanner</li>
                </ul>
                <div className="store-buttons">
                  <a
                    href={about.appstore_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/0/0f/Available_on_the_App_Store_%28black%29_SVG.svg"
                      alt="App Store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://blamoramagames.com/wp-content/uploads/2023/03/GooglePlay.png"
                      alt="Google Play Store"
                    />
                  </a>
                </div>
                Join the community and find your new favorite artists today!
              </div>
            </div>
          </div>
          <label className="right">
            <div className="phone-wrapper">
              <img className="logo" src={about.logo_white_url} alt="" />
            </div>
            <span className="_text2">
              <strong> v{about.ios_version}</strong> is out on the AppStore{" "}
            </span>
            <a className="_text3" href="/updates?platform=ios">
              Read more{" "}
            </a>
          </label>
        </div>
        <div></div>
        <div className="songdrop-band">
          <div className="left">
            <div className="title">Upload Your Music</div>
            <div className="subtitle">
              <i className="fa fa-music" /> Sign In as Artist(🌐Available
              online)
              <ul>
                <li>Fair revenue share for single songs or full albums.</li>
                <li>Social music promotion with Drops.</li>
                <li>Free artist account </li>
                <li>Monthly payout - if you sell songs</li>
                <li>
                  Cost effective physical distribution with ED(the new CD)
                </li>
              </ul>
              <a
                className="action_btn"
                href={about.band_url}
                alt=""
                title={about.band_url}
              >
                Visit Band Website <i className="fa fa-chevron-circle-right" />
              </a>{" "}
              <div>
                <br />
                Join SongDrop today and start building your music career!
                <div>
                  <strong>
                    Promote and sell your music on{" "}
                    <a href={about.band_url} alt="">
                      songdrop.band
                    </a>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <img className="logo" src={bandlogo} alt="" />
            <div style={{ fontSize: "12px", marginTop: "4px" }}>
              <i className="fa fa-calendar" /> 2023-01-07
            </div>
            <div>
              <strong>v{about.band_version}</strong> is online
            </div>
            <a href="/updates?platform=band">Read more </a>
          </div>
        </div>
        <div className="what-is-ed">
          <div className="content">
            <div className="left">
              <div className="title">Official Music Videos</div>
              <div className="subtitle">
                <i className="fa fa-film" /> OMV for Independent Artists
              </div>
              <div className="subtitle">
                Add video links to your album releases to unlock the full
                potential of your music on SongDrop. Only Official Music Videos
                (OMVs) are accepted, ensuring top-notch quality. Once published,
                these videos seamlessly appear as embedded content on our
                website, complementing your albums and driving engagement.
              </div>
              <a
                className="action_btn"
                href={"https://songdrop.uk/omv"}
                alt=""
                title={"Official Music Videos"}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-film" /> OMVs on SongDrop{" "}
              </a>
            </div>
            <div className="right">
              <VideosLoaderBig />
            </div>
          </div>
        </div>
        <div className="what-is-ed">
          <div className="content">
            <div className="left">
              <div className="title"> Physical Music Distribution</div>
              <div className="subtitle">
                Introducing - Electronically Distributed(ED)
                <div className="download_now">
                  <img src={distribution_history} alt="" />
                </div>
              </div>
              <div className="subtitle">
                Experience the best of both worlds with an Electronically
                Distributed(ED) album. Get a physical print with a QR code that
                instantly unlocks the digital album for download to your device.
                Enjoy the traditional album holding experience with the
                convenience of modern digital listening. Get your ED album
                today!
              </div>
              <a
                className="action_btn"
                href={about.edshop_url}
                alt=""
                title={about.edshop_url}
              >
                <i className="fa fa-qrcode" /> Get your first EDs!{" "}
              </a>
            </div>
            <div className="right">
              <Zoom>
                <img
                  className="ed_print"
                  src={
                    "https://cdn.songdrop.cloud/docs/history_of_distribution/ed_first_print_2022.jpg"
                  }
                  alt=""
                />
              </Zoom>
            </div>
          </div>
        </div>
        <div className="faq">
          <div className="title">
            <i className="fa fa-info-circle" /> Frequently Asked Questions
          </div>
          <FaqLanding />
          <div className="readmore">Have more questions?</div>
          <a href="/faq">Visit our FAQ</a>
        </div>
        <br />
        <Newsletter />
        {/* <DiscordPanel /> */}
        <Socials />
        <Footer />
      </div>
    </div>
  );
}

export default Dashboard;
