import React from "react";
import "./TutorialsHeader.css";

const TutorialsHeader = (props) => (
  <div>
    <div className="home-header">
      <div className="left">
        <i className="fa fa-info-circle" />
        Hub
      </div>
      <div className="right">
        <a href="/s/songdropofficial" target="_blank">
          <i className="fa fa-bell" />
          SUBSCRIBE
        </a>
      </div>
    </div>
    <div className="home-subtitle">
      Watch tutorial videos how to use our platform.
    </div>
  </div>
);

export default TutorialsHeader;
