function isEmpty(e) {
  switch (e) {
    case undefined:
    case "":
    case 0:
    case "0":
    case null:
    case false:
      return true;
    default:
      let array = Array.isArray(e);
      // console.log("isarray", array, e.length);
      if (array && e.length === 0) {
        return true;
      }
      return false;
  }
}
export default isEmpty;
