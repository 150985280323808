import React from "react";
import QRCode from "react-qr-code";
import JustCountries from "../../components/countriesavailable/JustCounties";
import Zoom from "react-medium-image-zoom";
import about from "../../assets/about/about.json";
//CSS
import "./DownloadApp.css";

//Dash class
function DownloadRadio() {
  //VIEW
  return (
    <div className="download-app">
      <div className="country">{/* <JustCountries /> */}</div>
      <div className="download">
        <a
          rel={"https://songdrop.uk"}
          href={about.radio_app_url}
          className="appstore"
          title={"Download Radio! iOS App"}
        >
          <i className="fa fa-apple" />
          <p className="app_text">Available on the</p>
          <p className="app_store">App Store</p>
        </a>
        <Zoom>
          <div className="qr-code">
            <QRCode id="QRCode" value={about.radio_app_url} size={100} />
            <div className="scan">
              <p>
                <i className="fa fa-apple" /> iOS {about.radio_ios_version}
              </p>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
}

export default DownloadRadio;
