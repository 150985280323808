import React, { useState, useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import CopyToClipboard from "react-copy-to-clipboard";
//CSS
import "./ProfileHeader.css";
import isEmpty from "../../components/isEmpty";
import placeholder from "../../assets/img/ic_placeholder_profile.png";
//ProfileHeader class
function ProfileHeader({ user, shareMyProfile, shareCode, setApiError }) {
  //share
  const [shareActive, setShareActive] = useState(false);

  function shareSongDropProfile() {
    setShareActive(true);
    const to_share_user = {
      title: `${user.name}`,
      domain: `https://songdrop.uk/@/${user.username}`,
      image: user.profile_img_url,
      bgImg: user.bcg_url,
    };
    shareMyProfile(to_share_user);
  }
  // Function to check URL parser ending
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!isEmpty(user) && urlParams.has("share")) {
      shareSongDropProfile();
    }
  }, [user]);

  function _shareCode() {
    shareCode();
  }

  function followClicked() {
    const confirmation = window.confirm(
      `Download our app to follow @${user.username}.`
    );
    if (confirmation) {
      window.location.href = "/mobileapp";
    }
  }
  //VIEW
  return (
    <div>
      {shareActive && <div className="profile_share_enabled">Link copied.</div>}
      <div
        className="profile_header"
        style={{ backgroundImage: `url(${user.bcg_url})` }}
      >
        <div className="profile_image">
          <Zoom>
            <img
              id="p-image"
              alt=""
              className="p-image"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: user.usertype === 1 ? "2px" : "30px",
              }}
              src={
                isEmpty(user.profile_img_url)
                  ? placeholder
                  : user.profile_img_url
              }
            />
          </Zoom>
          <div
            className="follow-me-frame"
            title={
              isEmpty(user.username) ? "Follow" : `Follow @${user.username}`
            }
          >
            <div className="arrow"></div>
            <button onClick={() => followClicked()}>
              <i className="fa fa-user" />
              <span>1</span>
            </button>
          </div>
        </div>
        <div className="profile_username">
          <h1>{user.name}</h1>
          <h2>@{user.username}</h2>
          <h3>{user.location}</h3>
        </div>
        <div className="profile_share">
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => shareSongDropProfile()}
          >
            <i className="fa fa-share-square" />
          </CopyToClipboard>
          {user.usertype === 1 && (
            <a href={`/s/${user.username}`}>
              <i className="fa fa-bell" />
            </a>
          )}
          <a onClick={() => _shareCode()}>
            <i className="fa fa-code" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
