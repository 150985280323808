import isEmpty from "./isEmpty";

//Open WikiPedia for genre
export default function openWikiForGenre(genre) {
  //link to search
  let wikilink = `https://en.wikipedia.org/api/rest_v1/page/summary/${genre.toLowerCase()}`;
  // var new_tab = window.open(wikilink, "_blank");

  //start searching wiki related music articles
  fetch(wikilink + "_music")
    .then((res) => res.json())
    .then((rows) => {
      if (!isEmpty(rows["content_urls"])) {
        if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
          let url = rows["content_urls"].mobile.page;
          // new_tab.location.href=url; //new tab: notes-it has a back button: multiple songdrop window warning
          window.location.href = url; //same tab
        } else {
          let url = rows["content_urls"].desktop.page;
          // window.location.href = url; //new tab: notes-it has a back button: multiple songdrop window warning
          // window.location.href = url; //same tab
          window.open(url, "_blank");
        }
      } else {
        //no result for music
        fetch(wikilink + "_genres")
          .then((res) => res.json())
          .then((rows) => {
            if (!isEmpty(rows["content_urls"])) {
              if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
                let url = rows["content_urls"].mobile.page;
                // new_tab.location.href=url; //new tab: notes-it has a back button: multiple songdrop window warning
                window.location.href = url; //same tab
              } else {
                let url = rows["content_urls"].desktop.page;
                // window.location.href = url; //new tab: notes-it has a back button: multiple songdrop window warning
                window.open(url, "_blank");
              }
            } else {
              //no result for genres
              fetch(wikilink)
                .then((res) => res.json())
                .then((rows) => {
                  if (!isEmpty(rows["content_urls"])) {
                    if (navigator.userAgent.toLowerCase().match(/mobile/i)) {
                      let url = rows["content_urls"].mobile.page;
                      // new_tab.location.href=url; //new tab: notes-it has a back button: multiple songdrop window warning
                      window.location.href = url; //same tab
                    } else {
                      let url = rows["content_urls"].desktop.page;
                      // window.location.href = url; //new tab: notes-it has a back button: multiple songdrop window warning
                      window.open(url, "_blank");
                    }
                  } else {
                    //no result
                  }
                });
            }
          });
      }
    });
}
