import React, { useEffect, useState } from "react";

//img
import brandlogo from "../../assets/img/songdrop-logo-black.png";
//css
import "./Charts.css";
import about from "../../assets/about/about.json";
import Footer from "../../components/footer/Footer";

//fa icons
const title = "Top Charts";
const info = "Coming soon...";
const errorIcon = "fa fa-chain-broken";

//Charts class
function Charts(props) {
  //
  const [_title, setTitle] = useState(title);
  const [_info, setInfo] = useState(info);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Charts | SongDrop.uk";
  }, []);

  //VIEW
  return (
    <div className="charts-container">
      <div className="body">
        <div className="top">
          <img
            className="logo"
            alt=""
            src={brandlogo}
            onClick={goToLoginPage}
          />
          <a
            title="What is SongDrop?"
            className="info"
            href={about.pr_url}
            target="_blank"
          />
        </div>
        <div className="header">
          <div className="welcome">
            <p>{_title}</p>
          </div>
        </div>
        <div className="content">
          <p>{_info}</p>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/";
  }
}

export default Charts;
