import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import useLocalStorage from "./components/useLocalStorage";
import { saveAs } from "file-saver";
import { generateHashtags, randomPopGenerator } from "./WebsiteRandomGenerator";
import {
  ShareWebsiteBox,
  AspectRatioSettings,
  BackgroundSettings,
  FontSettings,
} from "./WebsitePopupSettings";
//CSS
import "./WebsitePopup.css";

const _icon = "https://cdn.songdrop.cloud/docs/%5Blogo-black%5D.png";
const _domain = "https://songdrop.uk";
const _title = "Independent Music | SONGDROP";
const _firsthashtags = "#songdrop #london #socialmusic";
const _maximumhashags_number = 5;
//
//WebsitePopup
function WebsitePopup({
  closePopUp,
  websiteTitle,
  websiteDomain,
  websiteIcon,
}) {
  //
  const [websiteData, setWebsiteData] = useState(null);
  //
  const [activeTab, setActiveTab] = useState(""); // Default active tab
  //
  const [aspectRatio, handleChangeAspectRatio] = useState("1:1");
  const [backgroundColor, setBackgroundColor] = useLocalStorage(
    "wp-bcg",
    "orange"
  );
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [font, setFont] = useLocalStorage("wp-ft", "Arial");
  const [fontColor, setFontColor] = useLocalStorage("wp-ftCol", "#ffffff");
  const [fontSize, setFontSize] = useLocalStorage("wp-ftSize", 20);

  const [web_title, setWebTitle] = useState(websiteTitle || _title);
  const [web_icon, setWebIcon] = useState(websiteIcon || _icon);
  const [web_domain, setWebDomain] = useState(websiteDomain || _domain);

  useEffect(() => {
    setWebsiteData({
      icon: web_icon,
      title: web_title,
      domain: web_domain,
    });
  }, [web_title, web_icon, web_domain]);

  const alertHandleExport = () => {
    const confirmExport = window.confirm(
      "Do you want to export this image to share?"
    );
    if (confirmExport) {
      handleExportImage();
    }
  };

  const handleExportImage = async () => {
    const container = document.getElementById("website-to-story-content");
    const imageElement = container.querySelector(".website-image");

    // Fetch the image
    const response = await fetch(imageElement.src);
    const blob = await response.blob();

    // Create a URL for the Blob
    const imageUrl = URL.createObjectURL(blob);

    // Create image using html2canvas
    const canvas = await html2canvas(container, {
      useCORS: true,
      scale: 2,
      proxy: imageUrl,
    });
    const imageData = canvas.toDataURL("image/jpeg");

    // Save the image
    saveAs(imageData, "story-image.jpeg");

    // Revoke the URL to release resources
    URL.revokeObjectURL(imageUrl);
  };

  const handleClose = () => {
    closePopUp();
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  function randomGenerator() {
    const icon = web_icon;
    const domain = web_domain;
    let random = randomPopGenerator({ icon, domain, fontSize });
    // console.log(random.font);
    setWebsiteData({
      icon: random.icon,
      title: random.title,
      domain: random.domain,
    });
    setBackgroundColor(random.bgColor);
    setBackgroundImage(random.bgImg);
    setFontSize(random.fontSize);
    setFontColor(random.fontColor);
    setFont(random.font);
  }

  const handleLinkClick = () => {
    // Copy current URL to clipboard
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        // Show "Link copied" message
        setShowCopyMessage("Link copied");
        // Hide the message after a few seconds
        setTimeout(() => setShowCopyMessage(null), 3000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  const handleHashtagClick = () => {
    // Copy current URL to clipboard
    let hashtags = generateHashtags(_maximumhashags_number, _firsthashtags);
    navigator.clipboard
      .writeText(hashtags)
      .then(() => {
        // Show "Hashtags generated" message
        setShowCopyMessage("Hashtags generated");
        // Hide the message after a few seconds
        setTimeout(() => setShowCopyMessage(null), 3000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  return (
    <div id="website-popup-container" className="website-popup-container">
      {showCopyMessage && (
        <span className="link-copied-message">{showCopyMessage}</span>
      )}
      <div className="website-to-story-navbar">
        <button onClick={handleClose}>
          <i className="fa fa-close"></i>
        </button>
        <h1 onClick={handleHashtagClick}>
          <i className="fa fa-hashtag"></i>Share story
        </h1>
        <button onClick={alertHandleExport}>
          <i className="fa fa-share-square"></i>
        </button>
      </div>
      <div className="website-to-story-content">
        <ShareWebsiteBox
          id="website-to-story-content"
          websiteData={websiteData}
          aspectRatio={aspectRatio}
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          font={font}
          fontColor={fontColor}
          fontSize={fontSize}
        />
      </div>
      <div className="website-to-story-tabbar">
        <button
          className={activeTab === "random" ? "active" : ""}
          onClick={() => randomGenerator()}
        >
          <i className="fa fa-smile-o" />
          <p>Random</p>
        </button>
        <button
          className={activeTab === "sizes" ? "active" : ""}
          onClick={() => handleTabClick("Sizes")}
        >
          <i className="fa fa-file-picture-o" />
          <p>Sizes</p>
        </button>
        <button
          className={activeTab === "font" ? "active" : ""}
          onClick={() => handleTabClick("Font")}
        >
          <i className="fa fa-font" />
          <p>Font</p>
        </button>
        <button
          className={activeTab === "background" ? "active" : ""}
          onClick={() => handleTabClick("Background")}
        >
          <i className="fa fa-square" />
          <p>Background</p>
        </button>
        <button
          className={activeTab === "link" ? "active" : ""}
          onClick={() => handleLinkClick()}
        >
          <i className="fa fa-link" />
          <p>Link</p>
        </button>
      </div>
      <div
        className={`website-to-story-settings-container ${
          activeTab ? " active" : ""
        }`}
      >
        <div className="settings-header">
          <button></button>
          <h2>{activeTab}</h2>
          <button onClick={() => setActiveTab(null)}>
            <i className="fa fa-check"></i>
          </button>
        </div>
        {/* Render setting components based on active tab */}
        {activeTab === "Sizes" && (
          <AspectRatioSettings
            aspectRatio={aspectRatio}
            backgroundColor={backgroundColor}
            handleChangeAspectRatio={handleChangeAspectRatio}
          />
        )}
        {activeTab === "Font" && (
          <FontSettings
            font={font}
            fontColor={fontColor}
            fontSize={fontSize}
            setFont={setFont}
            setFontColor={setFontColor}
            setFontSize={setFontSize}
          />
        )}
        {activeTab === "Background" && (
          <BackgroundSettings
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
            setBackgroundImage={setBackgroundImage}
          />
        )}
      </div>
    </div>
  );
}

export default WebsitePopup;
