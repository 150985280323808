import React, { useState } from "react";
import isEmpty from "../../../components/isEmpty";
import emailvalidator from "email-validator";
//CSS
import "./EmailSubscription.css";
import { signUpToNewsLetterAPI } from "../../../utils/apis/public";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faSuccess = "fa fa-check-circle";
const faMail = "fa fa-envelope";

// EmailSubscription class
function EmailSubscription(props) {
  //api
  const [isApiWorking, setApiWorking] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  //user input
  const [emailAddress, setEmailAddress] = useState("");

  //UPDATE: SearchBar text
  function handleEmailFieldChange(emailAddress) {
    setEmailAddress(emailAddress);
  }

  //SEARCH
  const handleSignUpSubmit = async (event) => {
    event.preventDefault();
    setApiWorking(true);
    //email
    if (isEmpty(emailAddress)) {
      alert("Please enter your email address to sign up for our newsletter.");
      setApiWorking(false);
      return;
    }

    //check valid email address form
    if (!emailvalidator.validate(emailAddress)) {
      alert(
        "Please enter a correct email address to sign up for our newsletter."
      );
      setApiWorking(false);
      return;
    }
    //
    try {
      //bodyparams
      var bodyParams = [];
      bodyParams.push(`email=${emailAddress}`);
      var tag_id = props.tag_id || "";
      bodyParams.push(`tag_id=${tag_id}`);

      if (!isEmpty(props.selectedGenres)) {
        const genresParam =
          props.selectedGenres.length === 1
            ? `genres=${props.selectedGenres[0].toLowerCase()}`
            : `genres=${props.selectedGenres.join(",").toLowerCase()}`;
        bodyParams.push(genresParam);
      }

      if (!isEmpty(props.selectedArtists)) {
        const artistsParam =
          props.selectedArtists.length === 1
            ? `artists=${props.selectedArtists[0].toLowerCase()}`
            : `artists=${props.selectedArtists.join(",").toLowerCase()}`;
        bodyParams.push(artistsParam);
      }

      let bParameters = bodyParams.join("&");
      // console.log(bParameters);
      //✅Success: Form sent
      let result = await signUpToNewsLetterAPI(bParameters);
      alert(result["message"]);
      setApiWorking(false);
      setSuccess(true);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      alert(error.message);
    }
  };

  return (
    <div className="email_subbar">
      <div className="email-container">
        <form className="searchbar" onSubmit={handleSignUpSubmit}>
          <button className="mailButton" type="submit" disabled={true}>
            <i className={faMail}></i>
          </button>
          <input
            placeholder={props.placeholder || "Enter your Email Address"}
            type="text"
            value={emailAddress}
            onChange={(e) =>
              handleEmailFieldChange(e.target.value.toLowerCase())
            }
          />
          {/* SUBMIT SEARCH */}
          {props.isApiWorking === false &&
            isApiWorking === false &&
            isSuccess === false && (
              <button
                className="searchButton"
                type="submit"
                disabled={emailAddress.length === 0}
              >
                Sign Up
              </button>
            )}
          {props.isApiWorking === false &&
            isApiWorking === false &&
            isSuccess === true && (
              <button className="searchButton" type="submit" disabled={true}>
                <i className={faSuccess}></i>
              </button>
            )}
          {/* API_LOADING */}
          {(props.isApiWorking === true || isApiWorking === true) && (
            <button className="searchButton" type="submit" disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
        </form>
      </div>
      {props.isApiWorking === false &&
        isApiWorking === false &&
        isSuccess === true && (
          <button className="_subscribed" type="submit" disabled={true}>
            <i className={faSuccess}></i>You successfully subscribed to our
            newsletter.
          </button>
        )}
      <div>
        {" "}
        By signing up you agree to our{" "}
        <a href="/privacy" alt="">
          Privacy Policy
        </a>
        . You can unsubscribe at any time.
      </div>
    </div>
  );
}

export default EmailSubscription;
