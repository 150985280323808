import React, { useEffect, useState } from "react";
import Moment from "moment";
import toHHMMSS from "../../components/toHHMMSS";
import isEmpty from "../../components/isEmpty";
import AudioSoundPlayer from "./AudioSoundPlayer";
import ReactAudioPlayer from "react-audio-player";
//logo
import sdlogo from "../../assets/img/songdrop-logo-black.png";
//css
import "./AlbumDesign.css";
//json
import countries from "../../assets/json/countries.json";

const windowMargin = 32;

//
//QrDesign
function QrDesign(props) {
  //streamurl to play audio source
  const [streamUrl, setStreamUrl] = useState("");
  //ViewDidLoad
  useEffect(() => {
    //resize waves
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  });

  //VIEW
  return (
    <div className="sdqrmat">
      {/* HEADER */}
      <div className="front-header">
        <div
          id="at_title"
          className="title"
          style={{ fontSize: `${props.fontSize}px` }}
        >
          {props.album.title}
        </div>
        {props.album.explicit === 1 && (
          <div className="explIcon">{<p>🅴</p>}</div>
        )}
      </div>
      <div className="albumart">
        {/* ALBUM_ART */}
        {props.isBack === false && (
          <div>
            {props.corsEnabled === true && (
              <img
                className="cover_img"
                alt=""
                src={returnImage()}
                crossOrigin="anonymous"
              ></img>
            )}
            {props.corsEnabled === false ||
              (props.corsEnabled === undefined && (
                <img
                  className="cover_img"
                  alt=""
                  src={props.album.cover_img_url}
                ></img>
              ))}
          </div>
        )}
        {/* TRACK_LIST */}
        {props.isBack === true && (
          <div
            className="qrcardback"
            style={{ fontSize: `${props.trackFontSize}px` }}
          >
            <div>
              <div className="track_list">
                <div className="width80">Track list:</div>
                <div className="width20">{countAllDuration()}</div>
              </div>
            </div>
            <div>{buildTrackListTable()}</div>
          </div>
        )}
      </div>
      {/* BOTTOM_PART */}
      <div className="front-bottom">
        <div className="leftside">
          {props.isBack && (
            <div>
              <img alt="" src={sdlogo} />
            </div>
          )}
          {!props.isBack && (
            <div className="year">
              <p>Country:{setCountryFlag(props.album.country)}</p>
              <p>
                Year:<strong>{setReleaseYear(props.album.released_at)}</strong>
              </p>
            </div>
          )}
        </div>
        <div className="rightside" style={{ paddingTop: props.fontSize - 4 }}>
          <div
            id="af_title"
            className="title"
            style={{ fontSize: `${props.fontSize}px` }}
          >
            {props.album.title}
          </div>
          <div
            id="af_artist"
            className="artist"
            style={{ fontSize: `${props.fontSize}px` }}
          >
            {props.album.artist}
          </div>
        </div>
      </div>
    </div>
  );

  //CREATE img url path to satisfy html2canvas
  function returnImage() {
    const image = props.album.cover_img_url;
    const timestamp = new Date().getTime();
    const imageWithTimestamp = image.includes("?")
      ? `${image}&v=${timestamp}`
      : `${image}?v=${timestamp}`;
    return imageWithTimestamp;
  }

  //SUM: all track duration on the album
  function countAllDuration() {
    var albumdur = 0;
    var t_count = 0;
    props.album.tracks.forEach((track) => {
      let dur = parseInt(track.duration);
      t_count += 1;
      albumdur += dur;
    });
    return albumdur !== 0 ? toHHMMSS(albumdur) : `${t_count}▶︎`;
  }

  function helloStream(url) {
    setStreamUrl(url);
  }

  //BUILD: track list tabledata
  function buildTrackListTable() {
    var tableData = [];
    if (isEmpty(props.album.tracks)) {
      return tableData;
    }
    props.album.tracks.sort(function (left, right) {
      return left.track_number > right.track_number;
    });
    props.album.tracks.forEach((track, index) => {
      tableData.push(
        <div
          className="track_row"
          key={index}
          onClick={() => helloStream(track.prev_url)}
        >
          <div className="width80">
            {`${addZeroUnder10(track.track_number)}. ${track.title}`}
            {!isEmpty(streamUrl) && streamUrl === track.prev_url && (
              <div className="mp3Player">
                <p className="prev">Preview</p>
                <ReactAudioPlayer
                  src={streamUrl}
                  autoPlay
                  controls
                  style={{
                    width: "100%",
                    color: "#fff",
                  }}
                />
              </div>
            )}
          </div>
          {track.duration === "0" && (
            <div className="width20">
              <strong>{"LIVE"}</strong>
            </div>
          )}
          {track.duration !== "0" && (
            <div className="width20">{toHHMMSS(track.duration)}</div>
          )}
        </div>
      );
    });
    return tableData;
  }

  //Country flag
  function setCountryFlag(country) {
    let flag = "";
    countries.forEach((_country) => {
      if (_country.sortname === country) {
        flag = _country.emoji;
      }
    });
    return flag;
  }
  //Release year
  function setReleaseYear(releaseDate) {
    if (isEmpty(releaseDate)) {
      return "";
    }
    return Moment(new Date(releaseDate)).format("yyyy");
  }

  //DESIGN: 1->01, 2->02, 10->10
  function addZeroUnder10(number) {
    return number < 10 ? `0${number}` : number;
  }

  //UPDATE::Window - Update ContentWidth
  function updateWindowDimensions() {
    const _containerWidth = window.innerWidth - windowMargin;
    //resize_text
    // resizeText("at_title", _containerWidth * 0.6);
    // resizeText("af_title", _containerWidth * 0.6);
    // resizeText("af_artist", _containerWidth * 0.6);
    // console.log("asds");
  }

  function escapeStreamData(data) {}

  function resizeText(type, boxWidth) {
    let titleElement = document.getElementById(type);
    var currentWidth = (
      titleElement.textContent ||
      titleElement.innerText ||
      titleElement.innerHTML
    ).length;
    let divWidth = boxWidth;
    // console.log(currentWidth, boxWidth);
    // document.getElementById(type).style.fontSize = `${
    //   (currentWidth / divWidth) * 40
    // }px`;
    document.getElementById(type).style.fontSize = `${props.fontSize}px`;
  }
}

export default QrDesign;
