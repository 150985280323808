import React, { useState, useEffect } from "react";
import ReactPdf from "./ReactPdf";
import { URLParser } from "../../components/URLParser";

//JS
import Footer from "../../components/footer/Footer";
//CSS
import "./Legal.css";
import about from "../../assets/about/about.json";

//RadioPrivacyPolicy class
function RadioPrivacyPolicy() {
  var [noclick, setNoClick] = useState("0");

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Privacy Policy | SongDrop";
    //url paths
    let url_paths = URLParser();
    setNoClick(url_paths["no_click"] ?? "0");
  }, []);

  //VIEW
  return (
    <div className="legalbody">
      <div className="header">
        <div className="title">RADIO! PRIVACY POLICY</div>
      </div>
      <div className="content">
        <ReactPdf url={about.radio_privacy_pdf} />
      </div>
      {noclick === "0" && <Footer />}
    </div>
  );
}

export default RadioPrivacyPolicy;
