import React, { Component } from "react";
import PropTypes from "prop-types";
import { withCustomAudio } from "react-soundplayer/addons";
import { PlayButton, Timer, Progress } from "react-soundplayer/components";

//CSS
import "./AudioSoundPlayer.css";

class BasicSoundPlayer extends Component {
  //
  //Escape progress
  componentDidUpdate() {
    const { currentTime, duration } = this.props;
    let streamData = { progress: currentTime || 0, duration: duration || 0 };
    {
      this.props.escapeStreamData(streamData);
    }
  }

  render() {
    const { soundCloudAudio, track, currentTime, duration, autoplay } =
      this.props;
    if (track && autoplay) {
      soundCloudAudio.play();
    }
    return (
      <div className="audio-sound-player">
        <div className="play-wrapper">
          <PlayButton className="playbutton" {...this.props} />
        </div>
        <div className="progress-wrapper">
          <Progress
            className="progress-sound"
            innerClassName="progress-sound-inner"
            value={(currentTime / duration) * 100 || 0}
            {...this.props}
          />
        </div>
        <div className="timer-wrapper">
          <Timer duration={track ? track.duration / 1000 : 0} {...this.props} />
        </div>
      </div>
    );
  }
}

BasicSoundPlayer.propTypes = {
  resolveUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
};

export default withCustomAudio(BasicSoundPlayer);
