//Save file
function SaveDataURL(dataURL, filename) {
  var link = document.createElement("a");
  if (typeof link.download === "string") {
    link.href = dataURL;
    link.download = filename;
    //Firefox requires the link to be in the body
    document.body.appendChild(link);
    //simulate click
    link.click();
    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(dataURL);
  }
}

export default SaveDataURL;
