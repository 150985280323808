import React, { useState } from "react";

//CSS
import "./HashtagPlaylists.css";

//HashtagPlaylist
const HashtagPlaylist = (props) => {
  const [showAll, setShowAll] = useState(false);
  const hashtagsToShowInitially = 4; // Change this value to adjust the number of hashtags shown initially

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="hashtag-wrapper">
      {showAll && (
        <div className="dropsong">
          <i className="fa fa-info-circle" aria-hidden="true"></i> You can add
          up to 3 hashtags to each of your song drops, when you share music with
          others.
        </div>
      )}
      <div className="hashtaglist">
        {showAll
          ? tableData(props.hashtags)
          : tableData(props.hashtags.slice(0, hashtagsToShowInitially))}
        {!showAll && props.hashtags.length > hashtagsToShowInitially && (
          <button onClick={toggleShowAll} className="show-button">
            + Drops 24/7
          </button>
        )}
        {showAll && (
          <div>
            <div className="dropsong">
              <i className="fa fa-mobile-phone" aria-hidden="true"></i> Drop
              song(s) to hashtag based playlists when you share your music in
              our app 🎶{" "}
            </div>
            <button onClick={toggleShowAll} className="show-button">
              Show Less
            </button>
          </div>
        )}
      </div>
    </div>
  );

  // forEachExample
  function tableData(data) {
    var model = [];
    data.forEach((hashtag, index) => {
      // here is your code
      model.push(
        <a
          key={index}
          title={hashtag}
          href={`/hashtag/${hashtag.replace(/^#/, "")}`}
          className="o_hashtag"
        >
          {hashtag}
        </a>
      );
    });
    return model;
  }
};

export default HashtagPlaylist;
