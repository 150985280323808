import React, { useState, useEffect } from "react";
import { ReactPaginator } from "./ReactPaginator";
import * as RadioSetupGuide from "./RadioSetupGuide";
import "./RadioApp.css";

function RadioApp(props) {
  const sections = [
    { color: "grey", content: <RadioSetupGuide.WelcomeToRadio /> },
    { color: "grey", content: <RadioSetupGuide.Step1 /> },
    { color: "grey", content: <RadioSetupGuide.Step2 /> },
    { color: "grey", content: <RadioSetupGuide.Step3 /> },
    { color: "grey", content: <RadioSetupGuide.Step4 /> },
    { color: "grey", content: <RadioSetupGuide.Step5 /> },
    { color: "grey", content: <RadioSetupGuide.Step6 /> },
    { color: "grey", content: <RadioSetupGuide.Step7 /> },
    { color: "grey", content: <RadioSetupGuide.Step8 /> },
    { color: "grey", content: <RadioSetupGuide.Step9 /> },
    { color: "grey", content: <RadioSetupGuide.Step10 /> },
    { color: "grey", content: <RadioSetupGuide.Step11 /> },
  ];

  return (
    <ReactPaginator sections={sections} usePages={true} welcomePageStr={"📱"} />
  );
}

export default RadioApp;
