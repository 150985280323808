const AwsConfig = require("../AWSConfig");

//VALIDATE_ACCOUNT
export const validate = async (email, code) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).confirmRegistration(
      code,
      true,
      (err, result) => {
        if (err) {
          reject(err);
        }
        if (result) {
          resolve(result);
        }
      }
    );
  });
};

//RESEND_VALIDATION_CODE
export const resendCode = async (email) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
      }
      if (result) {
        resolve(result);
      }
    });
  });
};

//RESET_PASSWORD
export const resetPass = async (email, confirmationCode, password) => {
  return new Promise((resolve, reject) => {
    AwsConfig.getCognitoUser(email).confirmPassword(
      confirmationCode,
      password,
      {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve("Success");
        },
      }
    );
  });
};
