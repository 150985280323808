import React, { useState, useEffect } from "react";
import SearchInput from "../../components/search/search/SearchInput";
import CountrySelect from "../../components/CountrySelect";
import emailvalidator from "email-validator";
import {
  searchMaterial,
  sendClaimForm,
  validateClaim,
} from "../../utils/apis/public";
import isEmpty from "../../components/isEmpty";
import copyObject from "../../components/copyObject";
//JS
import Footer from "../../components/footer/Footer";

//CSS
import "./Copyright.css";

//assets
import brandlogo from "../../assets/img/songdrop-logo-black.png";

//json
import copyrightJSON from "../../assets/json/copyright.json";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const successFaIcon = "fa fa-check-circle";
const cdownFaIcon = "fa fa-chevron-down";
const cupFaIcon = "fa fa-chevron-up";
const squareFaIcon = "fa fa-square-o";
const checkFaIcon = "fa fa-check-square";

const validation_code_length = 6;

const cform = {
  track_id: "",
  album_id: "",
  claimant: "Myself",
  name: "",
  phone: "",
  email: "",
  description: "",
  country: "",
  address: "",
  city: "",
  county: "",
  postcode: "",
};

const legal = {
  l1: false,
  l2: false,
  l3: false,
};

const cop_mat = {
  album_id: "",
  track_id: "",
  uploader_id: "",
};

//Copyright class
function Copyright() {
  const [searchText, setSearchText] = useState("");
  //
  const [apiError, setApiError] = useState("");
  const [isApiWorking, setApiWorking] = useState(false);
  const [albumsResult, setAlbumsResult] = useState([]);
  const [tracksResult, setTracksResult] = useState([]);

  const [openedIndex, setOpenedIndex] = useState(-1);
  const [copyrighted_material, setCopyrightMaterial] = useState(cop_mat);

  const [c_form, setCForm] = useState(cform);
  const [c_legal, setCLegal] = useState(legal);

  const [form_success, setFormSuccess] = useState(false);
  const [code_success, setCodeSuccess] = useState(false);

  //validation_code
  const [case_id, setCaseId] = useState("");
  const [validation_code, setValidationCode] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Copyright Claim | SongDrop.uk";
  }, []);

  //SEARCH
  const searchForText = async () => {
    setApiWorking(true);
    setApiError("");
    if (isEmpty(searchText)) {
      return;
    }
    //
    try {
      //✅Success: User Validated account
      let result = await searchMaterial(searchText);
      console.log(result);
      setApiWorking(false);
      setSearchText(result.search_text);
      setAlbumsResult(result.albums);
      setTracksResult(result.tracks);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
    }
  };

  //SEARCH
  const submitForm = async (e) => {
    e.preventDefault();
    setApiWorking(true);
    setApiError("");
    //
    try {
      //✅Success: Form sent
      var bodyParams = [];
      bodyParams.push(`c_album_id=${copyrighted_material.album_id}`);
      bodyParams.push(`c_track_id=${copyrighted_material.track_id}`);
      bodyParams.push(`c_uploader_id=${copyrighted_material.uploader_id}`);
      bodyParams.push(`claimant=${c_form.claimant}`);
      bodyParams.push(`name=${c_form.name}`);
      bodyParams.push(`phone=${c_form.phone}`);
      bodyParams.push(`email=${c_form.email}`);
      bodyParams.push(`description=${c_form.description}`);
      bodyParams.push(`country=${c_form.country}`);
      bodyParams.push(`address=${c_form.address}`);
      bodyParams.push(`city=${c_form.city}`);
      bodyParams.push(`county=${c_form.county}`);
      bodyParams.push(`postcode=${c_form.postcode}`);
      let bParameters = bodyParams.join("&");
      let result = await sendClaimForm(bParameters);
      //
      console.log(result);
      //SAVE CASE_ID for validation
      setCaseId(result["case_id"]);
      //
      alert(result["message"]);
      setApiWorking(false);
      setFormSuccess(true);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setFormSuccess(false);
      alert(error);
      // console.log("ERROR",error.message);
      setApiError(error.message);
    }
  };

  //VALIDATE
  const validateForm = async (e) => {
    e.preventDefault();
    setApiWorking(true);
    setApiError("");
    //
    try {
      //✅Success: User Validated case
      var queryStringParams = [];
      queryStringParams.push(`case_id=${case_id}`);
      queryStringParams.push(`code=${validation_code}`);
      let qParameters = queryStringParams.join("&");
      let result = await validateClaim(qParameters);
      // console.log(result);
      setApiWorking(false);
      setCodeSuccess(true);
      alert(result.message);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setCodeSuccess(false);
      alert(error);
      setApiError(error.message);
    }
  };

  //COPYRIGHTED_MATERIAL(ALBUM_ID,TRACK_ID)
  function setMaterial(_copyrighted_material) {
    if (
      copyrighted_material.album_id === _copyrighted_material.album_id &&
      copyrighted_material.track_id === _copyrighted_material.track_id
    ) {
      setCopyrightMaterial(cop_mat);
    } else {
      setCopyrightMaterial(_copyrighted_material);
    }
  }

  //INPUT_FIELDS(CLAIMANT_DATA)
  function onInputChange(key, value) {
    let form = copyObject(c_form);
    console.log(c_form, key, value);
    form[key] = value;
    setCForm({ ...form });
  }

  //CHECKBOX(LEGAL)
  function onCheckboxChange(key, value) {
    let form = copyObject(c_legal);
    console.log(form, key, value);
    form[key] = value;
    setCLegal({ ...form });
  }

  //VALIDATION_CODE
  function onCodeChange(validation_code) {
    setValidationCode(validation_code);
  }

  function setIndex(index) {
    if (index === openedIndex) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(index);
    }
  }

  //VIEW
  return (
    <div className="copyright-body">
      <div className="header">
        <div className="title">Copyright Claim</div>
      </div>
      {code_success && (
        <div className="form_success">
          {copyrightJSON["form_was_sent"]}
          <button disabled={true}>
            <i className={successFaIcon} />
          </button>
        </div>
      )}
      {!code_success && (
        <div className="content">
          <div className="section bcgGray">
            <div className="section-title">1. Copyrighted Material</div>
            <div>{copyrightJSON["title"]}</div>
            <br />
            <div>
              <div>Search copyrighted album or track:</div>
              <SearchInput
                isApiWorking={isApiWorking}
                searchText={searchText}
                textDidChange={setSearchText}
                searchForText={searchForText}
              />
            </div>
            <div>
              <p>Search Results: {albumsResult.length + tracksResult.length}</p>
              <div className="result_title">
                <strong>Albums({albumsResult.length}):</strong>
              </div>
              <div>{getAlbumsResults()}</div>
              <div className="result_title">
                <strong>Tracks({tracksResult.length}):</strong>
              </div>
              <div>{getTracksResults()}</div>
            </div>
          </div>
          <div className="section">
            <div className="section-title">2. Copyright Owner</div>
            <div className="copyright">
              <select
                onChange={(e) => onInputChange("claimant", e.target.value)}
              >
                <option>Myself</option>
                <option>My client, organisation, or company</option>
              </select>
              <div>
                <p>Name</p>
                <input
                  className="i_copyright"
                  placeholder="(required)"
                  value={c_form.name}
                  onChange={(e) => onInputChange("name", e.target.value)}
                />
              </div>
              <div>
                <p>Phone</p>
                <input
                  className="i_copyright"
                  placeholder="(required)"
                  value={c_form.phone}
                  onChange={(e) => onInputChange("phone", e.target.value)}
                />
              </div>
              <div>
                <div>
                  {!isEmpty(c_form.email) &&
                    !emailvalidator.validate(c_form.email) && (
                      <p style={{ color: "red" }}>Enter valid email</p>
                    )}
                  {!isEmpty(c_form.email) &&
                    emailvalidator.validate(c_form.email) && <p>Email</p>}
                  {isEmpty(c_form.email) && <p>Email</p>}
                </div>
                <input
                  className="i_copyright"
                  placeholder="(required)"
                  value={c_form.email}
                  onChange={(e) => onInputChange("email", e.target.value)}
                />
              </div>
              <div>
                <p>Description</p>
                <textarea
                  className="t_copyright"
                  placeholder="Relationship with copyrighted material(required)"
                  value={c_form.description}
                  onChange={(e) => onInputChange("description", e.target.value)}
                />
              </div>
              <div>
                <p>Country</p>
                <CountrySelect
                  selectedCountry={(country) =>
                    onInputChange("country", country.name)
                  }
                />
              </div>
              <div>
                <p>Street Address</p>
                <input
                  className="i_copyright"
                  placeholder="(required)"
                  value={c_form.address}
                  onChange={(e) => onInputChange("address", e.target.value)}
                />
              </div>
              <div>
                <p>City</p>
                <input
                  className="i_copyright"
                  placeholder="(required)"
                  value={c_form.city}
                  onChange={(e) => onInputChange("city", e.target.value)}
                />
              </div>
              <div>
                <p>County</p>
                <input
                  className="i_copyright"
                  placeholder="(required)"
                  value={c_form.county}
                  onChange={(e) => onInputChange("county", e.target.value)}
                />
              </div>
              <div>
                <p>Postcode</p>
                <input
                  className="i_copyright"
                  placeholder="(required)"
                  value={c_form.postcode}
                  onChange={(e) => onInputChange("postcode", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="section">
            <div className="section-title">3. Submitting</div>
            <div className="legal_checkbox">
              <p>{copyrightJSON["i1"]}</p>
              <p>{copyrightJSON["i2"]}</p>
              <p>
                <strong>{copyrightJSON["i3"]}</strong>
              </p>
              <p>
                <strong>{`A, `}</strong>
                {copyrightJSON["i4"]}
              </p>
              <p>
                <strong>{`B, `}</strong>
                {copyrightJSON["i5"]}
                <a href="/terms">Terms and usage</a>.
              </p>
            </div>
          </div>
          <div className="section">
            <div className="section-title">4. Legal</div>
            <div className="legal_checkbox">
              <label>
                <button onClick={() => onCheckboxChange("l1", !c_legal["l1"])}>
                  <i
                    className={`${
                      c_legal["l1"] ? "fa fa-check-square" : "fa fa-square-o"
                    }`}
                  />
                </button>
                {copyrightJSON["l1"]}
              </label>
              <label>
                <button onClick={() => onCheckboxChange("l2", !c_legal["l2"])}>
                  <i
                    className={`${
                      c_legal["l2"] ? "fa fa-check-square" : "fa fa-square-o"
                    }`}
                  />
                </button>

                {copyrightJSON["l2"]}
              </label>
              <label>
                <button onClick={() => onCheckboxChange("l3", !c_legal["l3"])}>
                  <i
                    className={`${
                      c_legal["l3"] ? "fa fa-check-square" : "fa fa-square-o"
                    }`}
                  />
                </button>
                {copyrightJSON["l3"]}
              </label>
            </div>
          </div>
          <div className="section">
            {!form_success && isApiWorking && (
              <button disabled={true}>
                <i className={apiFaIcon} />
              </button>
            )}
            {!isApiWorking && (
              <div>
                {!form_success && (
                  <button
                    className="copyright-submit"
                    disabled={!isReadyToSubmit()}
                    onClick={(e) => submitForm(e)}
                  >
                    Submit
                  </button>
                )}
                {form_success && (
                  <button disabled={true}>
                    <i className={successFaIcon} />
                  </button>
                )}
                {!isEmpty(apiError) && (
                  <div className="error-row">Error: {apiError}</div>
                )}
              </div>
            )}
          </div>
          {isReadyToSubmit() && (
            <div className="section validation">
              {!form_success && <div>{copyrightJSON["email_to_conf"]}</div>}
              {form_success && <div>{copyrightJSON["email_was_sent"]}</div>}
              <input
                placeholder="XXXXXX"
                value={validation_code}
                onChange={(e) => onCodeChange(e.target.value)}
              />
              <div>
                {isApiWorking && form_success && (
                  <button disabled={true}>
                    <i className={apiFaIcon} />
                  </button>
                )}
                {!isApiWorking && (
                  <div>
                    {!code_success && form_success && (
                      <button
                        className="copyright-submit"
                        onClick={(e) => validateForm(e)}
                        disabled={
                          validation_code.length < validation_code_length &&
                          form_success === true
                        }
                      >
                        Validate
                      </button>
                    )}
                    {code_success && (
                      <button disabled={true}>
                        <i className={successFaIcon} />
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  function getAlbumsResults() {
    var data = [];
    albumsResult.forEach((item, index) => {
      let isOpened = index === openedIndex;
      let isSelected = copyrighted_material.album_id === item.album_id;
      let copyright = {
        album_id: item.album_id,
        track_id: "",
        uploader_id: item.uploader_id,
      };
      //full_album_infridgement
      let _item = (
        <div key={index} className="result_row">
          <div onClick={() => setIndex(index)}>
            <i className={isSelected ? checkFaIcon : squareFaIcon} />
            <i className={!isOpened ? cdownFaIcon : cupFaIcon} />
            {item.title} - {item.artist}
          </div>
          {isOpened && (
            <div className="box_info">
              <div>
                <img alt="" src={item.cover_img_url} />
                <p>
                  Title: <strong>{item.title}</strong>
                </p>
                <p>
                  Artist: <strong>{item.artist}</strong>
                </p>
                <p>Genres: {item.genres}</p>
                <p>Released: {item.released_at}</p>
                <p>
                  Uploader: <strong>{item.uploader_username}</strong>
                </p>
              </div>
              <label className="input_label">
                <div className="checkbox-wrapper">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => setMaterial(copyright)}
                  />
                </div>
                {copyrightJSON["copyrighted_album"]}
              </label>
            </div>
          )}
        </div>
      );
      data.push(_item);
    });
    return data;
  }

  function getTracksResults() {
    var data = [];
    tracksResult.forEach((item, index) => {
      let _index = albumsResult.length + index;
      let isOpened = _index === openedIndex;
      let isSelected =
        copyrighted_material.track_id === item.track_id ||
        copyrighted_material.album_id === item.album_id;
      let copyright = {
        album_id: item.album_id,
        track_id: item.track_id,
        uploader_id: item.uploader_id,
      };
      //song_infridgement
      let _item = (
        <div key={_index} className="result_row">
          <div onClick={() => setIndex(_index)}>
            <div>
              <div>
                <i className={isSelected ? checkFaIcon : squareFaIcon} />
                <i className={!isOpened ? cdownFaIcon : cupFaIcon} />
                {item.title} - {item.artist}
              </div>
              <div className="album_title">[{item.album_title}]</div>
            </div>
            <p></p>
          </div>
          {isOpened && (
            <div className="box_info">
              <div>
                <img alt="" src={item.cover_img_url} />
                <p>
                  Title: <strong>{item.title}</strong>
                </p>
                <p>
                  Artist: <strong>{item.artist}</strong>
                </p>
                <p>
                  Album: <strong>[{item.album_title}]</strong>
                </p>
                <p>Genres: {item.genres}</p>
                <p>Released: {item.released_at}</p>
                <p>
                  Uploader: <strong>{item.uploader_username}</strong>
                </p>
              </div>
              <div className="input_label">
                <div className="checkbox-wrapper">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => setMaterial(copyright)}
                  />
                </div>
                {copyrightJSON["copyrighted_song"]}
              </div>
            </div>
          )}
        </div>
      );
      data.push(_item);
    });
    return data;
  }

  function isReadyToSubmit() {
    //check if album has tracks(not empty)
    if (
      isEmpty(c_form.claimant) ||
      isEmpty(c_form.name) ||
      isEmpty(c_form.phone) ||
      (isEmpty(c_form.email) && !emailvalidator.validate(c_form.email)) ||
      isEmpty(c_form.description) ||
      isEmpty(c_form.country) ||
      isEmpty(c_form.address) ||
      isEmpty(c_form.county) ||
      isEmpty(c_form.postcode) ||
      c_legal.l1 === false ||
      c_legal.l2 === false ||
      c_legal.l3 === false ||
      isEmpty(copyrighted_material.album_id)
    ) {
      return false;
    }
    return true;
  }
}

export default Copyright;
