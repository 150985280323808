import React from "react";
import Moment from "moment";
import isEmpty from "../../components/isEmpty";
import isSame from "../../components/isSame";

//CSS
import "./HashtagDropCell.css";

//HashtagDropCell
function HashtagDropCell(props) {
  //SELECT: Drop
  function didSelectDrop() {
    props.didSelectDrop(props.drop);
  }

  //VIEW
  return (
    <div
      className={`hashtag_cell ${isDropActive("h-active")}`}
      onClick={() => didSelectDrop()}
    >
      <div className="cell-content">
        <div className="left">
          <div className="image-container">
            <img alt="" src={props.drop.song.cover_img_url} />
          </div>
        </div>
        <div className="right">
          <div className="rtitle">{props.drop.song.title}</div>
          <div className="rsubtitle">{props.drop.song.artist}</div>
          <img className="rimg" alt="" src={props.drop.owner.profile_img_url} />
          <a
            className={`${isDropActive("a-active")}`}
            href={`/@/${props.drop.owner.username}`}
          >
            @{props.drop.owner.username} <i className="fa fa-chevron-right" />
          </a>
          <div className="timeago">
            {isDropActive("▶︎")}
            {convertDate(props.drop.start_date)} - {props.drop.views_count}{" "}
            views
          </div>
        </div>
      </div>
    </div>
  );

  //SET: active cell
  function isDropActive(text_to_return) {
    return !isEmpty(props.drop) &&
      !isEmpty(props.dropToListen) &&
      isSame(props.drop.drop_id, props.dropToListen.drop_id)
      ? text_to_return
      : "";
  }
  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default HashtagDropCell;
