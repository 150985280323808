import "./WebsiteRandomFonts.css";
import {
  web_content_text,
  web_fonts,
  web_background_img_urls,
  web_colors,
  web_seo_copypaste,
} from "./WebsiteRandomContent";

export function generateHashtags(number_of_hahstags, first_hashtag) {
  const hashtags = [first_hashtag]; // Start with a default hashtag
  // Shuffle the extensive list to get random hashtags
  const shuffledList = web_seo_copypaste.sort(() => Math.random() - 0.5);
  // Add the desired number of hashtags to the array
  for (let i = 0; i < number_of_hahstags - 1; i++) {
    hashtags.push(shuffledList[i]);
  }
  return hashtags.join(" ");
}

//GENERATE: Web color => #colorHex
export const generateRandomWebColor = () => {
  const palette = web_colors[Math.floor(Math.random() * web_colors.length)]; //Select a random palette
  return palette[Math.floor(Math.random() * palette.length)]; //Select a random color from the palette
};

//GENERATE: Font from web_fonts => "Font name"
export const generateRandomFont = () => {
  return web_fonts[Math.floor(Math.random() * web_fonts.length)]; //Select a random font from the list
};

//GENERATE: random background URL from web_background_img_urls
//NOTE: If you add backgroundURLs into web_background_img_urls
//than it will generate always a background image and it will cover backgroundColor
const generateRandomBgImg = () => {
  return web_background_img_urls[
    Math.floor(Math.random() * web_background_img_urls.length)
  ];
};

//GENERATE: a random phrase from web_content_text
const generateRandomPhrase = () => {
  return web_content_text[Math.floor(Math.random() * web_content_text.length)];
};

//CONVERT: hexadecimal color to RGB values
const hexToRgb = (hex) => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
};

//GENERATE: a complementary color based on the provided RGB values
const generateComplementaryColor = (r, g, b) => {
  const cr = 255 - r;
  const cg = 255 - g;
  const cb = 255 - b;
  return `rgb(${cr}, ${cg}, ${cb})`; //Return complementary color in RGB format
};

///////////////////////////////////////////
//RANDOM CONTENT GENERATOR TO CREATE IMAGE CONTENT
// =>
// return {
//   title: title for the post,
//   font: font for the post,
//   fontSize: fontSize for the post,
//   fontColor: fontColor for the post,
//   icon: icon for the post,
//   domain: domain for the post(domain name at the bottom),
//   bgColor: bgColor for the post,
//   bgImg: bgImg for the post(if you want to have a background image for your post),
// };
export const randomPopGenerator = ({ icon, domain, fontSize }) => {
  //Generate random background color
  const bgColor = generateRandomWebColor();
  //Generate complementary font color based on the background color
  const { r, g, b } = hexToRgb(bgColor);
  const fontColor = generateComplementaryColor(r, g, b);
  //Generate random font
  const font = generateRandomFont();
  //Generate random background URL
  const bgImg = generateRandomBgImg();
  //Get a random phrase
  const title = generateRandomPhrase();
  return {
    title: title,
    font: font,
    fontSize: fontSize,
    fontColor: fontColor,
    icon: icon,
    domain: domain,
    bgColor: bgColor,
    bgImg: bgImg,
  };
};
