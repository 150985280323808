import React, { useState, useEffect } from "react";
import isEmpty from "../../components/isEmpty";
import ProfileSearchBar from "./ProfileSearchBar";
import { searchUsers } from "../../utils/apis/public";

//CSS
import "./ProfileSearch.css";
//asset
import placeholder from "../../assets/img/ic_placeholder_profile.png";

//ProfileSearch
function ProfileSearch(props) {
  //
  const [users, setUsers] = useState([]);
  const [isApiWorking, setApiWorking] = useState(props.isApiWorking || true);
  const [apiError, setApiError] = useState("");

  //SEARCH
  const [searchText, setSearchText] = useState(props.searchText || "");
  const [isSearching, setIsSearching] = useState(false);

  //ON SAFARI IOS PROFILE SEARCH HAS TROUBLE WITH ZINDEX
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    _searchUsers(searchText);
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        // Close the popup when the Escape key is pressed
        closePopup();
      }
    };
    // Add event listener for key press
    document.addEventListener("keydown", handleKeyPress);
    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  //api
  async function _searchUsers(search_text) {
    setApiWorking(true);
    setApiError("");

    try {
      let result = await searchUsers(search_text);
      setUsers(result["users"]);
      //✅Result
      console.log(result);
      setApiWorking(false);
    } catch (error) {
      //❌Error: Validation falied
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //UPDATE: SearchBar text
  function textDidChange(searchtext) {
    setSearchText(searchtext);
  }

  //SEARCH
  const searchForText = () => {
    setApiWorking(true);
    setApiError("");
    setIsSearching(true);
    //search again
    _searchUsers(searchText);
  };

  const userClicked = (username) => {
    window.location.href = `/@/${username}`;
  };

  const closePopup = () => {
    props.handleClose();
  };

  //VIEW
  return (
    <div
      className="usersearch-popup"
      style={{ padding: isIOS && isSafari ? "40px 0px 0px 0px" : "10px" }}
    >
      <div className="usersearch-popup-inner">
        <div className="search-wrapper">
          <button className="p-close-btn" onClick={() => closePopup()}>
            <i className="fa fa-chevron-circle-left" />
          </button>
          <div className="searchbar-wrapper">
            <ProfileSearchBar
              isApiWorking={isApiWorking}
              searchText={searchText}
              searchForText={searchForText}
              textDidChange={textDidChange}
            />
          </div>
        </div>
        <div className="search-result">Result: {users.length}</div>
        {isEmpty(users) && !isApiWorking && (
          <div className="no-users-found">No users found</div>
        )}
        {!isEmpty(users) && (
          <div className="content">
            {users.map((user, index) => {
              return (
                <div
                  className="p_cell"
                  key={index}
                  onClick={() => userClicked(user.username)}
                >
                  <div
                    className="p_img"
                    style={{
                      borderRadius: user.usertype === 1 ? "2px" : "25px",
                    }}
                  >
                    <img
                      alt=""
                      src={
                        isEmpty(user.profile_img_url)
                          ? placeholder
                          : user.profile_img_url
                      }
                      style={{
                        borderRadius: user.usertype === 1 ? "2px" : "25px",
                      }}
                    />
                  </div>
                  <div className="p_info">
                    <div className="p_username">{user.username}</div>
                    <div className="p_name">{user.name}</div>
                    <div className="p_genres">{user.genres}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileSearch;
