import React from "react";
import "./BlogHeader.css";

const BlogHeader = (props) => (
  <div>
    <div className="home-header">
      <div className="left">
        <i className="fa fa-rss-square" />
        Blog
      </div>
      <div className="right">
        <a href="/s/songdropofficial" target="_blank">
          <i className="fa fa-bell" />
          SUBSCRIBE
        </a>
      </div>
    </div>
    <div className="home-subtitle">
      Read news on new artists, releases, music and SongDrop. Subscribe to get
      notified in your inbox.
    </div>
  </div>
);

export default BlogHeader;
