import React from "react";
import numberFormatter from "../../components/numberFormatter";
import isEmpty from "../../components/isEmpty";
import ReactAudioPlayer from "react-audio-player";
import DownloadApp from "../dashboard/DownloadApp";

//CSS
import "./ListenDrop.css";

//ListenDrop
function ListenDrop(props) {
  //SELECT: Drop
  function setSelecteddropToListen() {
    props.setSelecteddropToListen(props.drop);
  }

  //VIEW
  return (
    <div id="drop-previewbox" className="drop-previewbox">
      <div className="listeninapp">
        <i className="fa fa-info-circle" /> Listen the full Song as a Drop in
        our app.
        <i
          className="close fa fa-times-circle"
          onClick={() => setSelecteddropToListen(null)}
        />
      </div>
      <div className="listen-drop">
        <div className="songtitle">
          <i className="fa fa-music" />
          {props.dropToListen.song.title} - {props.dropToListen.song.artist}
        </div>
        <div className="views">
          <i className="fa fa-eye" />
          {numberFormatter(props.dropToListen.views_count, 1)} views
        </div>
      </div>
      <div className="listeninapp">
        {!isEmpty(props.dropToListen.hashtags) &&
          props.dropToListen.hashtags.split(",").map((hashtag, index) => {
            return (
              <a
                key={index}
                title={hashtag}
                href={`https://songdrop.uk/hashtag/${hashtag.replace(
                  /^#/,
                  ""
                )}`}
                className="hashtag"
              >
                {hashtag}
              </a>
            );
          })}
      </div>
      {!isEmpty(props.streamUrl) &&
        props.streamUrl === props.dropToListen.song.prev_url && (
          <div className="mp3Player">
            <p className="prev">Preview</p>
            <div className="player">
              <ReactAudioPlayer
                src={props.streamUrl}
                autoPlay
                controls
                style={{
                  width: "100%",
                  color: "#fff",
                }}
              />
            </div>
          </div>
        )}
      <DownloadApp />
    </div>
  );
}

export default ListenDrop;
