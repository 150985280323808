import React, { useState, useEffect } from "react";

//about
import about from "../../assets/about/about.json";
//CSS
import "./MobileApp.css";
import Footer from "../../components/footer/Footer";
import Socials from "../../components/socials/socials";
import PhoneBezel from "./PhoneBezel";
//MobileApp class
function MobileApp(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="mobileapp">
      <div className="download-info">
        <div className="download-left">
          <i className="fa fa-mobile" />
        </div>
        <div className="download-right">
          <h1>Download Our Apps</h1>
          <p>Discover and enjoy our latest mobile applications.</p>
        </div>
      </div>
      {/* <main className="appcontainer">
        <PhoneBezel />
      </main> */}
      <div className="app-description">
        <p className="getapp">Get the app!</p>
        <div className="app-info">
          <h2>
            SongDrop <a href="/tutorials">{`Read more>`}</a>
          </h2>
          <p>
            Discover, buy, and share pre-recorded music from independent artists
            with SongDrop. Our platform empowers musicians and listeners,
            creating a sustainable income model for artists while offering a
            unique and enriched music discovery experience for listeners.
          </p>
          <ul>
            <li>Seamless music purchase on smartphones</li>
            <li>Flexible pricing for artists</li>
            <li>Metadata-enriched digital collections</li>
            <li>(ED)Electronically Distributed built-in scanner</li>
          </ul>
          <div className="store-buttons">
            <a
              href={about.appstore_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/0/0f/Available_on_the_App_Store_%28black%29_SVG.svg"
                alt="App Store"
              />
            </a>
            <a
              href="https://play.google.com/store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://blamoramagames.com/wp-content/uploads/2023/03/GooglePlay.png"
                alt="Google Play Store"
              />
            </a>
          </div>
          <div className="country-buttons"></div>
        </div>

        <div className="app-info">
          <h2>
            Radio! <a href="/radioapp">{`Read more>`}</a>
          </h2>
          <p>
            Experience live audio streaming with Radio! Whether you're a
            performer, podcaster, or DJ, our platform makes it easy to broadcast
            live content. Set up a broadcasting server in minutes and share your
            live voice as a simple .mp3 link or a QR code.
          </p>
          <ul>
            <li>Live audio sharing from your phone</li>
            <li>Quick setup for broadcasting servers</li>
            <li>Share live content as .mp3 links or QR codes</li>
          </ul>
          <div className="store-buttons">
            <a
              href={about.radio_app_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/0/0f/Available_on_the_App_Store_%28black%29_SVG.svg"
                alt="App Store"
              />
            </a>
            <a
              href="https://play.google.com/store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://blamoramagames.com/wp-content/uploads/2023/03/GooglePlay.png"
                alt="Google Play Store"
              />
            </a>
          </div>
        </div>
      </div>
      <Socials />
      <Footer />
    </div>
  );

  function goToRadioApp() {
    window.location.href = "./radioapp";
  }
}

export default MobileApp;
