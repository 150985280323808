// ReactPaginator.js
import React, { useState, useEffect } from "react";
import "./ReactPaginator.css";

// PageNumbers component
function ReactPaginatorPageNumbers({
  currentPage,
  pageCount,
  onPageChange,
  welcomePageStr,
}) {
  // Define the starting page number based on whether the Welcome page is shown
  const startPage = welcomePageStr ? 0 : 1;
  return (
    <div className="react-paginator-page-numbers">
      {[...Array(pageCount).keys()].map((index) => (
        <button
          key={index}
          className={`react-paginator-page-number ${
            currentPage === index ? "active" : ""
          }`}
          onClick={() => onPageChange(index)}
        >
          {" "}
          {currentPage === 0 && index === 0 && welcomePageStr
            ? welcomePageStr
            : index === currentPage
            ? index + startPage
            : ""}
        </button>
      ))}
    </div>
  );
}

// Section component
function ReactPaginatorSection({ color, children, id }) {
  return (
    <div
      id={id}
      className="react-paginator-section"
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  );
}

// ReactPaginator component
function ReactPaginator({ sections, usePages, welcomePageStr }) {
  const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0

  useEffect(() => {
    let timeout;

    const handleScroll = (event) => {
      clearTimeout(timeout); // Clear any existing timeout
      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        return; // Ignore horizontal scroll
      }
      timeout = setTimeout(() => {
        const deltaY = event.deltaY;
        const nextPage = deltaY > 0 ? currentPage + 1 : currentPage - 1;
        if (nextPage >= 0 && nextPage < sections.length) {
          scrollToSection(nextPage); // Update currentPage here
        }
      }, 50); // Adjust the timeout duration as needed
    };

    const handleTouchMove = (event) => {
      clearTimeout(timeout); // Clear any existing timeout
      const deltaY = event.touches[0].clientY - event.touches[1].clientY;
      const nextPage = deltaY > 0 ? currentPage + 1 : currentPage - 1;
      if (nextPage >= 0 && nextPage < sections.length) {
        scrollToSection(nextPage); // Update currentPage here
      }
    };

    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("wheel", handleScroll);

    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [currentPage, sections.length]); // Ensure currentPage is in the dependencies array

  useEffect(() => {
    // Scroll to section 0 when the component mounts or when the sections change
    const section = document.getElementById(`react-paginator-section${0}`);

    if (section) {
      section.setAttribute("tabindex", "-1");
      section.focus();
    }
  }, [sections]); // Trigger effect when sections change

  const scrollToSection = (pageNumber) => {
    setCurrentPage(pageNumber);
    // console.log("current pagenumber is", pageNumber);
    const section = document.getElementById(
      `react-paginator-section${pageNumber}`
    );
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sectionError = (
    <>
      <div>
        ReactPaginator Error: Please add a section array like the provided
        example:
      </div>
      <div>const sections = [</div>
      <div>
        &nbsp;&nbsp;
        {`{ color: "red", content: <div>First section content</div> },`}
      </div>
      <div>
        &nbsp;&nbsp;
        {`{ color: "green", content: <div>Second section content</div> },`}
      </div>
      <div>];</div>
    </>
  );

  const pagesError = (
    <>
      <div>ReactPaginator Error: Please set usePages to true or false</div>
    </>
  );
  return (
    <div className="react-paginator-fullpage">
      {sections ? (
        sections.length > 0 ? (
          sections.map((section, index) => (
            <ReactPaginatorSection
              key={`react-paginator-section${index}`}
              color={section.color}
              id={`react-paginator-section${index}`}
            >
              {section.content}
            </ReactPaginatorSection>
          ))
        ) : (
          <div className="react-paginator-section-error">{sectionError}</div>
        )
      ) : (
        <div className="react-paginator-section-error">{sectionError}</div>
      )}
      {usePages ? (
        sections ? (
          <ReactPaginatorPageNumbers
            welcomePageStr={welcomePageStr}
            currentPage={currentPage}
            pageCount={sections.length}
            onPageChange={scrollToSection}
          />
        ) : (
          <div></div>
        )
      ) : (
        <div className="react-paginator-section-error">{pagesError}</div>
      )}
    </div>
  );
}

export { ReactPaginator, ReactPaginatorPageNumbers, ReactPaginatorSection };
