import React, { useEffect } from "react";

//CSS
import "./Beta.css";
//img
import logo from "../../assets/img/songdrop-logo-black.png";

function Beta() {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Beta | SongDrop.uk";
  }, []);

  return (
    <div className="beta-body">
      <div className="img-wrapper">
        <img src={logo} alt="logo" />
      </div>
      <div className="success">BETA</div>
      <div className="text-wrapper" onClick={goToHome}>
        <span>Music Connects Us</span>
      </div>
    </div>
  );

  //go to login
  function goToHome() {
    window.location.href = "/";
  }
}

export default Beta;
