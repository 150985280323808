import React, { useState, useEffect } from "react";
import AlbumDesign from "./AlbumDesign";
import Zoom from "react-medium-image-zoom";
// CSS
import "./Card.css";

const fontSize = 12;
const trackFontSize = 12;
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

// Card component
function Card(props) {
  const [isChecked, setIsChecked] = useState(false);

  // ViewDidLoad
  useEffect(() => {}, []);

  // VIEW
  return (
    <div className="section">
      <input
        className="pricing"
        type="checkbox"
        id="pricing"
        name="pricing"
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
      <label htmlFor="pricing">
        <div className="block-diff">
          <span>Front</span>
          <span>Back</span>
        </div>
      </label>
      {props.isApiWorking && (
        <div className="stat-load" style={{ marginTop: 12 }}>
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}

      <div className={`card-3d-wrap mx-auto ${isChecked ? "flipped" : ""}`}>
        {props.apiError && <div className="api_error">🚫{props.apiError}</div>}
        <div
          className="card-3d-wrapper  mx-auto"
          style={{
            width: props.width * 0.72,
            height: props.width * 0.72,
          }}
        >
          <div
            className="card-front"
            style={{ width: props.width * 0.72, height: props.width * 0.72 }}
            onClick={() => setIsChecked(!isChecked)}
          >
            <AlbumDesign
              fontSize={fontSize}
              trackFontSize={trackFontSize}
              album={props.album}
              isBack={false}
            />
          </div>

          <div
            className="card-back"
            style={{ width: props.width * 0.72, height: props.width * 0.72 }}
          >
            <AlbumDesign
              fontSize={fontSize}
              trackFontSize={trackFontSize}
              album={props.album}
              isBack={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
