import React from "react";
import "./DropCell.css";
import Moment from "moment";
import isEmpty from "../../components/isEmpty";
import isSame from "../../components/isSame";

//DropCell
function DropCell(props) {
  //SELECT: Drop
  function didSelectDrop() {
    props.didSelectDrop(props.drop);
  }

  //VIEW
  return (
    <div
      className={`cell ${isDropActive("active")}`}
      onClick={() => didSelectDrop()}
    >
      <div className={`cell-content`}>
        <div className="image-container">
          <img alt="" src={props.drop.song.cover_img_url} />
        </div>
        <div className="timeago">
          {isDropActive("▶︎")}
          {convertDate(props.drop.start_date)}
        </div>
      </div>
    </div>
  );

  //SET: active cell
  function isDropActive(text_to_return) {
    return !isEmpty(props.drop) &&
      !isEmpty(props.dropToListen) &&
      isSame(props.drop.drop_id, props.dropToListen.drop_id)
      ? text_to_return
      : "";
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default DropCell;
