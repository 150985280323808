import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer";
//CSS
import "./Support.css";

const brandImageLink = "https://songdrop.uk";
const qrCodeUrl1 = "https://cdn.songdrop.cloud/investors/qr_paypal.png";
const qrCodeUrl2 = "https://cdn.songdrop.cloud/investors/qr_buymeacoffee.png";
const link1 = "https://paypal.me/SONGDROPLTD";
const link2 = "https://buymeacoffee.com/songdrop";

//Support
function Support() {
  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Support SongDrop | Support Music";
    //
  }, []);

  //VIEW
  return (
    <div className="support-container">
      <a href={brandImageLink} target="_blank" rel="noopener noreferrer">
        <img
          className="brandImage"
          src="https://cdn.songdrop.cloud/investors/SUPPORT_SONGDROP.png"
          alt="Brand Logo"
        />
      </a>
      <div className="qrCodeContainer">
        <img src={qrCodeUrl2} alt="QR Code 2" className="qrCodeImage" />
        <a
          href={link2}
          className="buttonLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          {"🔗buymeacoffee.com/songdrop"}
        </a>
      </div>
      <div className="qrCodeContainer">
        <img src={qrCodeUrl1} alt="QR Code 1" className="qrCodeImage" />
        <a
          href={link1}
          className="buttonLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          {"🔗paypal.me/SONGDROPLTD"}
        </a>
      </div>
      <Footer />
    </div>
  );
}

export default Support;
