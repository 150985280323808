import React, { useState, useEffect } from "react";

import "./DiscordPanel.css";

//Default class
function DiscordPanel(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="discord">
      <div className="left">
        <div className="title">JOIN OUR DISCORD SERVER</div>
        <div className="subtitle">
          Who's online? Talk with others about music, life, Drops and new
          artists.
          <div>
            With our Discord server, we help Independent Artists and their fans
            to connect easily on a safe platform.
          </div>
          <div>Chat many-to-many or 1-to-1.</div>
          Do you need more?
        </div>
      </div>
      <div className="right">
        <iframe
          style={{ padding: "0px 12px 0px 12px" }}
          src="https://discord.com/widget?id=1060578263560364102&theme=dark"
          width="280px"
          height="250px"
          allowtransparency="true"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          title="SONGDROP DISCORD SERVER"
        ></iframe>
      </div>
    </div>
  );
}

export default DiscordPanel;
