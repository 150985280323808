import React from "react";
import Linkify from "react-linkify";
//CSS
import "./Footer.css";

//JSON
//about
import about from "../../assets/about/about.json";

//ForumFooter
function Footer() {
  return (
    <div className="footer-wrapper">
      <p>Version:{about.version}</p>
      <p>
        <Linkify>
          © {about.year} {about.name}. All rights reserved. | contact:{" "}
          {about.contact}
        </Linkify>
      </p>
      <a title="ABOUT" href={"/@/songdropofficial"}>
        About
      </a>
      <a title="TERMS AND USAGE" href={about.terms_url}>
        Terms and Usage
      </a>
      <a title="PRIVACY POLICY" href={about.privacy_url}>
        Privacy Policy
      </a>
      <a title="FAQ" href={about.faq_url}>
        FAQ
      </a>
      <a title="Copyright Claim" href={about.copyright_url}>
        Copyright Claim
      </a>
      <a title="System Status" href={about.system_status_url}>
        System Status
      </a>
    </div>
  );
}

export default Footer;
